import React from "react";
import { makeStyles } from "@material-ui/core/styles";

import PropTypes from "prop-types";
import Typography from "@material-ui/core/Typography";

import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import { connect } from "react-redux";

import {
  loginRequest,
  setUserRole,
  setAppBarName,
  setLayoutName,
} from "../../../Redux/Actions/LoginActions";
import styles from "../../../assets/jss/material-dashboard-react/views/LoginPage.js";

const useStyles = makeStyles(styles);
const InvalidURL = (props) => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <GridContainer>
        <GridItem xs>
          <div className={classes.root}>
            <img
              className={classes.logo}
              src={require("../../../assets/img/vikingstransparent_logo.png")}
              alt=""
            />
            <Typography color="textPrimary" variant="h4" gutterBottom>
              uh oh!
            </Typography>
            <Typography color="textPrimary" variant="body1" gutterBottom>
              It seems like you're trying an invalid URL. Please retry with a valid URL.
            </Typography>
          </div>
        </GridItem>
      </GridContainer>
    </div>
  );
};

InvalidURL.propTypes = {
  history: PropTypes.object,
  prop: PropTypes.any,
};

const mapStateToProps = ({ app }) => ({
  userData: app.userDetails,
});
export default connect(mapStateToProps, {
  loginRequest,
  setUserRole,
  setAppBarName,
  setLayoutName,
})(InvalidURL);
