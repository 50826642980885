import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, TextField, Checkbox } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import styles from "assets/jss/material-dashboard-react/components/businessForm";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { format } from "date-fns";
import { createTheme } from "@material-ui/core";
import { MuiThemeProvider } from "@material-ui/core/styles";
import {
  getProviderByFilterData,
  addSearchData,
} from "../../Redux/Actions/ProvideRegistration";
import {
  getTransactionHistoryData,
  setFormDataTransactionHistory,
  clearFormDataTransactionHistory,
} from "../../Redux/Actions/genrateOfferPatient";

export const customTheme = createTheme({
  palette: {
    primary: {
      main: "#003c6e",
      light: "#003c6e",
      dark: "#003c6e",
    },
    secondary: {
      main: "#003c6e",
    },
  },
  overrides: {
    MuiPickersBasePicker: {
      container: {
        width: "300px",
        height: "370px",
      },
      pickerView: {
        width: "280px",
        height: "280px",
      },
    },
    MuiPickersCalendarHeader: {
      switchHeader: {
        fontSize: "0.9rem",
      },
      dayLabel: {
        fontSize: "0.7rem",
      },
    },
    MuiPickersDay: {
      day: {
        fontSize: "0.8rem",
      },
    },
    MuiTypography: {
      h4: {
        fontSize: "1rem",
      },
    },
  },
});
const useStyles = makeStyles(styles);
const searchButtonStyles = makeStyles((theme) => ({
  searchButton: {
    backgroundColor: theme.palette.primary.main,
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
      boxShadow: `0 14px 26px -12px rgb(153 153 153 / 42%), 0 4px 23px 0px rgb(0 0 0 / 12%), 0 8px 10px -5px rgb(153 153 153 / 20%)`,
    },
  },
}));

function SearchRefund(props) {
  const classes = useStyles();
  const [showAmountRange, setShowAmountRange] = React.useState(false);
  const [showDateRange, setShowDateRange] = React.useState(false);
  const searchButtonClasses = searchButtonStyles();
  const profileId = localStorage.getItem("profileId");
  const [txn1, setTxn1] = React.useState("");
  const [amount1, setAmount1] = React.useState("");
  const [amount2, setAmount2] = React.useState("");
  const [date1, setDate1] = React.useState("");
  const [date2, setDate2] = React.useState("");
  const [id, setId] = React.useState(null);

  React.useEffect(() => {
    const savedIsAmountRangeChecked = localStorage.getItem(
      "isAmountRangeChecked"
    );
    setShowAmountRange(savedIsAmountRangeChecked === "true");

    const savedIsDateRangeChecked = localStorage.getItem("isDateRangeChecked");
    setShowDateRange(savedIsDateRangeChecked === "true");
  }, []);

  React.useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const idFromUrl = queryParams.get("id");

    setId(idFromUrl);
  }, []);

  const clearFormData = () => {
    const initialFormData = {
      searchTxnID: "",
      searchStartAmount: "",
      searchEndAmount: "",
      searchStartDate: "",
      searchEndDate: "",
      dateRange: false,
      amountRange: false,
    };
    setShowAmountRange(false);
    setShowDateRange(false);
    localStorage.removeItem("isAmountRangeChecked");
    localStorage.removeItem("isDateRangeChecked");
    props.setFormDataTransactionHistory(initialFormData);
  };

  const handleAmountCheckboxChange = (event) => {
    setShowAmountRange(event.target.checked);
    if (event.target.checked) {
      setAmount1("");
      setShowAmountRange(true);
    } else {
      setShowAmountRange(false);
      setAmount1("");
      setAmount2("");
    }
  };

  const handleDateCheckboxChange = (event) => {
    setShowDateRange(event.target.checked);
    if (event.target.checked) {
      setDate1("");
      setShowDateRange(true);
    } else {
      setDate1("");
      setDate2("");
      setShowDateRange(false);
    }
  };

  const handleFromDateChange = (date) => {
    let formattedDate = null;
    if (date instanceof Date) {
      formattedDate = format(date, "yyyy-MM-dd");
    } else if (typeof date === "string") {
      const parts = date.split("/");
      if (parts.length === 3) {
        formattedDate = `${parts[2]}-${parts[1]}-${parts[0]}`;
      }
    }
    setDate1(formattedDate);
  };

  const handleToDateChange = (date) => {
    let formattedDate = null;
    if (date instanceof Date) {
      formattedDate = format(date, "yyyy-MM-dd");
    } else if (typeof date === "string") {
      const parts = date.split("/");
      if (parts.length === 3) {
        formattedDate = `${parts[2]}-${parts[1]}-${parts[0]}`;
      }
    }
    setDate2(formattedDate);
  };

  const handleClose = () => {
    if (id) {
      const payload = {
        Bussines_id: id,
        recordPerPage: 5,
        pageNo: 1,
      };
      props.getTransactionHistoryData(payload);
    } else {
      const payload = {
        Profile_id: profileId,
        recordPerPage: 5,
        pageNo: 1,
      };
      props.getTransactionHistoryData(payload);
    }
    props.clearFormDataTransactionHistory();
    clearFormData();
    props.clearChips();
  };

  const onFormSubmit = (event) => {
    event.preventDefault();
    const submitFormData = {
      searchTxnID: txn1.trim(),
      searchStartAmount: amount1.trim(),
      searchEndAmount: amount2.trim(),
      searchStartDate: date1,
      searchEndDate: date2,
      amountRange: showAmountRange,
      dateRange: showDateRange,
    };
    if (showAmountRange === true) {
      localStorage.setItem("isAmountRangeChecked", true);
    } else {
      localStorage.removeItem("isAmountRangeChecked");
    }

    if (showDateRange === true) {
      localStorage.setItem("isDateRangeChecked", true);
    } else {
      localStorage.removeItem("isDateRangeChecked");
    }

    props.setFormDataTransactionHistory(submitFormData);
    if (id) {
      const payload = {
        Bussines_id: id,
        recordPerPage: 5,
        searchTxnID: txn1.trim(),
        ...(showAmountRange
          ? {
            searchStartAmount: amount1.trim(),
            searchEndAmount: amount2.trim(),
          }
          : {
            searchStartAmount: amount1.trim(),
          }),
        ...(showDateRange
          ? {
            searchStartDate: date1,
            searchEndDate: date2,
          }
          : {
            searchStartDate: date1,
          }),
        amountRange: showAmountRange,
        dateRange: showDateRange,
        pageNo: 1,
      };
      props.getTransactionHistoryData(payload);
      props.seacrhChipData(payload);
      props.close();
    } else {
      const payload = {
        Profile_id: profileId,
        recordPerPage: 5,
        searchTxnID: txn1.trim(),
        ...(showAmountRange
          ? {
            searchStartAmount: amount1.trim(),
            searchEndAmount: amount2.trim(),
          }
          : {
            searchStartAmount: amount1.trim(),
          }),
        ...(showDateRange
          ? {
            searchStartDate: date1,
            searchEndDate: date2,
          }
          : {
            searchStartDate: date1,
          }),
        amountRange: showAmountRange,
        dateRange: showDateRange,
        pageNo: 1,
      };
      props.getTransactionHistoryData(payload);
      props.seacrhChipData(payload);
      props.close();
    }
  };

  React.useEffect(() => {
    if (props.formData.searchTxnID) {
      setTxn1(props.formData.searchTxnID);
    }
    if (props.formData.searchStartAmount) {
      setAmount1(props.formData.searchStartAmount);
    }
    if (props.formData.searchEndAmount) {
      setAmount2(props.formData.searchEndAmount);
    }
    if (props.formData.searchStartDate) {
      setDate1(props.formData.searchStartDate);
    }
    if (props.formData.searchEndDate) {
      setDate2(props.formData.searchEndDate);
    }
  }, [props.formData]);

  const handleInputChange1 = (e) => {
    setTxn1(e.target.value);
  };

  const handleInputChange2 = (e) => {
    setAmount1(e.target.value);
  };

  const handleInputChange3 = (e) => {
    setAmount2(e.target.value);
  };

  return (
    <MuiThemeProvider theme={customTheme}>
      <form onSubmit={(e) => onFormSubmit(e)}>
        <Grid
          container
          className={classes.margin}
          style={{ marginBottom: "5%" }}
        >
          <Grid item xs>
            <TextField
              className={classes.rootDialog}
              InputLabelProps={{
                classes: {
                  root: classes.activeInputLableColor,
                  focused: "focused",
                },
              }}
              InputProps={{
                classes: {
                  root: classes.activeInputColor,
                  focused: "focused",
                  input: classes.resize,
                },
              }}
              label="Ref Txn ID"
              value={txn1}
              onChange={handleInputChange1}
              name="searchTxnID"
              id="txnID"
              style={{ fontSize: "82%" }}
            />
          </Grid>
        </Grid>

        <Grid
          container
          className={classes.margin}
        >
          <Grid item xs container alignItems="center">
            <TextField
              className={classes.rootDialog}
              InputLabelProps={{
                classes: {
                  root: classes.activeInputLabelColor,
                  focused: "focused",
                },
              }}
              InputProps={{
                classes: {
                  root: classes.activeInputColor,
                  focused: "focused",
                  input: classes.resize,
                },
              }}
              label={showAmountRange ? "From" : "Amount ($)"}
              value={amount1}
              onChange={handleInputChange2}
              name="searchStartAmount"
              id="searchStartAmount"
              style={{
                fontSize: "82%",
                width: showAmountRange ? "50%" : "100%",
              }}
            />
            {showAmountRange && (
              <>
                <TextField
                  className={classes.rootDialog}
                  InputLabelProps={{
                    classes: {
                      root: classes.activeInputLabelColor,
                      focused: "focused",
                    },
                  }}
                  InputProps={{
                    classes: {
                      root: classes.activeInputColor,
                      focused: "focused",
                      input: classes.resize,
                    },
                  }}
                  label="To"
                  value={amount2}
                  onChange={handleInputChange3}
                  name="searchEndAmount"
                  id="searchEndAmount"
                  style={{ fontSize: "82%", width: "50%" }}
                />
              </>
            )}
            <Checkbox
              checked={showAmountRange}
              onChange={handleAmountCheckboxChange}
              color="primary"
              style={{ marginLeft: "auto" }}
            />
            <label style={{ fontSize: "12px", marginBottom: 0 }}>
              {showAmountRange ? "Amount ($)" : "Search In Range"}
            </label>
          </Grid>
        </Grid>

        <Grid container className={classes.margin}>
          <Grid item xs container alignItems="center">
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <DatePicker
                autoOk
                variant="inline"
                label={showDateRange ? "From" : "Date"}
                value={date1 ? new Date(date1) : null}
                onChange={handleFromDateChange}
                format="yyyy/MM/dd"
                maxDate={new Date()}
                style={{
                  width: showDateRange ? "50%" : "100%",
                }}
              />

              {(inputProps) => (
                <TextField
                  {...inputProps}
                  className={classes.rootDialog}
                  InputLabelProps={{
                    classes: {
                      root: classes.activeInputLabelColor,
                      focused: "focused",
                    },
                  }}
                  InputProps={{
                    classes: {
                      root: classes.activeInputColor,
                      focused: "focused",
                      input: classes.resize,
                    },
                  }}
                  label={showDateRange ? "From" : "Date"}
                  style={{
                    width: showDateRange ? "50%" : "100%",
                    fontSize: "100%",
                  }}
                />
              )}
            </MuiPickersUtilsProvider>
            {showDateRange && (
              <>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <DatePicker
                    autoOk
                    variant="inline"
                    label="To"
                    value={date2 ? new Date(date2) : null}
                    onChange={handleToDateChange}
                    format="yyyy/MM/dd"
                    maxDate={new Date()}
                    style={{
                      width: "50%",
                    }}
                  />
                  {(inputProps) => (
                    <TextField
                      {...inputProps}
                      className={classes.rootDialog}
                      InputLabelProps={{
                        classes: {
                          root: classes.activeInputLabelColor,
                          focused: "focused",
                        },
                      }}
                      InputProps={{
                        classes: {
                          root: classes.activeInputColor,
                          focused: "focused",
                          input: classes.resize,
                        },
                      }}
                      label="To"
                      style={{ width: "50%", fontSize: "82%" }}
                    />
                  )}
                </MuiPickersUtilsProvider>
              </>
            )}
            <Checkbox
              checked={showDateRange}
              onChange={handleDateCheckboxChange}
              color="primary"
              style={{ marginLeft: "auto" }}
            />
            <label style={{ fontSize: "12px", marginBottom: 0 }}>
              {showDateRange ? "Date" : "Search In Range"}
            </label>
          </Grid>
        </Grid>
        <Grid
          container
          className={classes.margin}
          style={{ justifyContent: "end" }}
        >
          <Button
            variant="contained"
            className={classes.clearButton}
            disabled={
              txn1 === ""
              && amount1 === ""
              && amount2 === ""
              && date1 === ""
              && date2 === ""
            }
            onClick={() => handleClose()}
          >
            clear
          </Button>
          <Button
            variant="contained"
            color="primary"
            className={`${classes.buttonStyle} ${searchButtonClasses.searchButton}`}
            disabled={
              txn1 === ""
              && amount1 === ""
              && amount2 === ""
              && date1 === ""
              && date2 === ""
            }
            type="submit"
          >
            Search
          </Button>
        </Grid>
      </form>
    </MuiThemeProvider>
  );
}

SearchRefund.propTypes = {
  close: PropTypes.func,
  tableData: PropTypes.object,
  getProviderByFilterData: PropTypes.func,
  seacrhChipData: PropTypes.func,
  addSearchData: PropTypes.func,
  getTransactionHistoryData: PropTypes.func,
};

const mapStateToProps = (app) => ({
  formData: app.genrateOfferPatient,
  tableData: app.provideRegistration,
  userDetails: app.app,
  merchantData: app.genrateOfferPatient,
  transactionData: app.genrateOfferPatient,
});

export default connect(mapStateToProps, {
  setFormDataTransactionHistory,
  clearFormDataTransactionHistory,
  getTransactionHistoryData,
  getProviderByFilterData,
  addSearchData,
})(SearchRefund);
