import * as types from "../Actions/ActionTypes";
import initialState from "./initailState";

const dashboard = (state = initialState.dashboard, action) => {
  switch (action.type) {
  case types.RECENT_CONTACT_LISTS_CALL:
    return { ...state, loading: true };
  case types.RECENT_CONTACT_LISTS_SUCCESS:
    return { ...state, loading: false, recentContactData: action.data };
  case types.RECENT_CONTACT_LISTS_ERROR:
    return { ...state, error: true, loading: false };
  case types.ACCEPT_OFFER_LIST_CALL:
    return { ...state, loading: true };
  case types.ACCEPT_OFFER_LIST_SUCCESS:
    return { ...state, loading: false, recentAcceptOffer: action.data };
  case types.ACCEPT_OFFER_LIST_ERROR:
    return { ...state, error: true, loading: false };
  case types.EXPIRYING_APPLICATION_DATA_CALL:
    return { ...state, loading: true };
  case types.EXPIRYING_APPLICATION_DATA_SUCCESS:
    return { ...state, loading: false, expireApplicationData: action.data };
  case types.EXPIRYING_APPLICATION_DATA_ERROR:
    return { ...state, error: true, loading: false };
  case types.NEW_ACTIVE_PATIENT_DASHBOARD_DATA_CALL:
    return { ...state, loading: true };
  case types.NEW_ACTIVE_PATIENT_DASHBOARD_DATA_SUCCESS:
    return { ...state, loading: false, activePatientData: action.data };
  case types.NEW_ACTIVE_PATIENT_DASHBOARD_DATA_ERROR:
    return { ...state, error: true, loading: false };
  case types.DEFAULT_DASHBOARD_DATA_CALL:
    return { ...state, loading: true };
  case types.DEFAULT_DASHBOARD_DATA_SUCCESS:
    return { ...state, loading: false, defaultData: action.data };
  case types.DEFAULT_DASHBOARD_DATA_ERROR:
    return { ...state, error: true, loading: false };
  case types.MOVE_TO_COLLECTION_DASHBOARD_CALL:
    return { ...state, loading: true };
  case types.MOVE_TO_COLLECTION_DASHBOARD_SUCCESS:
    return { ...state, loading: false, moveToCollectionData: action.data };
  case types.MOVE_TO_COLLECTION_DASHBOARD_ERROR:
    return { ...state, error: true, loading: false };
  case types.CPT_CODE_DISTRIBUTED_DASHBOARD_DATA_CALL:
    return { ...state, loading: true };
  case types.CPT_CODE_DISTRIBUTED_DASHBOARD_DATA_SUCCESS:
    return { ...state, loading: false, cptDistributionData: action.data };
  case types.CPT_CODE_DISTRIBUTED_DASHBOARD_DATA_ERROR:
    return { ...state, error: true, loading: false };
  case types.BAD_DEBATES_DASHBOARD_DATA_CALL:
    return { ...state, loading: true };
  case types.BAD_DEBATES_DASHBOARD_DATA_SUCCESS:
    return { ...state, loading: false, badDebatesData: action.data };
  case types.BAD_DEBATES_DASHBOARD_DATA_ERROR:
    return { ...state, error: true, loading: false };
  case types.TOTAL_OUT_SDANDING_DASHBOARD_DATA_CALL:
    return { ...state, loading: true };
  case types.TOTAL_OUT_SDANDING_DASHBOARD_DATA_SUCCESS:
    return { ...state, loading: false, totalOutStandingData: action.data };
  case types.TOTAL_OUT_SDANDING_DASHBOARD_DATA_ERROR:
    return { ...state, error: true, loading: false };
  case types.DAILY_DEPOSITE_DASHBOARD_DATA_CALL:
    return { ...state, loading: true };
  case types.DAILY_DEPOSITE_DASHBOARD_DATA_SUCCESS:
    return { ...state, loading: false, dailyDepositeData: action.data };
  case types.DAILY_DEPOSITE_DASHBOARD_DATA_ERROR:
    return { ...state, error: true, loading: false };
  default:
    return state;
  }
};

export default dashboard;
