/* eslint-disable import/extensions */
import React from "react";
import IconButton from "@material-ui/core/IconButton";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Menu from "@material-ui/core/Menu";
import { makeStyles } from "@material-ui/core/styles";
import MenuItem from "@material-ui/core/MenuItem";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import styles from "assets/jss/material-dashboard-react/components/businessForm";
import { sendMailAgain } from "actions/MerchantSignUp";
import { useSnackbar } from "notistack";
import AppLoader from "components/AppLoader/Index";
import { sendInviteApiCall } from "../../actions/Operations";
import { actionPermission, permissionCheck } from "../../Utils/commonUtils";

const useStyles = makeStyles(styles);

function MerchantSetupMenu(props) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [managerListData, setManagerListData] = React.useState();
  const [filteredManager, setFilteredManager] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const open = Boolean(anchorEl);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
    props.setValues(pre => {
      pre.status = props.details.Current_Status;
      return pre;
    });
  };

  React.useEffect(() => {
    setManagerListData(
      props.mainData?.genrateOfferPatient?.merchantListNames?.data
    );
  }, [props]);

  React.useEffect(() => {
    if (managerListData && props.id) {
      const filteredData = managerListData.filter(item => item._id === props.id);
      const selectedManager = filteredData.length > 0 ? filteredData[0] : null;
      setFilteredManager(selectedManager);
    }
  }, [managerListData, props.id]);

  const handleClose = type => {
    if (type === "edit") {
      props.history.push(
        `/${props.userDetails.layout}/MerchantDetails?id=${props.id}`
      );
    }
    setAnchorEl(null);
  };

  const handleInviteManager = e => {
    for (let item of managerListData) {
      if (item._id === props.id) {
        localStorage.setItem("businessName", item.Business_Name);
        localStorage.setItem("Manager_uid", item.Manager_Details.Manager_uid);
        localStorage.setItem("User_Id", item.User_Id);
        localStorage.setItem(
          "searchDataMerchant",
          JSON.stringify({
            searchBusinessName: props.merchantListData.searchBusinessName,
            searchDbaName: props.merchantListData.searchDbaName,
            searchManagerId: props.merchantListData.searchManagerId,
            searchStatus: props.merchantListData.searchStatus,
            searchManagerName: props.merchantListData.searchManagerName,
          })
        );
        props.history.push(
          `/${props.userDetails.layout}/ManagerList?id=${props.id}`
        );
      }
    }
  };

  const handleResendInvite = () => {
    const payload = {
      Invite_Mail_Status: false,
      businessName: filteredManager?.Business_Name || null,
      dba: filteredManager?.Dba || null,
      businessWebsite: filteredManager?.Business_Website || null,
      bsinessDescription: filteredManager?.Business_Description || null,
      businessRoom: filteredManager?.Business_Address?.Room_No || null,
      businessFloor: filteredManager?.Business_Address?.Floor || null,
      businessStreet: filteredManager?.Business_Address?.Street || null,
      businessCountry: filteredManager?.Business_Address?.Country || null,
      businessState: filteredManager?.Business_Address?.State || null,
      businessCity: filteredManager?.Business_Address?.City || null,
      businessPostalCode: filteredManager?.Business_Address?.Postal_Code || null,
      mailingRoom: filteredManager?.Mailing_Address?.Room_No || null,
      mailingFloor: filteredManager?.Mailing_Address?.Floor || null,
      mailingStreet: filteredManager?.Mailing_Address?.Street || null,
      mailingCountry: filteredManager?.Mailing_Address?.Country || null,
      mailingState: filteredManager?.Mailing_Address?.State || null,
      mailingCity: filteredManager?.Mailing_Address?.City || null,
      mailingPostalCode: filteredManager?.Mailing_Address?.PostalCode || null,
      routingNumber: filteredManager?.Banking_Details?.Routing_Number || null,
      accountNumber: filteredManager?.Banking_Details?.Account_Number || null,
      confirmAccountNumber: filteredManager?.Banking_Details?.confirmAccountNumber || null,
      confirmRoutingNumber: filteredManager?.Banking_Details?.confirmRoutingNumber || null,
      bankDetailsCannotBeEdited: filteredManager?.Banking_Details?.bankDetailsCannotBeEdited || null,
      AddressDetailsChecked: filteredManager?.AddressDetailsChecked || null,
      feeTypeSurcharge: true,
      feeRate: filteredManager?.FeeType?.FeeTypeName || null,
      perTransactionFee: filteredManager?.FeeType?.perTransactionFee || null,
      percentageFee: filteredManager?.FeeType?.percentageFee || null,
      aboveTransactionValue: filteredManager?.FeeType?.aboveTransactionValue || null,
      none: filteredManager?.FeeType?.none || null,
      feeCanNotBeEdited: filteredManager?.FeeType?.feeCanNotBeEdited || null,
      firstName: filteredManager?.Manager_Details?.First_Name || null,
      lastName: filteredManager?.Manager_Details?.Last_Name || null,
      phoneNumber: filteredManager?.Manager_Details?.Phone_Number || null,
      email: filteredManager?.Manager_Details?.Email || null,
      managerRoom: filteredManager?.Manager_Details?.Room || null,
      managerFloor: filteredManager?.Manager_Details?.Floor || null,
      managerStreet: filteredManager?.Manager_Details?.Street || null,
      managerCountry: filteredManager?.Manager_Details?.Country || null,
      managerState: filteredManager?.Manager_Details?.State || null,
      managerCity: filteredManager?.Manager_Details?.City || null,
      managerPostalCode: filteredManager?.Manager_Details?.PostalCode || null,
      Manager_id: "",
      userId: "",
      Status_Code: filteredManager?.Status_Code || null,
    };
    props.sendInviteApiCall(
      {
        ...payload,
        Resend_Invite: true,
        Business_OBJid:
        filteredManager?._id,
        Manager_OBJid:
        filteredManager?.Manager_Details?._id,
      },
      res => {
        setLoading(false);
        setAnchorEl(null);
        if (res.status === true) {
          enqueueSnackbar(`${res.message}`, {
            variant: "success",
          });
        }
      },
      err => {
        setLoading(false);
        setAnchorEl(null);
        enqueueSnackbar(`${err.message}`, {
          variant: "error",
        });
      }
    );
  };

  return (
    <div>
      <AppLoader loader={loading} />
      {actionPermission(
        permissionCheck.Category.setup,
        permissionCheck.Page.merchant,
        permissionCheck.option.resendInvite
      ) && (props.details.Status_Code === 1 || props.details.Status_Code === 3) ?
        <IconButton
          aria-label="more"
          aria-controls="long-menu"
          style={{ padding: "0px" }}
          aria-haspopup="true"
          onClick={handleClick}
        >
          <MoreVertIcon />
        </IconButton>:""}
      <Menu
        id="long-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
      >
        {props.details.Status_Code === 1 && [
          <MenuItem
            className={classes.dialogBoxDropDown}
            onClick={() => handleResendInvite()}
          >
            Resend Invite
          </MenuItem>,
        ]}
        {props.details.Status_Code === 3 && [
          <MenuItem
            className={classes.dialogBoxDropDown}
            onClick={() => handleInviteManager()}
          >
            Invite Manager
          </MenuItem>,
        ]}
      </Menu>
    </div>
  );
}

MerchantSetupMenu.propTypes = {
  details: PropTypes.object,
  history: PropTypes.any,
  openForm: PropTypes.func,
  setStatusPid: PropTypes.func,
  userDetails: PropTypes.object,
  provider: PropTypes.bool,
  setValues: PropTypes.func,
  sendMailAgain: PropTypes.func,
  roles: PropTypes.object,
  sendInviteApiCall: PropTypes.func,
};

const mapStateToProps = app => ({
  formData: app.provideRegistration,
  roles: app.roles,
});

export default connect(mapStateToProps, {
  sendMailAgain,
  sendInviteApiCall,
})(MerchantSetupMenu);
