import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import styles from "assets/jss/material-dashboard-react/components/businessForm";
import { makeStyles } from "@material-ui/core/styles";

const useBtnStyles = makeStyles(styles);
export default function SessionOutModal({ open, close, logout, remaining }) {
  const btnClasses = useBtnStyles();
  const minutes = Math.floor(+remaining / 60);
  const seconds = remaining % 60;
  const formattedMinutes = String(minutes).padStart(2, "0");
  const formattedSeconds = String(seconds).padStart(2, "0");

  const formattedTimeout = `${formattedMinutes}:${formattedSeconds}`;

  return (
    <div>
      <Dialog
        open={open}
        onClose={close}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Your session is about to expire!"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <p>
              Your session is about to expire. You'll be automatically signed
              out in {formattedTimeout} seconds.
            </p>
            <p>Do you want to stay signed in?</p>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={logout}
            variant="contained"
            className={btnClasses.clearButton}
          >
            Logout
          </Button>
          <Button
            onClick={close}
            variant="contained"
            className={btnClasses.buttonStyle}
          >
            Stay signed in
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
