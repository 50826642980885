import React from "react";
import PropTypes from "prop-types";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
    backgroundColor: "transparent",
  },
}));

function AppLoader(props) {
  const classes = useStyles();

  return (
    <Backdrop className={classes.backdrop} open={props.loader}>
      <CircularProgress style={{ color: "#3956a6" }} color="inherit" />
    </Backdrop>
  );
}

AppLoader.propTypes = {
  loader: PropTypes.bool,
};

export default AppLoader;
