import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Chip from "@material-ui/core/Chip";
import Paper from "@material-ui/core/Paper";
import PropTypes from "prop-types";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "flex-end",
    flexWrap: "wrap",
    listStyle: "none",
    padding: theme.spacing(0.5),
    margin: 0
  },
  chip: {
    margin: theme.spacing(0.5)
  }
}));

function SearchChips({
  searchValues,
  handleDeleteChip
}) {
  const classes = useStyles();

  return (
    <Paper elevation={0} component="ul" className={classes.root}>
      {searchValues.map((chip, index) => (
        <li key={index}>
          <Chip
            label={chip}
            onDelete={() => handleDeleteChip(chip)}
            className={classes.chip}
          />
        </li>
      ))}
    </Paper>
  );
}

SearchChips.propTypes = {
  searchValues: PropTypes.arrayOf(PropTypes.string),
  handleDeleteChip: PropTypes.func
};

export default SearchChips;