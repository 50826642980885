import React from "react";
import { SnackbarProvider } from "notistack";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import * as moment from "moment-timezone";
import { store } from "./Redux/store";

// core components
import "./assets/css/material-dashboard-react.css?v=1.9.0";
import AppView from "./AppView";

const localtz = moment.tz.guess();
moment.tz.setDefault(localtz);
const App = () => (
  <BrowserRouter>
    <SnackbarProvider
      maxSnack={3}
      anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      preventDuplicate
      autoHideDuration={2500}
    >
      <Provider store={store}>
        <AppView />
      </Provider>
    </SnackbarProvider>
  </BrowserRouter>
);

export default App;
