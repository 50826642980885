import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import MenuItem from "@material-ui/core/MenuItem";
import styles from "assets/jss/material-dashboard-react/components/businessForm";
import { useDispatch } from "react-redux";
import AgentSearchChip from "./AgentSearchChip";
import {
  getMerchantListData,
  setFormData,
  clearFormData,
} from "../../Redux/Actions/genrateOfferPatient";

const useStyles = makeStyles(styles);
const searchButtonStyles = makeStyles(theme => ({
  searchButton: {
    backgroundColor: theme.palette.primary.main,
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
      boxShadow: `0 14px 26px -12px rgb(153 153 153 / 42%), 0 4px 23px 0px rgb(0 0 0 / 12%), 0 8px 10px -5px rgb(153 153 153 / 20%)`,
    },
  },
}));

function SimpleDialog(props) {
  const classes = useStyles();
  const searchButtonClasses = searchButtonStyles();
  const dispatch = useDispatch();
  const profileId = localStorage.getItem("profileId");
  const menu = JSON.parse(localStorage.getItem("menu"));
  const [businessName1, setBusinessName1] = React.useState("");
  const [dba1, setDba1] = React.useState("");
  const [searchManagerName, setSearchManagerName] = React.useState("");
  const [status1, setStatus1] = React.useState("");
  const [status1value, setStatus1Value] = React.useState("");
  const [managerId, setManagerId] = React.useState("");

  const clearFormData = () => {
    const initialFormData = {
      searchBusinessName: "",
      searchDbaName: "",
      searchStatus: null,
      searchStatusValue: "",
      searchManagerName: "",
      searchManagerId: "",
    };
    props.setFormData(initialFormData);
  };

  const handleClose = () => {
    props.clearFormData();
    clearFormData();
    props.clearChips();
  };

  const handleStatusChange = value => {
    let statusCode = null;

    switch (value) {
    case "Invited":
      statusCode = 1;
      break;
    case "Saved As Draft":
      statusCode = 2;
      break;
    case "Submitted":
      statusCode = 3;
      break;
    default:
      statusCode = null;
      break;
    }
    setStatus1(statusCode);
    setStatus1Value(value);
  };

  const onChange = e => {
    const { name, value } = e.target;
    if (name === "searchStatus") {
      handleStatusChange(value);
    } else {
      props.setFormData({
        ...props.formData,
        [name]: value,
      });
    }
  };
  const handleSetSearchManagerName = value => {
    setSearchManagerName(value);
  };

  const handleSetSearchManagerId = value => {
    setManagerId(value)
  };

  const setParentSearchManagerName = value => {
    setSearchManagerName(value);
  };

  const setParentSearchManagerId = value => {
    setManagerId(value);
  };

  const onFormSubmit = event => {
    event.preventDefault();
    const submitFormData = {
      searchBusinessName: businessName1.trim(),
      searchManagerName: searchManagerName.trim(),
      searchDbaName: dba1.trim(),
      searchStatus: status1,
      searchStatusValue: status1value,
      searchManagerId: managerId,
    };
    props.setFormData(submitFormData);
    const profileCode = menu?.Role_Permission_Data?.Profile_Code;
    if (profileCode === "Mg") {
      const payload = {
        Profile_id: profileId,
        recordPerPage: 5,
        searchBusinessName: businessName1.trim(),
        searchManagerName: "",
        searchDbaName: dba1.trim(),
        searchStatus: status1,
        searchManagerId: managerId,
        pageNo: 1,
      };
      const payloadWithManagerName = {
        ...payload,
        searchManagerName: searchManagerName.trim(),
      };
      props.seacrhChipData(payloadWithManagerName);
      props.close();
    } else {
      const payload = {
        Profile_Code: menu?.Role_Permission_Data?.Profile_Code,
        recordPerPage: 5,
        searchBusinessName: businessName1.trim(),
        searchManagerName: "",
        searchDbaName: dba1.trim(),
        searchStatus: status1,
        searchManagerId: managerId,
        pageNo: 1,
      };
      const payloadWithManagerName = {
        ...payload,
        searchManagerName: searchManagerName.trim(),
      };
      props.seacrhChipData(payloadWithManagerName);
      props.close();
      localStorage.setItem("pageReset",true)
    }
  };

  React.useEffect(() => {
    if (props.formData.searchBusinessName) {
      setBusinessName1(props.formData.searchBusinessName);
    }
    if (props.formData.searchDbaName) {
      setDba1(props.formData.searchDbaName);
    }
    if (props.formData.searchManagerName) {
      setSearchManagerName(props.formData.searchManagerName);
    }
    if (props.formData.searchStatus) {
      setStatus1(props.formData.searchStatus);
    }
  }, [props.formData]);

  const handleInputChange = e => {
    setBusinessName1(e.target.value);
  };

  const handleInputChange1 = e => {
    setDba1(e.target.value);
  };

  return (
    <>
      <form onSubmit={e => onFormSubmit(e)}>
        <Grid
          container
          className={classes.margin}
          style={{ marginBottom: "5%" }}
        >
          <Grid item xs>
            <TextField
              className={classes.rootDialog}
              InputLabelProps={{
                classes: {
                  root: classes.activeInputLableColor,
                  focused: "focused",
                },
              }}
              InputProps={{
                classes: {
                  root: classes.activeInputColor,
                  focused: "focused",
                  input: classes.resize,
                },
              }}
              label="Business Name"
              value={businessName1}
              onChange={handleInputChange}
              name="searchBusinessName"
              id="Business Name"
              style={{ fontSize: "82%" }}
            />
          </Grid>
        </Grid>
        <Grid
          container
          className={classes.margin}
          style={{ marginBottom: "5%" }}
        >
          <Grid item xs>
            <TextField
              className={classes.rootDialog}
              InputLabelProps={{
                classes: {
                  root: classes.activeInputLableColor,
                  focused: "focused",
                },
              }}
              InputProps={{
                classes: {
                  root: classes.activeInputColor,
                  focused: "focused",
                  input: classes.resize,
                },
              }}
              label="DBA Name"
              value={dba1}
              onChange={handleInputChange1}
              name="searchDbaName"
              id="DBA"
              style={{ fontSize: "82%" }}
            />
          </Grid>
        </Grid>

        <Grid
          container
          className={classes.margin}
          style={{ marginBottom: "5%" }}
        >
          <AgentSearchChip
            onChange={(value, type) => {
              setFormData({
                ...props.formData,
                [type]: value,
              });
              props.seacrhChipData({ ...props.formData, [type]: value });
              setParentSearchManagerName(value);
              setParentSearchManagerId(value);
            }}
            setParentSearchManagerName={handleSetSearchManagerName}
            setParentSearchManagerId={handleSetSearchManagerId}
            values={searchManagerName}
            propsf={props.formData.searchManagerName}
            clearSearchManagerName={() => {
              dispatch(
                setFormData({ 
                  ...props.formData, 
                  searchManagerName: "",
                  searchManagerId: "",
                })
              );
            }}
          />
        </Grid>

        <Grid
          container
          className={classes.margin}
          style={{ marginBottom: "5%" }}
        >
          <Grid item xs>
            <TextField
              className={classes.rootDialog}
              InputLabelProps={{
                classes: {
                  root: classes.activeInputLableColor,
                  focused: "focused",
                },
              }}
              InputProps={{
                classes: {
                  root: classes.activeInputColor,
                  input: classes.resize,
                  focused: "focused",
                },
              }}
              placeholder="Select Status"
              label="Status"
              value={
                (status1 === 1
                  ? "Invited"
                  : status1 === 2
                    ? "Saved As Draft"
                    : status1 === 3
                      ? "Submitted"
                      : "")
              }
              onChange={onChange}
              name="searchStatus"
              id="Current_Status"
              select
              style={{ fontSize: "82%" }}
            >
              <MenuItem className={classes.dialogBoxDropDown} value="Invited">
                Invited
              </MenuItem>
              <MenuItem className={classes.dialogBoxDropDown} value="Submitted">
                Submitted
              </MenuItem>
              <MenuItem
                className={classes.dialogBoxDropDown}
                value="Saved As Draft"
              >
                Saved As Draft
              </MenuItem>
            </TextField>
          </Grid>
        </Grid>

        <Grid
          container
          className={classes.margin}
          style={{ justifyContent: "end" }}
        >
          <Button
            variant="contained"
            className={classes.clearButton}
            disabled={
              businessName1 === ""
              && dba1 === ""
              && status1 === ""
              && searchManagerName === ""
            }
            onClick={() => handleClose()}
          >
            clear
          </Button>
          <Button
            variant="contained"
            color="primary"
            className={`${classes.buttonStyle} ${searchButtonClasses.searchButton}`}
            disabled={
              businessName1 === ""
              && dba1 === ""
              && status1 === ""
              && searchManagerName === ""
            }
            type="submit"
          >
            Search
          </Button>
        </Grid>
      </form>
    </>
  );
}

SimpleDialog.propTypes = {
  close: PropTypes.func,
  seacrhChipData: PropTypes.func,
  getMerchantListData: PropTypes.func,
};

const mapStateToProps = app => ({
  formData: app.genrateOfferPatient,
  tableData: app.provideRegistration,
  userDetails: app.app,
  merchantData: app.genrateOfferPatient,
});

export default connect(mapStateToProps, {
  setFormData,
  clearFormData,
  getMerchantListData,
})(SimpleDialog);
