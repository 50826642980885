import { get, put } from "./httpClient";
import {
  MERCHANT_SIGNUP_RESEND_MAIL,
  UPDATE_TEMPLETE_UNDER_PROVIDER_ID,
  GET_PRICING_TEMPLTE_DATA,
  GET_APPLICATION_TYPE_LIST,
  GET_PROCCERSOR_LIST,
  MERCHANT_INVITE_TEMPLETE_CALL,
} from "../Uintls/Urls";

export const sendMailAgain =
  (payload, successCallback, failureCallback) => async () => {
    try {
      const data = await get(MERCHANT_SIGNUP_RESEND_MAIL, payload);

      if (typeof successCallback === "function") {
        successCallback(data);
      }
    } catch (err) {
      if (typeof failureCallback === "function") {
        failureCallback(err);
      }
    }
  };

export const getPricingTemplete =
  (successCallback, failureCallback, payload) => async () => {
    try {
      const data = await get(GET_PRICING_TEMPLTE_DATA, payload);

      if (typeof successCallback === "function") {
        successCallback(data);
      }
    } catch (err) {
      if (typeof failureCallback === "function") {
        failureCallback(err);
      }
    }
  };

export const getApplicationType =
  (successCallback, failureCallback) => async () => {
    try {
      const data = await get(GET_APPLICATION_TYPE_LIST);

      if (typeof successCallback === "function") {
        successCallback(data);
      }
    } catch (err) {
      if (typeof failureCallback === "function") {
        failureCallback(err);
      }
    }
  };

export const getProccesorList =
  (successCallback, failureCallback) => async () => {
    try {
      const data = await get(GET_PROCCERSOR_LIST);

      if (typeof successCallback === "function") {
        successCallback(data);
      }
    } catch (err) {
      if (typeof failureCallback === "function") {
        failureCallback(err);
      }
    }
  };

export const updatePricingTemplete =
  (payload, successCallback, failureCallback) => async () => {
    try {
      const data = await put(UPDATE_TEMPLETE_UNDER_PROVIDER_ID);

      if (typeof successCallback === "function") {
        successCallback(data);
      }
    } catch (err) {
      if (typeof failureCallback === "function") {
        failureCallback(err);
      }
    }
  };

export const templateFieldsDetailsCall =
  (payload, successCallback, failureCallback) => async () => {
    try {
      const data = await get(MERCHANT_INVITE_TEMPLETE_CALL, payload);

      if (typeof successCallback === "function") {
        successCallback(data);
      }
    } catch (err) {
      if (typeof failureCallback === "function") {
        failureCallback(err);
      }
    }
  };
