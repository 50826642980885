import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Box, Modal } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Card from "components/Card/Card";
import Button from "components/CustomButtons/Button";
import styles from "assets/jss/material-dashboard-react/components/businessForm";
import TextField from "@material-ui/core/TextField";
import fullOrigin from "../../assets/img/imgpsh_fullsize_anim.png";
import AlreadyRegister from "./AlreadyRegister";
import gif from "../../assets/img/success.gif";
import ChangePassword from "./ChangePassword";
import {
  patientRegister,
  confirmForgetPassword,
  changePasswordCall,
} from "../../Redux/Actions/genrateOfferPatient";
import bgImage from "../../assets/img/7OHMVp.jpg";

const useStyles = makeStyles(styles);
const PatientRegister = props => {
  const classes = useStyles();
  const [showChangePassword, setShowChangePassword] = React.useState(
    props.formData.authTokenCheck
  );
  const [passwordResetModal, setPasswordResetModal] = React.useState(false);
  const [prePasswordReset, setPrePasswordReset] = React.useState(
    props.formData.changePassword
  );
  const [values, handleInput] = React.useState({
    firstName: "",
    lastName: "",
    email: "",
    mobileNumber: "",
    password: "",
    confirmPassword: "",
  });
  const [preRegisterData, setPreRegisterData] = React.useState(
    props.formData.patientRegisterData
  );
  const [confirmationModal, setConfirmationModal] = React.useState();
  const [validationError, setValidationError] = React.useState({
    firstName: false,
    lastName: false,
    email: false,
    mobileNumber: false,
    password: false,
    confirmPassword: false,
    confirmPasswordCombine: false,
  });
  const [data, setData] = React.useState(false);

  if (!data && props.formData.showPatientInfoViaMail.status) {
    const preData = props.formData.showPatientInfoViaMail.data;
    const allState = { ...values };
    allState.firstName = preData.firstName;
    allState.lastName = preData.lastName;
    allState.email = preData.email;
    allState.mobileNumber = preData.phoneNumber;
    handleInput(allState);
    setData(true);
  }
  const onChange = (value, type) => {
    const allState = { ...values };
    const error = { ...validationError };
    allState[type] = value;
    if (type === "confirmPassword") {
      if (allState.password !== allState[type]) {
        error.confirmPasswordCombine = true;
        error.password = true;
      } else {
        error.confirmPasswordCombine = false;
        error.password = false;
      }
    } else {
      error[type] = false;
    }
    handleInput(allState);
    setValidationError(error);
  };

  React.useEffect(() => {
    if (preRegisterData !== props.formData.patientRegisterData) {
      if (
        props.formData.patientRegisterData
        && props.formData.patientRegisterData.status
      ) {
        setConfirmationModal(true);
      }
      setPreRegisterData(props.formData.patientRegisterData);
    }
    if (showChangePassword !== props.formData.authTokenCheck) {
      setShowChangePassword(props.formData.authTokenCheck);
    }
    if (prePasswordReset !== props.formData.changePassword) {
      setPrePasswordReset(props.formData.changePassword);
      if (
        props.formData.changePassword
        && props.formData.changePassword.status
      ) {
        setPasswordResetModal(true);
      }
    }
  }, [props]);

  const handleConfirmationModal = () => {
    setConfirmationModal(false);
    setPasswordResetModal(false);
    props.history.push("/");
  };

  const checkValidation = () => {
    let checkValue = true;
    const value = { ...values };
    const allValidation = { ...validationError };
    Object.keys(validationError).forEach(v => {
      if (v === "password") {
        if (value[v] === "") {
          checkValue = true;
          allValidation[v] = true;
        } else if (value[v] !== value.confirmPassword) {
          checkValue = true;
          allValidation.confirmPasswordCombine = true;
        }
      } else if (value[v] === "") {
        checkValue = true;
        allValidation[v] = true;
      }
    });
    setValidationError(allValidation);
    return checkValue;
  };

  const onFormSubmit = () => {
    if (checkValidation()) {
      const data = {
        patientId: props.formData.showPatientInfoViaMail.data.patientId,
        password: values.password,
        confirmPassword: values.confirmPassword,
      };
      props.patientRegister(data);
    }
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundImage: `url(${bgImage})`,
        width: "100%",
        height: "100vh",
      }}
    >
      {!props.formData.loading && (
        <>
          {props.formData.showPatientInfoViaMail.status && (
            <>
              <Card style={{ width: "90%" }}>
                <>
                  <div style={{ margin: "15px" }}>
                    <Grid container>
                      <Grid item xs align="center">
                        <img
                          src={fullOrigin}
                          width="10%"
                          height="auto"
                          alt=""
                        />
                      </Grid>
                    </Grid>

                    <Grid
                      container
                      style={{ marginTop: "2%", marginBottom: "1%" }}
                    >
                      <Grid item xs={3} />
                      <Grid item xs={8} align="left">
                        Please fill up the details below to register your
                        account
                      </Grid>
                      <Grid item xs={1} />
                    </Grid>
                    <Grid container>
                      <Grid item xs={3} />
                      <Grid
                        item
                        xs={8}
                        align="left"
                        style={{ fontWeight: "bold" }}
                      >
                        Step 1: Verify your Account Information
                      </Grid>
                      <Grid item xs={1} />
                    </Grid>
                    <Grid container>
                      <Grid item xs={3} />
                      <Grid item xs={4}>
                        <TextField
                          error={validationError.firstName}
                          className={classes.root}
                          InputLabelProps={{
                            classes: {
                              root: classes.activeInputLableColor,
                              focused: "focused",
                            },
                          }}
                          InputProps={{
                            classes: {
                              root: classes.activeInputColor,
                              input: classes.resize,
                              focused: "focused",
                            },
                            readOnly: true,
                          }}
                          label="First Name"
                          value={values.firstName}
                          onChange={e => onChange(e.target.value, "firstName")}
                          name="firstName"
                          id="firstName"
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <TextField
                          error={validationError.lastName}
                          className={classes.root}
                          InputLabelProps={{
                            classes: {
                              root: classes.activeInputLableColor,
                              focused: "focused",
                            },
                          }}
                          InputProps={{
                            classes: {
                              root: classes.activeInputColor,
                              input: classes.resize,
                              focused: "focused",
                            },
                            readOnly: true,
                          }}
                          label="Last Name"
                          value={values.lastName}
                          onChange={e => onChange(e.target.value, "lastName")}
                          name="lastName"
                          id="lastName"
                        />
                      </Grid>
                      <Grid item xs={1} />
                    </Grid>
                    <Grid container style={{ marginTop: "1%" }}>
                      <Grid item xs={3} />
                      <Grid item xs={4}>
                        <TextField
                          error={validationError.mobileNumber}
                          className={classes.root}
                          InputLabelProps={{
                            classes: {
                              root: classes.activeInputLableColor,
                              focused: "focused",
                            },
                          }}
                          InputProps={{
                            classes: {
                              root: classes.activeInputColor,
                              input: classes.resize,
                              focused: "focused",
                            },
                            readOnly: true,
                          }}
                          label="Mobile Number"
                          value={values.mobileNumber}
                          onChange={e => onChange(e.target.value, "mobileNumber")}
                          name="mobileNumber"
                          id="mobileNumber"
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <TextField
                          className={classes.root}
                          error={validationError.email}
                          InputLabelProps={{
                            classes: {
                              root: classes.activeInputLableColor,
                              focused: "focused",
                            },
                          }}
                          InputProps={{
                            classes: {
                              root: classes.activeInputColor,
                              input: classes.resize,
                              focused: "focused",
                            },
                            readOnly: true,
                          }}
                          label="Email"
                          value={values.email}
                          onChange={e => onChange(e.target.value, "email")}
                          name="email"
                          id="email"
                        />
                      </Grid>
                      <Grid item xs={1} />
                    </Grid>
                    <Grid container style={{ marginTop: "2%" }}>
                      <Grid item xs={3} />
                      <Grid
                        item
                        xs={8}
                        align="left"
                        style={{ fontWeight: "bold" }}
                      >
                        Step 2: Create your password
                      </Grid>
                      <Grid item xs={1} />
                    </Grid>
                    <Grid container>
                      <Grid item xs={3} />
                      <Grid item xs={4}>
                        <TextField
                          error={validationError.password}
                          className={classes.root}
                          InputLabelProps={{
                            classes: {
                              root: classes.activeInputLableColor,
                              focused: "focused",
                            },
                          }}
                          InputProps={{
                            classes: {
                              root: classes.activeInputColor,
                              input: classes.resize,
                              focused: "focused",
                            },
                          }}
                          label="Password"
                          type="password"
                          value={values.password}
                          onChange={e => onChange(e.target.value, "password")}
                          name="password"
                          id="password"
                          helperText={`${
                            validationError.confirmPasswordCombine
                              ? "Password and Confirm Password should same"
                              : ""
                          }`}
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <TextField
                          error={validationError.confirmPassword}
                          className={classes.root}
                          InputLabelProps={{
                            classes: {
                              root: classes.activeInputLableColor,
                              focused: "focused",
                            },
                          }}
                          InputProps={{
                            classes: {
                              root: classes.activeInputColor,
                              input: classes.resize,
                              focused: "focused",
                            },
                          }}
                          label="Confirm Password"
                          type="password"
                          value={values.confirmPassword}
                          onChange={e => onChange(e.target.value, "confirmPassword")}
                          name="confirmPassword"
                          id="confirmPassword"
                        />
                      </Grid>
                      <Grid item xs={1} />
                    </Grid>
                    <Grid container className={classes.margin}>
                      <Box className={classes.buttonBox}>
                        <Button
                          variant="contained"
                          className={classes.buttonStyle}
                          color="#696969"
                          size="large"
                          onClick={() => onFormSubmit()}
                        >
                          Register
                        </Button>
                      </Box>
                    </Grid>
                  </div>
                </>
                <Modal
                  open={confirmationModal}
                  onClose={() => setConfirmationModal(false)}
                >
                  <div className={classes.modalDiv}>
                    <Card className={classes.modalCard}>
                      <div className={classes.cardDiv}>
                        <img src={gif} alt="" width="50%" />
                        <div className={classes.successText}>
                          Registration successfully done. Continue to login
                        </div>
                        <br />
                        <Button
                          variant="contained"
                          className={classes.buttonStyle}
                          color="#696969"
                          size="sm"
                          onClick={() => handleConfirmationModal()}
                        >
                          Ok
                        </Button>
                      </div>
                    </Card>
                  </div>
                </Modal>
                <Modal
                  open={passwordResetModal}
                  onClose={() => setPasswordResetModal(false)}
                >
                  <div className={classes.modalDiv}>
                    <Card className={classes.modalCard}>
                      <div className={classes.cardDiv}>
                        <img src={gif} width="50%" alt="" />
                        <div className={classes.successText}>
                          {prePasswordReset.message}
                        </div>
                        <br />
                        <Button
                          variant="contained"
                          className={classes.buttonStyle}
                          color="#696969"
                          size="sm"
                          onClick={() => handleConfirmationModal()}
                        >
                          Ok
                        </Button>
                      </div>
                    </Card>
                  </div>
                </Modal>
              </Card>
            </>
          )}
          {props.formData.showPatientInfoViaMail
            && !props.formData.showPatientInfoViaMail.status && (
            <AlreadyRegister {...props} />
          )}
          {!props.formData.showPatientInfoViaMail && (
            <ChangePassword {...props} />
          )}
        </>
      )}
    </div>
  );
};

PatientRegister.propTypes = {
  formData: PropTypes.object,
  patientRegister: PropTypes.func,
  history: PropTypes.object,
  confirmForgetPassword: PropTypes.func,
  changePasswordCall: PropTypes.func,
};

const mapStateToProps = app => ({
  formData: app.genrateOfferPatient,
});

export default connect(mapStateToProps, {
  patientRegister,
  confirmForgetPassword,
  changePasswordCall,
})(PatientRegister);
