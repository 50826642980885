import * as types from "../Actions/ActionTypes";
import initialState from "./initailState";

const payout = (state = initialState.payout, action) => {
  switch (action.type) {
    case types.GET_PAYOUT_LASTCALCDATE_CALL:
      return { ...state, loading: true };
    case types.GET_PAYOUT_LASTCALCDATE_SUCCESS:
      return { ...state, loading: false, lastCalcDate: action.data };
    case types.GET_PAYOUT_LASTCALCDATE_ERROR:
      return { ...state, error: true, loading: false };
    default:
      return state;
  }
};

export default payout;
