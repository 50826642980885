import * as types from "../Actions/ActionTypes";
import initialState from "./initailState";

const provideRegistration = (
  state = initialState.provideRegistration,
  action
) => {
  switch (action.type) {
    case types.PROVIDE_REGISTRATION_CALL:
      return { ...state, loading: true, providerList: false };
    case types.PROVIDE_REGISTRATION_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.data
      };
    case types.PROVIDE_REGISTRATION_EMPTY:
      return {
        ...state,
        loading: false,
        data: false
      };
    case types.PROVIDE_REGISTRATION_ERROR:
      return { ...state, error: true, loading: false };
    case types.MCC_LIST_CALL:
      return { ...state, loading: true };
    case types.MCC_LIST_SUCCESS:
      action.data.MCC_Data = action.data.MCC_Data.sort(
        (a, b) => a.MCC_Code - b.MCC_Code
      );
      return {
        ...state,
        loading: false,
        mcclist: action.data
      };
    case types.MCC_LIST_ERROR:
      return { ...state, error: true, loading: false };
    case types.COUNTEY_TYPE_LIST_CALL:
      return { ...state, loading: true };
    case types.COUNTEY_TYPE_LIST_SUCCESS:
      return { ...state, countryTypeList: action.data, loading: false };
    case types.COUNTEY_TYPE_LIST_ERROR:
      return {
        ...state,
        loading: false,
        error: true
      };
    case types.ACCOUNT_TYPE_LIST_CALL:
      return { ...state, loading: true };
    case types.ACCOUNT_TYPE_LIST_SUCCESS:
      return { ...state, loading: false, accountTypeList: action.data };
    case types.ACCOUNT_TYPE_LIST_ERROR:
      return { ...state, error: true, loading: false };
    case types.OWNER_TYPE_LIST_CALL:
      return { ...state, loading: true };
    case types.OWNER_TYPE_LIST_SUCCESS:
      return { ...state, loading: false, ownerShipTypeList: action.data };
    case types.OWNER_TYPE_LIST_ERROR:
      return { ...state, error: true, loading: false };
    case types.PROVIDER_LIST_CALL:
      return { ...state, loading: true };
    case types.PROVIDER_LIST_SUCCESS:
      return { ...state, loading: false, providerList: action.data };
    case types.PROVIDER_LIST_ERROR:
      return { ...state, error: true, loading: false };
    case types.PROVIDER_LIST_BY_ID_CALL:
      return { ...state, loading: true, providerDetailsById: false };
    case types.PROVIDER_LIST_BY_ID_SUCCESS:
      return { ...state, loading: false, providerDetailsById: action.data };
    case types.PROVIDER_LIST_BY_ID_ERROR:
      return { ...state, error: true, loading: false };
    case types.GET_PROVIDER_STATUS_CALL:
      return { ...state, loading: true };
    case types.GET_PROVIDER_STATUS_SUCCESS:
      return { ...state, loading: false, statusList: action.data };
    case types.GET_PROVIDER_STATUS_ERROR:
      return { ...state, error: true, loading: false };
    case types.PROVIDER_FORM_MODE:
      return {
        ...state,
        formMode: action.data,
        saveProviderParameter: false,
        providerDetailsById: false
      };
    case types.PROVIDER_LIST_BY_FILTERDATA_SUCCESS:
      return {
        ...state,
        loading: false,
        search: true,
        providerList: action.data
      };
    case types.PROVIDER_STATUS_CHANGE_SINGLE_CALL:
      return { ...state, loading: true, statusUpdate: false };
    case types.PROVIDER_STATUS_CHANGE_SINGLE_SUCCESS:
      return { ...state, loading: false, statusUpdate: action.data };
    case types.PROVIDER_STATUS_CHANGE_SINGLE_ERROR:
      return { ...state, error: true, loading: false };
    case types.PROVIDER_ACTIVITY_BY_ID_CALL:
      return { ...state, loading: true };
    case types.PROVIDER_ACTIVITY_BY_ID_SUCCESS:
      return { ...state, loading: false, providerActivityById: action.data };
    case types.PROVIDER_ACTIVITY_BY_ID_ERROR:
      return { ...state, error: true, loading: false };
    case types.PROVIDER_PARAMETER_CALL:
      return { ...state, loading: true };
    case types.PROVIDER_PARAMETER_SUCCESS:
      return { ...state, loading: false, providerParameterData: action.data };
    case types.PROVIDER_PARAMETER_ERROR:
      return { ...state, error: true, loading: false };
    case types.SAVE_PROVIDER_PARAMETER_CALL:
      return { ...state, loading: true };
    case types.SAVE_PROVIDER_PARAMETER_SUCCESS:
      return { ...state, loading: false, saveProviderParameter: action.data };
    case types.SAVE_PROVIDER_PARAMETER_ERROR:
      return { ...state, error: true, loading: false };
    case types.PROVIDER_PARAMETER_FORM_MODE:
      return { ...state, saveProviderParameter: action.data, loading: false };
    case types.PROVIDER_PARAMETER_BY_ID_CALL:
      return { ...state, loading: true };
    case types.PROVIDER_PARAMETER_BY_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        getProviderParameterById: action.data
      };
    case types.PROVIDER_PARAMETER_BY_ID_ERROR:
      return { ...state, error: true, loading: false };
    case types.DELETE_INCROPRATION_DOCUMENT_CALL:
      return { ...state, loading: true };
    case types.DELETE_INCROPRATION_DOCUMENT_SUCCESS:
      return { ...state, loading: false, deleteIncropration: action.data };
    case types.DELETE_INCROPRATION_DOCUMENT_ERROR:
      return { ...state, error: true, loading: false };
    case types.DELETE_ADDRESS_DOCUMENT_CALL:
      return { ...state, loading: true };
    case types.DELETE_ADDRESS_DOCUMENT_SUCCESS:
      return { ...state, loading: false, deleteAddress: action.data };
    case types.DELETE_ADDRESS_DOCUMENT_ERROR:
      return { ...state, error: true, loading: false };
    case types.DELETE_IDENTITY_DOCUMENT_CALL:
      return { ...state, loading: true };
    case types.DELETE_IDENTITY_DOCUMENT_SUCCESS:
      return { ...state, loading: false, deleteIdProof: action.data };
    case types.DELETE_IDENTITY_DOCUMENT_ERROR:
      return { ...state, error: true, loading: false };
    case types.GET_QUESTION_DATA_CALL:
      return { ...state, loading: true };
    case types.GET_QUESTION_DATA_SUCCESS:
      return { ...state, loading: false, questionData: action.data };
    case types.GET_QUESTION_DATA_ERROR:
      return { ...state, error: true, loading: false };
    case types.GET_KYC_QUESTIONS_CALL:
      return { ...state, loading: true };
    case types.GET_KYC_QUESTIONS_SUCCESS:
      return { ...state, loading: false, kycQuestionData: action.data };
    case types.GET_KYC_QUESTIONS_ERROR:
      return { ...state, error: true, loading: false };
    case types.SAVE_QUESTION_DATA_CALL:
      return { ...state, loading: true };
    case types.SAVE_QUESTION_DATA_SUCCESS:
      return { ...state, loading: false, questionUpdate: action.data };
    case types.SAVE_QUESTION_DATA_ERROR:
      return { ...state, error: true, loading: false };

    case types.GET_PRICING_TEMPLETE_DATA_CALL:
      return { ...state, loading: true };
    case types.GET_PRICING_TEMPLETE_DATA_SUCCESS:
      return { ...state, loading: false, pricingTemplete: action.data };
    case types.GET_PRICING_TEMPLETE_DATA_ERROR:
      return { ...state, error: true, loading: false };

    case types.SET_PRICING_TEMPLETE_EMPTY:
      return { ...state, pricingTemplete: false };
    case types.GET_EQUIPMENT_LIST_DATA_CALL:
      return { ...state, loading: true };
    case types.GET_EQUIPMENT_LIST_DATA_SUCCESS:
      return { ...state, loading: false, Equipment: action.data };
    case types.GET_EQUIPMENT_LIST_DATA_ERROR:
      return { ...state, error: true, loading: false };
    case types.SET_ALL_EMPTY_Question:
      return {
        ...state,
        loading: false,
        questionData: false,
        kycQuestionData: false
      };
    case types.PROVIDER_USER_LIST_CALL:
      return { ...state, loading: true };
    case types.PROVIDER_USER_LIST_SUCCESS:
      return { ...state, loading: false, provideruserListById: action.data };
    case types.PROVIDER_USER_LIST_ERROR:
      return { ...state, error: true, loading: false };
    case types.ADD_MERCHANT_SEARCH_DATA:
      return {
        ...state,
        loading: false,
        searchDetails: action.data
      };
    case types.EMPTY_SEARCH_DATA:
      return {
        ...state,
        loading: false,
        providerList: false,
        searchDetails: {
          searchDbaName: "",
          searchStatus: "",
          searchAgentId: "",
          apiType: "search",
          recordPerPage: 10,
          pageNo: 1,
          sortColumn: "Updated_date",
          sortOrder: "desc",
          statusExpression: "NOT_IN"
        }
      };
    case types.GET_AGENT_HIERARCHY_CALL:
      return { ...state, loading: true };
    case types.GET_AGENT_HIERARCHY_CALL_SUCCESS:
      return { ...state, loading: false, agentList: action.data };
    case types.GET_AGENT_HIERARCHY_CALL_ERROR:
      return { ...state, error: true, loading: false };

    case types.GET_NOTES_LIST_CALL:
      return { ...state, loading: true };
    case types.GET_NOTES_LIST_SUCCESS:
      return { ...state, loading: false, notesList: action.data };
    case types.GET_NOTES_LIST_ERROR:
      return { ...state, error: true, loading: false };

    default:
      return state;
  }
};

export default provideRegistration;
