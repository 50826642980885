const url = process.env.REACT_APP_API_BASE_PATH;
const loginUrl = process.env.REACT_APP_API_BASE_LOGIN_PATH;

export const LOGIN_URL = `${url}/user/login`;
export const VIKING_LOGIN_URL = `${loginUrl}/user/login`;

export const LOGIN_OTP_URL = `${url}/user/login-otp`;

export const COUNTRY_CODE_URL = `${url}/country`;

export const ACTIVE_PATIENT_URL = `${url}/dashboard/patients-data`;

export const PROVIDE_REGISTRATION_URL = `${url}/provider`;

export const PROVIDE_UPDATION_URL = `${url}/provider`;

export const MCC_LIST_URL = `${url}/mcc`;

export const OWNER_SHIP_LIST_URL = `${url}/ownershiptype`;

export const COMMON_COUNTRY_TBALE_URL = `${url}/country`;

export const ACCOUNT_TYPE_LIST_URL = `${url}/accounttype`;

export const PROVIDER_LIST_URL = `${url}/provider`;

export const GET_PROVIDER_LIST_BY_ID = `${url}/provider`;

export const GET_PROVIDER_STATUS_URL = `${url}/providerstatus`;

export const PROVIDER_LIST_BY_SEARCH_URL = `${url}/provider`;

export const PROVIDER_STATUS_CHANGE_URL = `${url}/provider/updatestatus`;

export const PROVIDERS_THEME_UPDATE_URL = `${url}/update-provider-theme`;

export const PROVIDERS_THEME_LIST_URL = `${url}/theme-list`;

export const MASTER_TIMEZONE_LIST_URL = `${url}/timezone`;

export const PROVIDER_PANEL_DATA_URL = `${url}/provider-theme-data`;

export const PORTAL_LOGO_UPLOAD_URL = `${url}/upload-logo`;

export const PROVIDERS_PORTAL_THEME_URL = `${url}/provider-theme`;

export const ROLE_LIST_URL = `${url}/roles`;

export const ROLE_PAGE_PERMISSION = `${url}/page-permission`;

export const USER_LIST_URL = `${url}/user`;

export const COMMON_PROFILE_URL = `${url}/profile`;

export const USER_REGISTRATION_URL = `${url}/user`;

export const GET_USER_LIST_BY_ID = `${url}/user`;

export const USER_STATUS_CHANGE_URL = `${url}/user/updatestatus`;

export const USER_CHANGE_PASSWORD_URL = `${url}/user/changepassword`;

export const LANDING_PAGE_LIST_URL = `${url}/roles/landingPage`;

export const GET_ROLE_LIST_BY_ID = `${url}/roles`;

export const USER_LIST_BY_SEARCH_URL = `${url}/user`;

export const ROLE_LIST_BY_SEARCH_URL = `${url}/roles`;

export const TIMEZONE_LIST_URL = `${url}/timezone`;

export const USER_PROVIDER_LIST = `${url}/provider/boardedproviderlist`;

export const PROVIDER_ROLE_LIST_URL = `${url}/getproviderroles`;

export const MOVE_TO_COLLECTION_GRAPH_API = `${url}/dashboard/move-to-collection-graph?`;

export const DAILY_DEPOSITE_DASHBOARD_DATA_URL = `${url}/dashboard/daliy-deposit-graph`;

export const CPT_CODE_GRAPH_API = `${url}/dashboard/cpt-code-data`;

export const MEMBER_RESGISTRATION_GRAPH_API = `${url}/dashboard/new-member-register-data`;

export const BAD_DEBITS_GRAPH_API = `${url}/dashboard/bad-debts-data`;

export const SEND_TO_COLLECCTION_GRAPH_API = `${url}/dashboard/sent-to-collections`;

export const TOTAL_OUT_SDANDING_GRAPH_API = `${url}/dashboard/total-out-standing`;

export const DEFAULT_GRAPH_API = `${url}/dashboard/defaults`;

export const DAILY_DEPOSIT_URL = `${url}/dashboard/daliy-deposit-data`;

export const TOTAL_OUTSTANDING_URL = `${url}/dashboard/total-out-standing-data`;

export const DEFAULTS_URL = `${url}/dashboard/defaults-data`;

export const MOVE_TO_COLLECTION_URL = `${url}/dashboard/sent-to-collections-data`;

export const BED_DEBTS_URL = `${url}/dashboard/bad-debts-data`;

export const NEW_MEMBER_URL = `${url}/dashboard/new-member-register-data`;

export const PROVIDER_SEARCH_URL = `${url}/provider/search`;

export const PROVIDER_ACTIVITY_BY_ID_URL = `${url}/provider/activity`;

export const PROVIDER_PARAMETER_URL = `${url}/providerparameter`;

export const ROLE_STATUS_UPDATE_URL = `${url}/roles/updatestatus`;

export const GENRATE_OFFER_PATIENT_SEARCH_URL = `${url}/reports/patients/search`;

export const UPLOAD_DOCUMNET_FINAL_OFFER = `${url}/upload-signed-acceptance`;

export const SEND_OFFER_EMAIL_ID_URL = `${url}/sendofferemail`;

export const SHOW_EMAIL_PATIENT_DETAILS_URL = `${url}/patients/view-offer`;

export const UPDATE_PATIENT_DETAILS_OFFER_URL = `${url}/edit-patient-info`;

export const PATIENT_GENRATE_OFFER_URL = `${url}/patients/generate-offer`;

export const PERSONAL_LOAN_OFFER_URL = `${url}/payment/link`;

export const CREATE_OFFER_PATIENT_URL = `${url}/create-offer`;

export const PRINT_OFFER_PATIENT_URL = `${url}/patients/printoffer`;

export const DOWNLOAD_PATIENT_OFFER_DOCUMENTS_URL = `${url}/download`;

export const DELETE_PATIENT_OFFER_DOCUMENTS_URL = `${url}/delete-signed-acceptance`;

export const PATIENT_INFO_BILLING_DETAILS_URL = `${url}/patient-info`;

export const MAKE_PATIENT_PAYMENT_URL = `${url}/make-payment`;

export const SHOW_EMAIL_PATIENT_INFO_URL = `${url}/patients/register-info`;

export const PATIENT_REGISTER_URL = `${url}/patients/register`;

export const PATIENT_OFFER_GRAPH_URL = `${url}/patient/offer-graph`;

export const PATIENT_FORGET_PASSWORD_URL = `${url}/user/forgot-password`;

export const AUTO_GENERATED_REPO_CODE = `${url}/user/autogenerate-repocode`;

export const CHECK_RESET_PASSWORD_AUTH_CHECK_URL = `${url}/user/check-email-token`;

export const CHANGE_PATIENT_PASSWORD_URL = `${url}/user/changepassword`;

export const SERVICES_CPT_CODE_PATIENT_URL = `${url}/cpt-code-master`;

export const ADD_PATIENT_DETAILS_OFFER_URL = `${url}/patient/create`;

export const UPDATE_PATIENT_DETAILS_URL = `${url}/patient/update`;

export const ACTIVE_PLAN_PATIENT_LIST_URL = `${url}/patients/active-list`;

export const PATIENT_DOCUMENT_LIST_URL = `${url}/upload-signed-acceptance-list`;

export const OFFER_DOWNLOAD_FILE_URL = `${url}/offer/download`;

export const ADD_PROPOSAL_DETAILS_URL = `${url}/proposal`;

export const SETUP_MONTHLY_PAYMENT_URL = `${url}/offer/monthly-setup`;

export const COMPLETED_PLAN_PATIENT_LIST_URL = `${url}/offer/completed-list`;

export const ACTIVE_PLAN_PATIENT_LISTS_URL = `${url}/patients/dashboard/active-lists`;

export const OPEN_FUNDING_AMOUNT_URL = `${url}/patient/dashboard/open-funding`;

export const PENDING_OFFER_AMOUNT_URL = `${url}/patient/dashboard/pending-offers`;

export const PAYMENT_STATUS_URL = `${url}/patient/dashboard/payment-status`;

export const OFFER_ACCOUNT_STATUS_URL = `${url}/patient/dashboard/account-status`;

export const ORIGINAL_LOAN_TERM_URL = `${url}/patient/dashboard/original-loan-term`;

export const REMAINING_FUND_GRAPH_URL = `${url}/patient/dashboard/remaining-funding-graph`;

export const RECENT_CONTACT_LISTS_URL = `${url}/dashboard/recent-contacts`;

export const ACCEPT_OFFER_LIST_URL = `${url}/dashboard/accept-offer-list`;

export const EXPIRYING_APPLICATION_DATA_URL = `${url}/dashboard/expiring-application`;

export const NEW_ACTIVE_PATIENT_DASHBOARD_DATA_URL = `${url}/dashboard/new-active-patients-graph`;

export const DEFAULT_DASHBOARD_DATA_URL = `${url}/dashboard/defaults-graph-fig`;

export const MOVE_TO_COLLECTION_DASHBOARD_URL = `${url}/dashboard/move-to-collection-graph`;

export const CPT_CODE_DISTRIBUTED_DASHBOARD_DATA_URL = `${url}/dashboard/cpt-code-graphs`;

export const BAD_DEBATES_DASHBOARD_DATA_URL = `${url}/dashboard/bad-debates-graph`;

export const TOTAL_OUT_SDANDING_DASHBOARD_DATA_URL = `${url}/dashboard/out-statnding-graph`;

export const PAYMENT_LIST_AS_PER_PATIENT_ID_URL = `${url}/payment-list`;

export const CURRENTLY_SETUP_MONTH_PATIENT_URL = `${url}/patient/dashboard/current-monthly-setup`;

export const BEFORE_PAYMENT_SEND_OTP_URL = `${url}/payment-otp`;

export const VERIFY_PAYMENT_OTP_URL = `${url}/verify-otp`;

export const PENDING_OFFER_LISTS_URL = `${url}/patient/report/pending-offers-data`;

export const OPEN_FUNDING_LISTS_URL = `${url}/patient/report/open-funding-data`;

export const PATIENT_TPO_SCORE_URL = `${url}/patient-score`;

export const GENERATE_PATIENT_TPO_SCORE_URL = `${url}/patient-tpo-score`;

export const ACCOUNT_STATEMENT_LISTS_URL = `${url}/patient/report/account-statement-list`;

export const PAYMENT_CHECKOUT_URL =
  "https://checkout.securepds.com/checkout/checkout.svc/JSON/GenerateToken";

export const DEFAULT_THEME_CALL = `${url}/common-theme-data`;

export const GLOBAL_PATIENT_SEARCH_URL = `${url}/reports/patients/cerner-search`;

export const ONBORDING_PROVIDER_URL = `${url}/user`;

export const DELETE_INCROPRATION_DOCUMENT_URL = `${url}/provider/delete-incorportion`;

export const DELETE_ADDRESS_DOCUMENT_URL = `${url}/provider/delete-addressProof`;

export const DELETE_IDENTITY_DOCUMENT_URL = `${url}/provider/delete-photoId`;

export const MERCHANT_SIGNUP_RESEND_MAIL = `${url}/mail-sent-user`;

export const GET_QUESTION_DATA_URL = `${url}/mpa-checklist`;

export const GROUP_LIST_DATA_URL = `${url}/group`;

export const GET_GROUP_LIST_URL = `${url}/group`;

export const CREATE_NEW_GROUP_URL = `${url}/group-save`;

export const UPDATE_GROUP_DATA_URL = `${url}/group-update`;

export const GET_GROUP_TYPE_LIST_URL = `${url}/group-type-list`;

export const GET_KYC_QUESTIONS_URL = `${url}/kyc-checklist`;

export const SAVE_QUESTION_DATA_URL = `${url}/provider-checklist-save`;

export const QUESTION_REPORT_CHANGE_STATUS = `${url}/mpa-progress`;

export const QUESTION_REPORT_KYC_CHANGE_STATUS = `${url}/kyc-progress`;

export const KYC_STATUS_APPROVE_CALL = `${url}/kycchecklist-status`;

export const MPA_STATUS_APPROVE_CALL = `${url}/mpachecklist-status`;

export const CHECKLIST_COMMENT_SAVE = `${url}/provider/checklist-comment-save`;

export const MPA_ATTECHMENT_CHECKLIST_REVIEW_SAVE = `${url}/provider/mpachecklist-attachment-save`;

export const KYC_ATTECHMENT_CHECKLIST_REVIEW_SAVE = `${url}/provider/kycchecklist-attachment-save`;

export const USER_LEFT_MENU_URL = `${url}/user/left-menu`;

export const GENRATE_TOKEN_ALT_PAY = `${url}/generate-token`;

export const CREATE_INVOICE_ALT_PAY = `${url}/create-invoice`;

export const GET_PRICING_TEMPLTE_DATA = `${url}/pricingTemplete`;

export const GET_APPLICATION_TYPE_LIST = `${url}/appicationType`;

export const GET_PROCCERSOR_LIST = `${url}/proccers`;

export const MERCHANT_INVITE_TEMPLETE_CALL = `${url}/merchant-invite-template`;

export const UPDATE_TEMPLETE_UNDER_PROVIDER_ID = `${url}/merchant-pricing-template`;

export const GET_PRICING_TEMPLETE_DATA = `${url}/provider-template`;

export const GET_KYC_RESPONSE_JSON_DATA = `${url}/provider-kyc-response`;

export const UPDATE_PRICING_TEMPLATE_CALL = `${url}/update-merchant-template`;

export const PROVIDER_USER_LIST_URL = `${url}/provider/user-list`;

export const PROVIDER_KYC_CHECKLIST_SUB_QUESTIONS = `${url}/kyc-checklist/sub-ques-save`;

export const GET_EQUIPMENT_CATEGORY_DATA = `${url}/equipmentCategory`;

export const ADDTIONAL_INFORMATION_SAVE = `${url}/provider/additional-info`;

export const UPDATE_ADDTIONAL_INFORMATION_CALL = `${url}/provider/additional-info-update`;

export const DELETE_BANK_DOCUMENT_API_CALL = `${url}/provider/delete-bankdocument`;

export const DELETE_OWNER_API_CALL = `${url}/provider/delete-owner`;

export const GET_PRICING_TEMPLETE_LIST = `${url}/pricingTemplete`;

export const PRICING_TEMPLETE_CURD = `${url}/template-items`;

export const GET_EQUIPMENT_CATEGORY_LIST = `${url}/equipment`;

export const GET_SIGNNOW_CALL = `${url}/sign-now`;

export const GET_DOC_STATUS = `${url}/provider/addendum`;

export const USER_HIERARCHY_LIST = `${url}/user/GetAgentByHierarchyTree`;

export const GET_AGENT_LIST_HIERARCHY = `${url}/user/GetAgentByHierarchy`;

export const GET_MERCHANT_LIST_HIERARCHY = `${url}/user/GetMerchantByHierarchy`;

export const USER_IMPORT_FILE = `${url}/userimportfile`;

export const PRICINGSCHEDULE_IMPORT_FILE = `${url}/pricing-schedule-importfile`;

export const PRICINGSCHEDULE_NEWITEMS_FILE = `${url}/pricing-schedule-importfile`;

export const PRICINGSCHEDULE_UPDATE_FILE = `${url}/pricing-schedule-importfile-update`;

export const CREATE_NOTES = `${url}/note`;

export const SHOW_NOTES_LIST = `${url}/note`;

export const GET_USER_PROFILE_IMAGE = `${url}/user`;

export const PAYMENT_LINK_VERIFY_CALL = `${url}/payment/verify/link`;

export const MAKE_PAYMENT_URL = `${url}/new/payment`;

export const SIGN_UP_URL = `${url}/merchant/add`;
export const SUBMIT_API_URL = `${url}/merchant/add/bussiness`;

export const ROUTING_NUMBER_URL = `${url}/rest-api/wallet/report/routing-number/query`;
export const ACCOUNT_NUMBER_URL = `${url}/check/account/rtn`;

export const SEND_MONEY_CALL = `${url}/rest-api/wallet/send-dfi`;
export const GET_MERCHANT_LIST = `${url}/merchant/list`;
export const GET_MERCHANT_LIST_NAMES = `${url}/merchant/list`;
export const GET_MANAGER_LIST = `${url}/manager/list`;
export const SENT_INVITE_MANAGER_CALL = `${url}/manager/invite/submanager`;
export const MANAGER_UPDATE_CALL = `${url}/manager/update`;
export const GET_TRANSACTION_LIST = `${url}/transaction/list`;
export const GET_ERROR_CODE = `${url}/errorcode`;
export const GET_API_LOGS = `${url}/api/logs`;
export const GET_MANAGER_ACTIONS_LIST = `${url}/link/business`;
export const GET_MANAGER_ACTIONS = `${url}/link/manager`;
export const GET_TRANSACTION_HISTORY = `${url}/transactionhistory`;
export const ADD_INDIVIDUAL_API_CALL = `${url}/add-individual`;
export const GET_EMAIL_LIST_URL = `${url}/manager/get-manager-list`;
export const GET_EMAIL_DATA_URL = `${url}/manager/manager-detail`;
export const SEND_OTP_CALL = `${url}/signup/otp`;
