/* eslint-disable no-nested-ternary */
import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import {
  useLocation,
  useHistory,
  Link,
} from "react-router-dom";
import ListIcon from "@material-ui/icons/List";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import Button from "components/CustomButtons/Button";
import { useTheme } from "@material-ui/core/styles";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { setBackToSearchFromDetails } from "Redux/Actions/genrateOfferPatient";

import styles from "assets/jss/material-dashboard-react/components/headerStyle";
import AdminNavbarLinks from "./AdminNavbarLinks";
import { logout } from "../../Redux/Actions/LoginActions";

const useStyles = makeStyles(styles);


function Header(props) {
  const history = useHistory();
  const location = useLocation();
  const url = location.pathname;
  const theme = useTheme();
  const windowWidth = useMediaQuery(theme.breakpoints.up("md"));
  const [temp, setTemp] = React.useState(false);
  const classes = useStyles();
  const { color } = props;
  const appBarClasses = classNames({
    [` ${classes[color]}`]: color,
  });

  const handleBackMobile = () => {
    history.goBack();
    props.setBackToSearchFromDetails();
    setTemp(!temp);
  };
  const handleClose = () => {
    props.setopen();
    if (props.openDrawer) {
      props.setOpenDrawerHover(false);
    }
  };

  let modalHead = "";
  const urlName = history?.location?.pathname?.split("/")[2]?.toLowerCase();
  const pageName = props?.app?.split("/")[1]?.toLowerCase();
  const pageUrl = urlName?.includes("-") && urlName?.replace(/-/g, " ");

  if (urlName === pageName) {
    modalHead = props.app;
  } else if (urlName?.includes("-")) {
    modalHead = `${props.app.split("/")[0]}/${" "}${pageUrl}`;
  } else {
    modalHead = `${props.app.split("/")[0]}/${" "}${
      history.location.pathname.split("/")[2]
    }`;
  }
  modalHead = modalHead.replace(/([a-z])([A-Z])/g, "$1 $2");

  const includeHistory = history?.location?.pathname
    ?.split("/")[2]
    ?.toLowerCase()
    ?.includes(modalHead.replace(/\s+/g, "")?.split("/")[1]?.toLowerCase());

  // breadcrumbs
  const segments = url.split("/").filter((segment) => segment !== ""); // Split URL by '/' and remove empty segments
  const breadcrumbItems = [];

  let accumulatedPath = "";
  const generateDisplayName = (segment) => {
    if (segment.includes("-")) {
      return segment
        .split("-")
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(" ");
    } else {
      if (/[A-Z]/.test(segment)) {
        return segment.replace(/([A-Z])/g, " $1");
      } else {
        return segment.charAt(0).toUpperCase() + segment.slice(1);
      }
    }
  };
  for (let i = 0; i < segments.length; i++) {
    accumulatedPath += `/${segments[i]}`;
    breadcrumbItems.push(
      <span key={i}>
        {i === 0 ? (
          props.app.split("/")[0]
        ) : (
          <Link to={accumulatedPath}>{generateDisplayName(segments[i])}</Link>
        )}
        {i < segments.length - 1 && " / "}
      </span>
    );
  }

  return (
    <AppBar className={classes.appBar + appBarClasses}>
      <Toolbar
        className={classes.container}
        style={{ display: "flex", justifyContent: "space-between" }}
      >
        <div>
          {includeHistory || windowWidth ? (
            <Button
              onClick={() => handleClose()}
              color="white"
              aria-label="edit"
              justIcon
              round
            >
              {props.openDrawer ? (
                <MoreVertIcon style={{ color: "#000" }} fontSize="large" />
              ) : (
                <ListIcon style={{ color: "#000" }} fontSize="large" />
              )}
            </Button>
          ) : includeHistory ? (
            <Button
              onClick={() => handleClose()}
              color="white"
              aria-label="edit"
              justIcon
              round
            >
              {props.openDrawer ? (
                <MoreVertIcon style={{ color: "#000" }} fontSize="large" />
              ) : (
                <ListIcon style={{ color: "#000" }} fontSize="large" />
              )}
            </Button>
          ) : (
            <Button
              onClick={() => handleBackMobile()}
              color="white"
              aria-label="edit"
              justIcon
              round
            >
              <ArrowBackIcon style={{ color: "#000" }} fontSize="large" />
            </Button>
          )}
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          {/* {modalHead} */}
          {breadcrumbItems}
        </div>
        <div className={classes.hideHeader}>
          <AdminNavbarLinks {...props} />
        </div>
      </Toolbar>
    </AppBar>
  );
}

Header.propTypes = {
  color: PropTypes.oneOf(["primary", "info", "success", "warning", "danger"]),
  rtlActive: PropTypes.bool,
  handleDrawerToggle: PropTypes.func,
  setopen: PropTypes.func,
  openDrawer: PropTypes.any,
  setState: PropTypes.func,
  menuData: PropTypes.object,
  logout: PropTypes.func,
  displayName: PropTypes.string,
  app: PropTypes.object,
  setBackToSearchFromDetails: PropTypes.func,
  setCallRolePermission: PropTypes.func,
  setOpenDrawerHover: PropTypes.func,
};

const mapStateToProps = ({ userRole, app }) => ({
  menuData: userRole,
  app: app.appBarName,
});

export default connect(mapStateToProps, { logout, setBackToSearchFromDetails })(
  Header
);
