import moment from "moment";
import isEmpty from "lodash/isEmpty";
import * as types from "./ActionTypes";
import { post, get, put } from "../Helper/index";
import * as urls from "../../Uintls/Urls";

const changeTime = (date) => {
  if (date) {
    const newDate = moment(date).format("YYYY-MM-DD");
    return newDate;
  }
};

export const provideRegistration = (data) => (dispatch) => {
  dispatch({ type: types.PROVIDE_REGISTRATION_CALL });
  data.ownerArray.forEach((element) => {
    element.Owner_DOB = changeTime(element.Owner_DOB);
  });
  post({
    url: urls.PROVIDE_REGISTRATION_URL,
    success: types.PROVIDE_REGISTRATION_SUCCESS,
    failure: types.PROVIDE_REGISTRATION_ERROR,
    dispatch,
    body: {
      UserId: localStorage.getItem("userId"),
      guId: "",
      Business_Name: !isEmpty(data.businessName)
        ? data.businessName
        : data.legalBusiness,
      Legal_Name: data.legalBusiness,
      Business_Start_Date: changeTime(data.businessStartDate),
      MCC: data.USID,
      Business_Description: data.businessDiscrition,
      Ownership_Type: data.ownerShipType,
      Business_Phone: data.businessPhoneNumber,
      Business_EMail: data.businessEmail,
      Website: data.businessWebsite,
      Fedral_Tax_ID: data.businessTaxId,
      Stock_Symbol: data.businessStock,
      Business_Address: data.businessAddress,
      Suite: data.businessSuite,
      Country_Code: data.businessCountry,
      businessType: data.businessType,
      State_Code: data.businessState,
      City: data.businessCity,
      International_Province: data.businessInterNational,
      Postal_Code: data.businessPostalCode,
      Account_Number: data.accountNumber,
      Routing_Number: data.routingNumber,
      Name_On_Account: data.legalBusiness,
      Account_Type: data.accountType,
      Card_Swiped: data.cardPresent,
      Card_Not_Present: data.cardNotPresent,
      ECommerce: data.eCommers,
      Monthly_Card_Volume: data.cardVolumeMonth,
      Average_Ticket_Size: data.averageTicketSize,
      High_Ticket: data.highTicket,
      Monthly_ACH_Volume: data.eCheckVolumeMonth,
      Average_ACH: data.eCheckAverage,
      incorportionDocument: data.incorportionDocument,
      incorporationName: data.incorportionDocumentDetails.File_Name,
      bankDocumentType: data.bankDocumentType,
      bankDocumentsName: data.bankDocumentType,
      bankDocuments: data.bankDocuments,
      High_ACH: data.eCheckHigh,
      masterContactName: data.masterContactName,
      foreignEntityNonresident: !!data.foreignEntityNonresident,
      fax: data.fax,
      mailingAddress: data.mailingAddress,
      transmitCardholderOption: !!data.transmitCardholderOption,
      transmitCardholderName: data.transmitCardholderName,
      seasonalSales: !!data.seasonalSales,
      comments: data.comments,
      locationAddress: data.locationAddress,
      Owner_Information: data.ownerArray,
      timeZoneID: 1,
      Created_By: "Admin",
      previewSubmit: data.previewSubmit,
      ipAddress: data.ipAddress,
      contactArray: data.contactArray,
      nameTermsCondition: data.nameTermsCondition,
      titleTermsCondition: data.titleTermsCondition,
      gasPosTerm: data.gasPosTerm ? data.gasPosTerm : false
    }
  });
};

export const updateRegistration = (data, id) => (dispatch) => {
  dispatch({ type: types.PROVIDE_REGISTRATION_CALL });
  data.ownerArray.forEach((element) => {
    element.Owner_DOB = changeTime(element.Owner_DOB);
  });
  put({
    url: urls.PROVIDE_REGISTRATION_URL,
    success: types.PROVIDE_REGISTRATION_SUCCESS,
    failure: types.PROVIDE_REGISTRATION_ERROR,
    dispatch,
    body: {
      ...data,
      UserId: localStorage.getItem("userId"),
      pid: id,
      Business_Name: !isEmpty(data.businessName)
        ? data.businessName
        : data.legalBusiness,
      Legal_Name: data.legalBusiness,
      Business_Start_Date: changeTime(data.businessStartDate),
      MCC: data.USID,
      Business_Description: data.businessDiscrition,
      Ownership_Type: data.ownerShipType,
      Business_Phone: data.businessPhoneNumber,
      incorporationName: data.incorportionDocumentDetails.File_Name,
      Business_EMail: data.businessEmail,
      Website: data.businessWebsite,
      Fedral_Tax_ID: data.businessTaxId,
      businessType: data.businessType,
      Stock_Symbol: data.businessStock,
      Business_Address: data.businessAddress,
      Suite: data.businessSuite,
      Country_Code: data.businessCountry,
      State_Code: data.businessState,
      City: data.businessCity,
      International_Province: data.businessInterNational,
      Postal_Code: data.businessPostalCode,
      Account_Number: data.accountNumber,
      Routing_Number: data.routingNumber,
      Name_On_Account: data.legalBusiness,
      Account_Type: data.accountType,
      Card_Swiped: data.cardPresent,
      Card_Not_Present: data.cardNotPresent,
      ECommerce: data.eCommers,
      Monthly_Card_Volume: data.cardVolumeMonth,
      Average_Ticket_Size: data.averageTicketSize,
      High_Ticket: data.highTicket,
      Monthly_ACH_Volume: data.eCheckVolumeMonth,
      Average_ACH: data.eCheckAverage,
      High_ACH: data.eCheckHigh,
      masterContactName: data.masterContactName,
      foreignEntityNonresident: !!data.foreignEntityNonresident,
      fax: data.fax,
      mailingAddress: data.mailingAddress,
      transmitCardholderOption: !!data.transmitCardholderOption,
      transmitCardholderName: data.transmitCardholderName,
      seasonalSales: !!data.seasonalSales,
      comments: data.comments,
      locationAddress: data.locationAddress,
      Owner_Information: data.ownerArray,
      incorportionDocument: data.incorportionDocument,
      bankDocumentType: data.bankDocumentType,
      bankDocuments: data.bankDocuments,
      bankDocumentsName: data.bankDocumentType,
      timeZoneID: 1,
      Created_By: "Admin",
      Current_Status: data.status,
      previewSubmit: data.previewSubmit,
      ipAddress: data.ipAddress,
      contactArray: data.contactArray,
      nameTermsCondition: data.nameTermsCondition,
      titleTermsCondition: data.titleTermsCondition,
      gasPosTerm: data.gasPosTerm,
      termAndConditions: data.termAndConditions,
      discription: data.discription
    }
  });
};

export const clearRegData = () => (dispatch) => {
  dispatch({ type: types.PROVIDE_REGISTRATION_EMPTY });
};

export const getMccList = () => (dispatch) => {
  dispatch({ type: types.MCC_LIST_CALL });
  get({
    url: urls.MCC_LIST_URL,
    success: types.MCC_LIST_SUCCESS,
    failure: types.MCC_LIST_ERROR,
    dispatch
  });
};

export const getCountryTypeList = () => (dispatch) => {
  dispatch({ type: types.COUNTEY_TYPE_LIST_CALL });
  get({
    url: urls.COMMON_COUNTRY_TBALE_URL,
    success: types.COUNTEY_TYPE_LIST_SUCCESS,
    failure: types.COUNTEY_TYPE_LIST_ERROR,
    dispatch
  });
};

export const accountTypeList = () => (dispatch) => {
  dispatch({ type: types.ACCOUNT_TYPE_LIST_CALL });
  get({
    url: urls.ACCOUNT_TYPE_LIST_URL,
    success: types.ACCOUNT_TYPE_LIST_SUCCESS,
    failure: types.ACCOUNT_TYPE_LIST_ERROR,
    dispatch
  });
};

export const ownerTypeList = () => (dispatch) => {
  dispatch({ type: types.OWNER_TYPE_LIST_CALL });
  get({
    url: urls.OWNER_SHIP_LIST_URL,
    success: types.OWNER_TYPE_LIST_SUCCESS,
    failure: types.OWNER_TYPE_LIST_ERROR,
    dispatch
  });
};

export const getProviderList = () => (dispatch) => {
  dispatch({ type: types.PROVIDER_LIST_CALL });
  get({
    url: urls.PROVIDER_LIST_URL,
    success: types.PROVIDER_LIST_SUCCESS,
    failure: types.PROVIDER_LIST_ERROR,
    dispatch,
    body: false
  });
};

export const getProviderByFilterData = (data) => (dispatch) => {
  const finalData = { ...data };
  finalData.apiType = "search";
  dispatch({ type: types.PROVIDER_LIST_BY_FILTERDATA_CALL });
  get({
    url: `${urls.PROVIDER_LIST_BY_SEARCH_URL}`,
    success: types.PROVIDER_LIST_BY_FILTERDATA_SUCCESS,
    failure: types.PROVIDER_LIST_BY_FILTERDATA_ERROR,
    dispatch,
    body: finalData
  });
};

export const getProviderDetailsById = (data) => (dispatch) => {
  dispatch({ type: types.PROVIDER_LIST_BY_ID_CALL });
  get({
    url: `${urls.GET_PROVIDER_LIST_BY_ID}/${data}`,
    success: types.PROVIDER_LIST_BY_ID_SUCCESS,
    failure: types.PROVIDER_LIST_BY_ID_ERROR,
    dispatch
  });
};

export const getProviderStatusAction = (data) => (dispatch) => {
  dispatch({ type: types.GET_PROVIDER_STATUS_CALL });
  const updatedData = data;
  updatedData.initialCall = true;
  dispatch({
    type: types.GET_PROVIDER_STATUS_SUCCESS,
    data: { ...updatedData }
  });
};

export const getProviderStatus = (data) => (dispatch) => {
  dispatch({ type: types.GET_PROVIDER_STATUS_CALL });
  if (data === undefined) {
    get({
      url: urls.GET_PROVIDER_STATUS_URL,
      success: types.GET_PROVIDER_STATUS_SUCCESS,
      failure: types.GET_PROVIDER_STATUS_ERROR,
      dispatch,
      body: ""
    });
  } else {
    get({
      url: urls.GET_PROVIDER_STATUS_URL,
      success: types.GET_PROVIDER_STATUS_SUCCESS,
      failure: types.GET_PROVIDER_STATUS_ERROR,
      dispatch,
      body: data
    });
  }
};

export const changeProviderFormMode = (data) => ({
  type: types.PROVIDER_FORM_MODE,
  data
});

export const changeSingleStatus = (data) => (dispatch) => {
  dispatch({ type: types.PROVIDER_STATUS_CHANGE_SINGLE_CALL });
  put({
    url: urls.PROVIDER_STATUS_CHANGE_URL,
    success: types.PROVIDER_STATUS_CHANGE_SINGLE_SUCCESS,
    failure: types.PROVIDER_STATUS_CHANGE_SINGLE_ERROR,
    dispatch,
    body: {
      UserId: localStorage.getItem("userId"),
      pid: data.id,
      Current_Status: data.status
    }
  });
};

export const getProviderActivityById = (data) => (dispatch) => {
  dispatch({ type: types.PROVIDER_ACTIVITY_BY_ID_CALL });
  get({
    url: urls.PROVIDER_ACTIVITY_BY_ID_URL,
    success: types.PROVIDER_ACTIVITY_BY_ID_SUCCESS,
    failure: types.PROVIDER_ACTIVITY_BY_ID_ERROR,
    dispatch,
    body: data
  });
};

export const getProviderParameterCall = (data) => (dispatch) => {
  dispatch({ type: types.PROVIDER_PARAMETER_CALL });
  get({
    url: urls.PROVIDER_PARAMETER_URL,
    success: types.PROVIDER_PARAMETER_SUCCESS,
    failure: types.PROVIDER_PARAMETER_ERROR,
    dispatch,
    body: data
  });
};

export const saveProviderParameter = (data) => (dispatch) => {
  dispatch({ type: types.SAVE_PROVIDER_PARAMETER_CALL });
  post({
    url: urls.PROVIDER_PARAMETER_URL,
    success: types.SAVE_PROVIDER_PARAMETER_SUCCESS,
    failure: types.SAVE_PROVIDER_PARAMETER_ERROR,
    dispatch,
    body: data
  });
};

export const changeProviderParameterFormMode = (data) => ({
  type: types.PROVIDER_PARAMETER_FORM_MODE,
  data
});

export const getProviderParameterById = (data) => (dispatch) => {
  dispatch({ type: types.PROVIDER_PARAMETER_BY_ID_CALL });
  get({
    url: `${urls.PROVIDER_PARAMETER_URL}/${data}`,
    success: types.PROVIDER_PARAMETER_BY_ID_SUCCESS,
    failure: types.PROVIDER_PARAMETER_BY_ID_ERROR,
    dispatch,
    body: data
  });
};

export const deleteIncroptration = (data) => (dispatch) => {
  dispatch({ type: types.DELETE_INCROPRATION_DOCUMENT_CALL });
  get({
    url: `${urls.DELETE_INCROPRATION_DOCUMENT_URL}`,
    success: types.DELETE_INCROPRATION_DOCUMENT_SUCCESS,
    failure: types.DELETE_INCROPRATION_DOCUMENT_ERROR,
    dispatch,
    body: data
  });
};

export const deleteAddressProof = (data) => (dispatch) => {
  dispatch({ type: types.DELETE_ADDRESS_DOCUMENT_CALL });
  get({
    url: `${urls.DELETE_ADDRESS_DOCUMENT_URL}`,
    success: types.DELETE_ADDRESS_DOCUMENT_SUCCESS,
    failure: types.DELETE_ADDRESS_DOCUMENT_ERROR,
    dispatch,
    body: data
  });
};

export const deleteIdentityProof = (data) => (dispatch) => {
  dispatch({ type: types.DELETE_IDENTITY_DOCUMENT_CALL });
  get({
    url: `${urls.DELETE_IDENTITY_DOCUMENT_URL}`,
    success: types.DELETE_IDENTITY_DOCUMENT_SUCCESS,
    failure: types.DELETE_IDENTITY_DOCUMENT_ERROR,
    dispatch,
    body: data
  });
};

export const getQuestionData = (data) => (dispatch) => {
  dispatch({ type: types.GET_QUESTION_DATA_CALL });
  get({
    url: `${urls.GET_QUESTION_DATA_URL}`,
    success: types.GET_QUESTION_DATA_SUCCESS,
    failure: types.GET_QUESTION_DATA_ERROR,
    dispatch,
    body: data
  });
};

export const getKycQuestionData = (data) => (dispatch) => {
  dispatch({ type: types.GET_KYC_QUESTIONS_CALL });
  get({
    url: `${urls.GET_KYC_QUESTIONS_URL}`,
    success: types.GET_KYC_QUESTIONS_SUCCESS,
    failure: types.GET_KYC_QUESTIONS_ERROR,
    dispatch,
    body: data
  });
};

export const saveQuestionCall = (data) => (dispatch) => {
  dispatch({ type: types.SAVE_QUESTION_DATA_CALL });
  post({
    url: urls.SAVE_QUESTION_DATA_URL,
    success: types.SAVE_QUESTION_DATA_SUCCESS,
    failure: types.SAVE_QUESTION_DATA_ERROR,
    dispatch,
    body: data
  });
};
export const saveQuestionCallAction = (data) => (dispatch) => {
  dispatch({ type: types.SAVE_QUESTION_DATA_CALL });
  // post({
  //   url: urls.SAVE_QUESTION_DATA_URL,
  //   success: types.SAVE_QUESTION_DATA_SUCCESS,
  //   failure: types.SAVE_QUESTION_DATA_ERROR,
  //   dispatch,
  //   body: data,
  // });
  dispatch({ type: types.SAVE_QUESTION_DATA_SUCCESS, data });
};

export const setResetWuestion = () => ({
  type: types.SET_ALL_EMPTY_Question
});

export const pricingTempleteCall = (data) => (dispatch) => {
  dispatch({ type: types.GET_PRICING_TEMPLETE_DATA_CALL });
  get({
    url: urls.GET_PRICING_TEMPLETE_DATA,
    success: types.GET_PRICING_TEMPLETE_DATA_SUCCESS,
    failure: types.GET_PRICING_TEMPLETE_DATA_ERROR,
    dispatch,
    body: data
  });
};
export const pricingTempleteCallInvite = (data) => (dispatch) => {
  dispatch({ type: types.GET_PRICING_TEMPLETE_DATA_CALL });
  get({
    url: urls.MERCHANT_INVITE_TEMPLETE_CALL,
    success: types.GET_PRICING_TEMPLETE_DATA_SUCCESS,
    failure: types.GET_PRICING_TEMPLETE_DATA_ERROR,
    dispatch,
    body: data
  });
};
export const getInviteEquipmentData = (data) => (dispatch) => {
  dispatch({ type: types.GET_EQUIPMENT_LIST_DATA_CALL });
  get({
    url: `${urls.GET_EQUIPMENT_CATEGORY_LIST}`,
    success: types.GET_EQUIPMENT_LIST_DATA_SUCCESS,
    failure: types.GET_EQUIPMENT_LIST_DATA_ERROR,
    dispatch,
    body: data
  });
};
export const setPricingTempleteEmpty = () => ({
  type: types.SET_PRICING_TEMPLETE_EMPTY
});
export const getKycResponseUserList = (data) => (dispatch) => {
  dispatch({ type: types.PROVIDER_USER_LIST_CALL });
  get({
    url: urls.PROVIDER_USER_LIST_URL,
    success: types.PROVIDER_USER_LIST_SUCCESS,
    failure: types.PROVIDER_USER_LIST_ERROR,
    dispatch,
    body: data
  });
};

export const getSignNowCall = (data) => (dispatch) => {
  dispatch({ type: types.GET_SIGNNOW_CALL });
  get({
    url: urls.GET_SIGNNOW_CALL,
    success: types.GET_SIGNNOW_CALL,
    failure: types.GET_SIGNNOW_CALL,
    dispatch,
    body: data
  });
};

export const addSearchData = (data) => ({
  type: types.ADD_MERCHANT_SEARCH_DATA,
  data
});

export const removeSearchData = () => ({
  type: types.EMPTY_SEARCH_DATA
});

export const getAgentByHierarchy = (data) => (dispatch) => {
  dispatch({ type: types.GET_AGENT_HIERARCHY_CALL });
  get({
    url: `${urls.GET_AGENT_LIST_HIERARCHY}`,
    success: types.GET_AGENT_HIERARCHY_CALL_SUCCESS,
    failure: types.GET_AGENT_HIERARCHY_CALL_ERROR,
    dispatch,
    body: data
  });
};

export const getMerchantByHierarchy = (data) => (dispatch) => {
  dispatch({ type: types.GET_MERCHANT_HIERARCHY_CALL });
  get({
    url: `${urls.GET_MERCHANT_LIST_HIERARCHY}`,
    success: types.GET_MERCHANT_HIERARCHY_CALL_SUCCESS,
    failure: types.GET_MERCHANT_HIERARCHY_CALL_ERROR,
    dispatch,
    body: data
  });
};

export const getNotesList = (data) => (dispatch) => {
  dispatch({ type: types.GET_NOTES_LIST_CALL });
  get({
    url: `${urls.SHOW_NOTES_LIST}`,
    success: types.GET_NOTES_LIST_SUCCESS,
    failure: types.GET_NOTES_LIST_ERROR,
    dispatch,
    body: data
  });
};
