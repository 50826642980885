import { get, post, put } from "./httpClient";
import {
  PROVIDERS_THEME_LIST_URL,
  PROVIDERS_THEME_UPDATE_URL,
  PROVIDERS_PORTAL_THEME_URL,
  MASTER_TIMEZONE_LIST_URL,
  PROVIDER_PANEL_DATA_URL,
  PORTAL_LOGO_UPLOAD_URL,
  PROVIDER_LIST_BY_SEARCH_URL,
} from "../Uintls/Urls";

export const ThemeListFetchCall = (successCallback, failureCallback) => async () => {
  try {
    const data = await get(PROVIDERS_THEME_LIST_URL);

    if (typeof successCallback === "function") {
      successCallback(data);
    }
  } catch (err) {
    if (typeof failureCallback === "function") {
      failureCallback(err);
    }
  }
};

export const UpdatePanelTheme = (payload, successCallback, failureCallback) => async () => {
  try {
    const data = await post(PROVIDERS_THEME_UPDATE_URL, payload);

    if (typeof successCallback === "function") {
      successCallback(data);
    }
  } catch (err) {
    if (typeof failureCallback === "function") {
      failureCallback(err);
    }
  }
};

export const FetchPortalThemeCall = (successCallback, failureCallback) => async () => {
  try {
    const clientId = localStorage.getItem("clientId");
    const data = await get(PROVIDERS_PORTAL_THEME_URL, { clientId });

    if (typeof successCallback === "function") {
      successCallback(data);
    }
  } catch (err) {
    if (typeof failureCallback === "function") {
      failureCallback(err);
    }
  }
};

export const timeZoneListFetchCall = (successCallback, failureCallback) => async () => {
  try {
    const data = await get(MASTER_TIMEZONE_LIST_URL);

    if (typeof successCallback === "function") {
      successCallback(data);
    }
  } catch (err) {
    if (typeof failureCallback === "function") {
      failureCallback(err);
    }
  }
};

export const providerPanelInfoCall = (clientId, successCallback, failureCallback) => async () => {
  try {
    const data = await get(PROVIDER_PANEL_DATA_URL, { clientId });

    if (typeof successCallback === "function") {
      successCallback(data);
    }
  } catch (err) {
    if (typeof failureCallback === "function") {
      failureCallback(err);
    }
  }
};

export const portalLogoUploadCall = (clientId, payload, successCallback, failureCallback) => async () => {
  try {
    const fd = new FormData();
    fd.append("logoImage", payload.logo);
    const newURL = `${PORTAL_LOGO_UPLOAD_URL}`;
    const data = await put(newURL, fd, true);

    if (typeof successCallback === "function") {
      successCallback(data);
    }
  } catch (err) {
    if (typeof failureCallback === "function") {
      failureCallback(err);
    }
  }
};

export const exportProviderCall = (body, successCallback, failureCallback) => async () => {
  try {
    const finalData = {
      ...body,
      apiType: "search",
    };
    const data = await get(`${PROVIDER_LIST_BY_SEARCH_URL}`, finalData);

    if (typeof successCallback === "function") {
      successCallback(data);
    }
  } catch (err) {
    if (typeof failureCallback === "function") {
      failureCallback(err);
    }
  }
};
