import {
  defaultBoxShadow,
  transition,
} from "assets/jss/material-dashboard-react";

const styles = (theme) => ({
  tittleColor: {
    backgroundColor: theme.palette.primary.main,
    display: "flex",
    alignItem: "center",
    justifyContent: "center",
    height: "2rem",
  },
  notestittleColor: {
    backgroundColor: theme.palette.tableColor.header,
    display: "flex",
    alignItem: "center",
    justifyContent: "center",
    height: "3rem",
    alignItems: "center",
    position: "relative",
    borderTopLeftRadius: "16px",
    borderTopRightRadius: "16px",
    [theme.breakpoints.down("sm")]: {
      height: "2rem",
    },
  },
  lableTextColor: {
    backgroundColor: theme.palette.tableColor.header,
    color: theme.palette.tableColor.cellFontColor,
    fontSize: "14px",
    fontStyle: "Helvetica, Arial,sans-serif",
    display: "flex",
    alignItems: "center",
    padding: "15px 0px",
    justifyContent: "center",
    marginBottom: "1px",
  },
  usertittleBGColor: {
    backgroundColor: "#f5f5f0",
    color: "#000052",
    display: "flex",
    alighItem: "center",
    justifyContent: "center",
    height: "2rem",
  },
  userTitleTextColor: {
    color: "#000052",
    fontSize: "18px !important",
    fontStyle: "Helvetica, Arial,sans-serif !important",
  },
  tittleColorList: {
    backgroundColor: theme.palette.tableColor.header,
    display: "flex",
    justifyContent: "space-between",
    paddingTop: ".7%",
    height: "2rem",
  },
  tittleColorCheck: {
    backgroundColor: theme.palette.primary.main,
    display: "flex",
    alighItem: "center",
    justifyContent: "space-between",
    paddingTop: ".7%",
    height: "2.5rem",
  },
  dropFileStyle: {
    minHeight: "0px",
    "@global": {
      ".MuiDropzoneArea-icon": {
        color: theme.palette.primary.main,
        width: "40px",
      },
    },
  },
  dropzoneParagraphClasses: {
    fontSize: "14px",
    color: theme.palette.primary.dark,
    "&::before": {
      content: "hello",
    },
  },
  large: {
    width: theme.spacing(7),
    height: theme.spacing(7),
  },
  tittleTextColor: {
    color: theme.palette.tableColor.cellFontColor,
    fontSize: "14px",
    fontStyle: "Helvetica, Arial,sans-serif",
  },
  buttonBoxPricing: {
    display: "flex",
    // width: "100%",
    justifyContent: "flex-end",
    alighItem: "center",
    margin: "0.5rem",
  },
  tittleTextColorList: {
    color: theme.palette.tableColor.cellFontColor,
    fontSize: "14px",
    paddingLeft: "1rem",
    fontStyle: "Helvetica, Arial,sans-serif",
  },
  tittleTextColorAtteched: {
    color: "black",
    fontSize: "14px",
    padding: "1rem",
    fontStyle: "Helvetica, Arial,sans-serif",
  },
  attechmentSection: {
    color: "black",
    backgroundColor: "#D3D3D3",
    fontSize: "10px",
    padding: "1rem",
    margin: "1rem",
    fontStyle: "Helvetica, Arial,sans-serif",
  },
  margin: {
    margin: "1%",
    marginTop: "0px",
    marginBottom: "0px",
  },

  marginCheckList: {
    margin: "2%",
    marginBottom: "0px",
    borderColor: "black",
  },
  textColor: {
    color: theme.palette.primary.dark,
    fontSize: "14px",
    fontStyle: "Helvetica, Arial,sans-serif",
  },
  customLabel: {
    // borderTop: "1px solid rgba(0,0,0,0.32)",
    // borderLeft: "1px solid rgba(0,0,0,0.32)",
    // borderRight: "1px solid rgba(0,0,0,0.32)",
    width: "100%",
    height: "35px",
    display: "flex",
    alignItems: "center",
    fontSize: "0.85rem",
    fontWeight: "700",
    paddingLeft: "12px",
  },
  girdBorderHading:{
    borderTop: "1px solid rgba(0,0,0,0.32)",
    borderLeft: "1px solid rgba(0,0,0,0.32)",
  },
  girdBorderValue:{
    borderTop: "1px solid rgba(0,0,0,0.32)",
    borderRight: "1px solid rgba(0,0,0,0.32)",
    borderLeft: "1px solid rgba(0,0,0,0.32)",
  },
  customTypographyLabel: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: "0.85rem",
    fontWeight: "700",
    
  },
  customTypographyValue: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  customBoxEWay: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    // border: "1px solid rgba(0,0,0,0.32)",
    // borderRight: "1px solid rgba(0,0,0,0.32)",
    
    padding: "12px",
  },
  fab: {
    margin: 0,
    left: "97.5%",
    top: 0,
    display: "flex",
    flexDirection: "column",
    alignContent: "center",
    justifyContent: "center",
    width: "3%",
    bottom: "0%",
    position: "fixed",
  },
  fabCheckList: {
    margin: 0,
    overflowY: "auto",
    zIndex: 1150,
    borderRadius: "1%",
    border: "outset",
    minHeight: "100%",
    maxHeight: "100vh",
    height: "fit-content",
    right: 0,
    backgroundColor: "white",
    display: "flex",
    flexDirection: "column",
    alignContent: "flex-end",
    width: "35%",
    bottom: "0%",
    position: "fixed",
    boxShadow: `rgba(0, 0, 0, 0.35) 0px 5px 15px`,
    "&::-webkit-scrollbar": {
      width: "0.4em",
    },
    "&::-webkit-scrollbar-track": {
      boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
      webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "rgba(0,0,0,.1)",
      outline: "1px solid slategrey",
    },
  },
  fabQues: {
    color: "#3c4858",
    display: "flex",
    alignItems: "center",
    fontSize: "13px !important",
  },
  fabQuesHeader: {
    fontSize: "14px !important",
  },
  checkListStatusPending: {
    backgroundColor: "#c10038",
    color: "#fff",
    padding: "3px 22px",
  },
  checkListStatusInProgress: {
    backgroundColor: "orange",
    color: "#fff",
    padding: "3px 22px",
  },
  checkListStatusComplete: {
    backgroundColor: "#0abf28",
    color: "#fff",
    padding: "3px 22px",
  },
  modalDiv: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "nowrap",
    justifyContent: "center",
    alignItems: "center",
    alignContent: "stretch",
  },
  modalDivCheckList: {
    display: "flex",
    marginRight: "1rem",
    flexDirection: "row",
    width: "100%",
    flexWrap: "nowrap",
    justifyContent: "flex-end",
    alignItems: "center",
    alignContent: "stretch",
  },
  input: {
    width: "80%",
  },
  cardWidth: {
    width: "100%",
  },
  iconBox: {
    color: theme.palette.primary.contrastText,
  },
  tncBox: {
    height: "40px",
    display: "flex",
    alighItem: "center",
    justifyContent: "center",
    borderColor: "black",
  },
  buttonBox: {
    display: "flex",
    // width: "100%",
    justifyContent: "space-between",
    alighItem: "center",
    margin: "2rem",
  },
  optionAvailableText: {
    display: "flex",
    flexDirection: "row",
    margin: "1%",
    fontSize: "20px",
    whiteSpace: "nowrap",
  },
  tpoLevelMargin: {
    marginTop: "-3%",
    marginLeft: "1%",
  },
  ownerHeader: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  dialogBoxDropDown: {
    width: "auto",
    overflow: "hidden",
    marginRight: "2%",
    marginLeft: "2%",
    fontSize: "14px",
    boxSizing: "border-box",
    fontFamily: 'Roboto", "Helvetica", "Arial", sans-serif',
    fontWeight: "200",
    lineHeight: "1.5",
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
    },
  },
  successText: {
    color: "gray",
    fontSize: "14px",
    whiteSpace: "normal",
    width: "92% !important",
  },
  statusContainer: {
    justifyContent: "space-between",
    marginBottom: "20px",
  },
  marginStatusItem: {
    marginRight: "2%",
  },
  ownerTittle: {
    marginTop: ".5rem",
  },
  feetype: {
    marginTop: "1.5rem",
    fontSize: "12px !important",
  },
  amountOwed: { backgroundColor: theme.palette.primary.main },
  tableData: {
    paddingLeft: "4%",
    paddingRight: "4%",
    paddingTop: "4%",
    paddingBottom: "2%",
    textAlign: "center",
  },
  financerLogo: { objectFit: "contain", maxHeight: "100px", maxWidth: "100px" },

  buttonStyle: {
    backgroundColor: theme.palette.primary.main,
    margin: "0.5%",
    color: theme.palette.primary.contrastText,
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
      boxShadow: `0 14px 26px -12px rgb(153 153 153 / 42%), 0 4px 23px 0px rgb(0 0 0 / 12%), 0 8px 10px -5px rgb(153 153 153 / 20%)`,
    },
    textTransform: "capitalize",
    lineHeight: "0.9",
    padding: "9px 25px",
    height: "32px",
    fontSize: "14px",
    borderRadius: "3px",
  },

  buttonStyleAlienEnd: {
    textAlign: "end",
  },
  resetButtonStyle: {
    margin: "0.5%",
    textTransform: "capitalize",
    lineHeight: "0.9",
    padding: "9px 25px",
    height: "30px",
    fontSize: "14px",
    borderRadius: "3px",
    backgroundColor: theme.palette.primary.dark,
    color: theme.palette.primary.contrastText,
    "&:hover": {
      backgroundColor: theme.palette.primary.dark,
      boxShadow: `0 14px 26px -12px rgb(153 153 153 / 42%), 0 4px 23px 0px rgb(0 0 0 / 12%), 0 8px 10px -5px rgb(153 153 153 / 20%)`,
    },
  },
  buttonStyleError: {
    backgroundColor: theme.palette.error.main,
    height: "30px",
    color: theme.palette.primary.contrastText,
    "&:hover": {
      backgroundColor: theme.palette.error.main,
    },
  },

  verticalButtonStyle: {
    wordBreak: "break-all",
    width: "1em",
    height: "auto",
    minWidth: "40px",
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
    },
  },
  kycButtonText: {
    fontSize: "13px !important",
    padding: "8px 0",
  },
  clearButton: {
    margin: "0.5%",
    textTransform: "capitalize !important",
    lineHeight: "0.9",
    padding: "9px 25px",
    height: "32px",
    fontSize: "14px",
    borderRadius: "3px",
    backgroundColor: theme.palette.primary.dark,
    color: theme.palette.primary.contrastText,
    "&:hover": {
      backgroundColor: theme.palette.primary.dark,
      boxShadow: `0 14px 26px -12px rgb(153 153 153 / 42%), 0 4px 23px 0px rgb(0 0 0 / 12%), 0 8px 10px -5px rgb(153 153 153 / 20%)`,
    },
  },
  clearButton1: {
    backgroundColor: theme.palette.primary.dark,
    color: theme.palette.primary.contrastText,
    "&:hover": {
      backgroundColor: theme.palette.primary.dark,
      boxShadow: `0 14px 26px -12px rgb(153 153 153 / 42%), 0 4px 23px 0px rgb(0 0 0 / 12%), 0 8px 10px -5px rgb(153 153 153 / 20%)`,
    },
  },
  inputBox: {
    margin: "10px",
    marginTop: "20px",
    color: theme.palette.primary.light,
  },
  statusHeadingText: {
    textAlign: "center",
    width: "90%",
    fontSize: "16px",
  },
  offerPageMargin: {
    margin: "3%",
  },
  canvasBackgourd: {
    backgroundColor: theme.palette.primary.light,
  },
  statusModalHeader: {
    display: "flex",
    height: "50px",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: theme.palette.primary.main,
    padding: "1%",
  },
  activeInputLableColor: {
    fontSize: "14px",
    fontFamily: "sans-serif",
    "&.focused": {
      color: theme.palette.primary.main,
    },
  },
  selectRow: {
    "&.MuiTableRow-root.Mui-selected": {
      background: theme.palette.info.dark,
    },
  },
  activeInputLableBestOffer: {
    width: "130%",
    fontSize: "130%",
    "&.focused": {
      color: theme.palette.primary.main,
    },
  },
  root: {
    width: "80%",
    "&.focused": {
      color: theme.palette.primary.main,
      fontSize: "14px",
      fontStyle: "Helvetica, Arial,sans-serif",
    },
  },

  rootNotes: {
    width: "94%",
    "&.focused": {
      color: theme.palette.primary.main,
      fontSize: "14px",
      fontStyle: "Helvetica, Arial,sans-serif",
    },
  },
  rootPrintedName: {
    width: "90%",
    "&.focused": {
      color: theme.palette.primary.main,
      fontSize: "14px",
      fontStyle: "Helvetica, Arial,sans-serif",
    },
  },
  rootDocumentSelect: {
    width: "240px",
    marginRight: "30px",
    "&.focused": {
      color: theme.palette.primary.main,
      fontSize: "14px",
      fontStyle: "Helvetica, Arial,sans-serif",
    },
  },
  rootSelectInput: {
    width: "80%",
    marginBottom: "10%",
    fontSize: "14px",
    fontStyle: "Helvetica, Arial,sans-serif",
  },

  resize: {
    fontSize: "14px",
    fontStyle: "Helvetica, Arial,sans-serif",
    color: theme.palette.primary.dark,
  },
  resizeAddtional: {
    fontSize: "14px",
    fontStyle: "Helvetica, Arial,sans-serif",
    color: theme.palette.primary.dark,
    padding: "10px",
  },
  resizeBeestOffer: {
    fontStyle: "Helvetica, Arial,sans-serif",
    color: theme.palette.primary.dark,
  },
  resizeDownPayment: {
    fontSize: "130%",
    fontStyle: "Helvetica, Arial,sans-serif",
    color: theme.palette.primary.dark,
  },
  checkBox: {
    padding: "0px",
  },
  cardDiv: {
    display: "flex",
    flexDirection: "column",
    flexWrap: "nowrap",
    alignItems: "center",
    marginBottom: "10%",
    marginTop: "10%",
    overflow: "auto",
    height: "70vh",
  },
  cardDivApprove: {
    display: "flex",
    flexDirection: "column",
    flexWrap: "nowrap",
    alignItems: "center",
    margin: "5% 0 2% 0",
    overflow: "auto",
  },
  cardDiv1: {
    display: "flex",
    flexDirection: "column",
    flexWrap: "nowrap",
    justifyContent: "center",
    alignItems: "center",
    marginBottom: "10%",
    marginTop: "10%",
    overflow: "auto",
    textAlign: "center",
  },
  modalCard: {
    width: "40%",
    height: "auto",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
  modalCardApprove: {
    width: "30%",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
  modalCardAttechment: {
    width: "38%",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  overLayBox: {
    position: "absolute",
    [theme.breakpoints.down("sm")]: {
      width: "91%",
    },
    [theme.breakpoints.up("sm")]: {
      width: "30%",
    },
    padding: "1%",
    zIndex: 999,
    cursor: "pointer",
    backgroundColor: theme.palette.primary.contrastText,
    boxShadow: "1px 1px 2px white, 0 0 25px #d6d0d0, 0 0 5px #737373",
  },
  modalCardList: {
    width: "100%",
    height: "auto",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  mobileModalCard: {
    width: "80%",
    height: "auto",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
  patientModal: {
    height: "auto",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    paddingLeft: "10%",
    paddingRight: "10%",
  },
  modalRoot: {
    width: "40%",
    marginTop: "10%",
    height: "auto",
    padding: "30px",
    paddingTop: "10px",
    alignItems: "center",
    justifyContent: "center",
  },
  fullInput: {
    width: "93.2%",
    "&.focused": {
      color: theme.palette.primary.main,
      fontSize: "90%",
      fontStyle: "Helvetica, Arial,sans-serif",
    },
  },
  halfInput: {
    width: "87%",
    "&.focused": {
      color: theme.palette.primary.main,
      fontSize: "90%",
      fontStyle: "Helvetica, Arial,sans-serif",
    },
  },
  halfInputSingle: {
    width: "87%",
    "&.focused": {
      color: theme.palette.primary.main,
      fontSize: "90%",
      fontStyle: "Helvetica, Arial,sans-serif",
    },
  },
  withoutOption: {
    width: "26.8%",
    "&.focused": {
      color: theme.palette.primary.main,
      fontSize: "90%",
      fontStyle: "Helvetica, Arial,sans-serif",
    },
  },
  quaterInput: {
    width: "73%",
    "&.focused": {
      color: theme.palette.primary.main,
      fontSize: "90%",
      fontStyle: "Helvetica, Arial,sans-serif",
    },
  },
  kycInput: {
    width: "95%",
    "&.focused": {
      color: theme.palette.primary.main,
      fontSize: "90%",
      fontStyle: "Helvetica, Arial,sans-serif",
    },
  },
  addIcon: {
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.main,
    "&:hover": {
      color: theme.palette.primary.contrastText,
      backgroundColor: theme.palette.primary.main,
    },
  },
  deleteIcon: {
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.error.main,
    "&:hover": {
      color: theme.palette.primary.contrastText,
      backgroundColor: theme.palette.error.main,
    },
  },
  dialogWidth: {
    width: "100%",
  },
  calender: {
    zIndex: "10002",
    position: "absolute",
    width: "auto",
    ...defaultBoxShadow,
    ...transition,
  },
  rootDialog: {
    width: "100%",
    "&.focused": {
      color: theme.palette.primary.main,
      fontSize: "90%",
      fontStyle: "Helvetica, Arial,sans-serif",
    },
  },
  patientNameColor: {
    fontSize: "100%",
    fontStyle: "Helvetica, Arial,sans-serif",
    color: theme.palette.primary.main,
  },
  widthAuto: {
    width: "auto",
  },
  widthMax: {
    width: "max-content",
  },
  uploadDocumentBox: {
    display: "flex",
    alignItems: "center",
    margin: "10px 31px",
    justifyContent: "center",
    borderRadius: 10,
    backgroundColor: "#eeeeee",
    padding: "10px",
    width: "auto",
  },
  uploadDocumentBoxRequired: {
    margin: "10px 31px",
    justifyontent: "center",
    padding: "10px",
    borderRadius: 10,
    backgroundColor: "#eeeeee",
    width: "auto",
  },
  signOnModal: {
    width: "900px",
    height: "600px",
    position: "fixed !important",
    top: "50% !important",
    left: "50% !important",
    transform: "translate(-50%,-50%)",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      height: "100%",
    },
  },
  appSvgIcons: {
    position: "relative",
    height: "40px",
    width: "40px",
    cursor: "pointer",
    color: theme.palette.primary.main,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  appSvgIconsCheck: {
    position: "absolute",
    bottom: "0px",
    right: "0px",
    color: "green",
    height: "20px",
    width: "20px",
  },

  rightAnsStyle: {
    backgroundColor: "#8af98a29",
    borderRadius: "8px",
    padding: "4px 8px",
    marginBottom: "4px",
    border: `1px solid ${theme.palette.primary.main}`,
  },
  wrongAnsStyle: {
    backgroundColor: "#ff000012",
    borderRadius: "8px",
    padding: "4px 8px",
    marginBottom: "4px",
    border: "1px solid #c10038",
  },
  NoAnsStyle: {
    backgroundColor: "lightyellow",
    borderRadius: "8px",
    padding: "4px 8px",
    marginBottom: "4px",
    border: "1px solid orange",
  },
});

export default styles;
