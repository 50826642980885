import * as types from "../Actions/ActionTypes";
import initialState from "./initailState";

const roles = (state = initialState.roles, action) => {
  switch (action.type) {
    case types.ROLE_LIST_CALL:
      return { ...state, loading: true };
    case types.ROLE_LIST_SUCCESS:
      return { ...state, loading: false, roleList: action.data };
    case types.ROLE_LIST_ERROR:
      return { ...state, error: true, loading: false };
    case types.SET_ROLL_PERMISSIONS_CALL:
      return { ...state, loading: true };
    case types.SET_ROLL_PERMISSIONS_SUCCESS:
      return { ...state, roleDetailsById: action.data, loading: false };
    case types.SET_ROLL_PERMISSIONS_ERROR:
      return { ...state, error: true, loading: false };
    case types.UPDATE_ROLL_PERMISSIONS_CALL:
      return { ...state, loading: true };
    case types.UPDATE_ROLL_PERMISSIONS_SUCCESS:
      return { ...state, updateRolePermission: action.data, loading: false };
    case types.SET_ROLE_DETAILS_DATA:
      return { ...state, roleTab: action.data, loading: false };
    case types.UPDATE_ROLL_PERMISSIONS_ERROR:
      return { ...state, error: true, loading: false };
    case types.PROFILE_TYPE_LIST_CALL:
      return { ...state, loading: true };
    case types.PROFILE_TYPE_LIST_SUCCESS:
      return { ...state, profileTypeList: action.data, loading: false };
    case types.PROFILE_TYPE_LIST_ERROR:
      return {
        ...state,
        loading: false,
        error: true
      };
    case types.ROLE_REGISTRATION_CALL:
      return { ...state, loading: true, roleList: false };
    case types.ROLE_REGISTRATION_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.data
      };
    case types.ROLE_REGISTRATION_ERROR:
      return { ...state, error: true, loading: false };
    case types.LANDING_PAGE_LIST_CALL:
      return { ...state, loading: true };
    case types.LANDING_PAGE_LIST_SUCCESS:
      return { ...state, landingpageList: action.data, loading: false };
    case types.LANDING_PAGE_LIST_ERROR:
      return {
        ...state,
        loading: false,
        error: true
      };
    case types.ROLE_LIST_BY_ID_CALL:
      return { ...state, loading: true, roleDetailsById: false };
    case types.ROLE_LIST_BY_ID_SUCCESS:
      return { ...state, loading: false, roleDetailsById: action.data };
    case types.ROLE_LIST_BY_ID_ERROR:
      return { ...state, error: true, loading: false };
    case types.ROLE_FORM_MODE:
      return { ...state, formMode: action.data };
    case types.ROLE_LIST_BY_FILTERDATA_SUCCESS:
      return {
        ...state,
        loading: false,
        roleList: action.data
      };
    case types.PROVIDER_ROLE_LIST_CALL:
      return { ...state, loading: true, providerroleList: false };
    case types.PROVIDER_ROLE_LIST_SUCCESS:
      return { ...state, loading: false, providerroleList: action.data };
    case types.PROVIDER_ROLE_LIST_ERROR:
      return { ...state, error: true, loading: false };
    case types.ADD_ROLLS_SEARCH_DATA:
      return { ...state, searchDetails: action.data };
    case types.EMPTY_SEARCH_DATA:
      return {
        ...state,
        searchDetails: {
          Name: "",
          Current_Status: "All",
          pageNo: 1,
          recordPerPage: 10,
          sortColumn: "Updated_Date",
          sortOrder: "desc"
        }
      };
    default:
      return state;
  }
};

export default roles;
