import React from "react";
import {
  Switch, Route, Redirect,
} from "react-router-dom";
import PropTypes from "prop-types";
import classNames from "classnames";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Navbar from "components/Navbars/Navbar";
import Sidebar from "components/Sidebar/Sidebar";
import { connect } from "react-redux";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import AppLoader from "components/AppLoader/Index";
import ResetPassword from "views/Login/NewLogin/ResetPassword";
import SessionOut from "views/Login/SessionOut";
import ProviderReport from "views/Reports/Provider";
import PatientRegister from "views/Patient/PatientRegister";
import PaymentCompletePage from "views/OfferDetails/PaymentCompletePage";
import InviteManager from "views/Invite/InviteManager";
import Signup from "views/UserProfile/Signup";
import jwt from "jsonwebtoken";
import logo from "../assets/img/7OHMVp.jpg";
import bgImage from "../assets/img/sidebar-2.jpg";
import styles from "../assets/jss/material-dashboard-react/layouts/adminStyle";
import NewLogin from "../views/Login/NewLogin/NewLogin";
import CloneNewLogin from "../views/Login/NewLogin/CloneNewLogin";
import {
  getPatientDetailsEmail,
  checkResetPasswordAuthCall,
  setUserIdResetPaassword,
  emptyPatientSelect,
} from "../Redux/Actions/genrateOfferPatient";
import {
  setAppBarName,
  setUserRole,
  setLayoutName,
  clientThemeCall,
  clientPortalConfig,
  getUserLeftMenuCall,
} from "../Redux/Actions/LoginActions";
import {
  getUserList,
  changeUserFormMode,
  getUserListProvider,
  setOroviderListEmpty,
  getUserDetailsById,
  setUserSearchEmpty,
} from "../Redux/Actions/UserRegistration";
import {
  getProviderList,
  getProviderDetailsById,
  changeProviderFormMode,
} from "../Redux/Actions/ProvideRegistration";
import { resetReducer } from "../Redux/Actions/appActions";
import { lastCalculationDate } from "../Redux/Actions/payoutAction";
import {
  changeRoleFormMode,
  rolePermisstionRequest,
} from "../Redux/Actions/RoleRegistration";
import { checkLayoutName } from "../Uintls/gloables";
import { logout } from "../Redux/Actions/LoginActions";
import RoutingNumber from "../modules/payout/pages/agent_payout/RoutingNumber";
import MerchantList from "../views/Reports/ManagerList";
import ApiLogs from "../views/Reports/ApiLogs";
import OldTransactionList from "../views/Reports/OldTransactionList";
import TransactionList from "../views/Reports/TransactionList";
import ErrorCodes from "../views/Reports/ErrorCodes";
import ManagerActions from "../views/Reports/ManagerActions";
import ErrorTable from "../views/Reports/ErrorTable";
import RefundHistory from "../views/Reports/RefundHistory";
import InvalidURL from "../views/Login/NewLogin/InvalidURL";

const switchRoutes = (value, rest) => (
  <>
    <Switch>
      <Route exact path="/" component={NewLogin} />
      <Route exact path="/login" component={NewLogin} />
      <Route exact path="/admin/login" component={CloneNewLogin} />
      <Route exact path="/errorCodeOpen" component={ErrorTable} />
      <Route exact path="/invite" component={InviteManager} />
      <Route
        exact
        path="/resetPassword"
        component={() => <ResetPassword state={value} prop={rest} />}
      />

      <Route
        exact
        path="/sessionOut"
        component={() => <SessionOut state={value} prop={rest} />}
      />

      <Route
        exact
        path="/invalidURL"
        component={() => <InvalidURL state={value} prop={rest} />}
      />
      <Route path="/admin/email/register" component={PatientRegister} />
      <Route
        path="/admin/OldTransactionReport"
        exact
        component={OldTransactionList}
      />
      <Route
        path={`/${rest.userDetails.layout}/Merchant`}
        component={ProviderReport}
      />
      <Route
        path={`/${rest.userDetails.layout}/MerchantSetup`}
        component={ProviderReport}
      />
      <Route path={`/${rest.userDetails.layout}/merchantDetails`} component={Signup} />
      <Route path={`/${rest.userDetails.layout}/ManagerActions`} component={ManagerActions} />
      <Route path={`/${rest.userDetails.layout}/ManagerList`} exact component={MerchantList} />
      <Route path={`/${rest.userDetails.layout}/ApiLogs`} exact component={ApiLogs} />
      <Route path={`/${rest.userDetails.layout}/ErrorCodes`} exact component={ErrorCodes} />
      <Route path={`/${rest.userDetails.layout}/TransactionReport`} exact component={TransactionList} />
      <Route exact path={`/${rest.userDetails.layout}/MakePayment`} component={RoutingNumber} />
      <Route path={`/${rest.userDetails.layout}/fee-refund-history`} exact component={RefundHistory} />
      <Route path={`/${rest.userDetails.layout}/paymentDetails`} component={PaymentCompletePage} />
    </Switch>
  </>
);

function Admin({ ...rest }) {
  if (localStorage.getItem("sessionExpired") === "true") {
    // localStorage.clear();
    rest.history.push("/sessionOut");
  }
  const useStyles = makeStyles(styles);
  const theme = useTheme();
  const windowWidth = useMediaQuery(theme.breakpoints.up("md"));
  const [preUserByIdDetails, setPreUserByIdDetails] = React.useState(
    rest.userProvider.userDetailsById
  );
  const classes = useStyles();
  const mainPanel = React.createRef();
  const [state, setState] = React.useState(false);
  const [hoverDrawer, setOpenDrawerHover] = React.useState(false);
  const [image] = React.useState(bgImage);
  const [callRolePermission, setCallRolePermission] = React.useState(false);
  // const [color] = React.useState("blue");
  const [preWindowDetails, setPreWindowDetails] = React.useState(false);
  const [openDrawer, setOpen] = React.useState(false);
  const [preProviderListData, setPreProviderListData] = React.useState(
    rest.tableData.providerList
  );

  React.useEffect(() => {
    if (preProviderListData !== rest.tableData.providerList) {
      setPreProviderListData(rest.tableData.providerList);
      if (
        rest.tableData.providerList
        && rest.tableData.providerList.Providers_Data
      ) {
        const providerListLength = rest.tableData.providerList.Providers_Data.length;
        if (providerListLength === 1) {
          const merchantId = rest.tableData.providerList.Providers_Data[0].Provider_id;
          rest.history.push(`/${rest.userDetails.layout}/Merchant/app`);
          rest.getProviderDetailsById(merchantId);
          rest.changeProviderFormMode("Edit");
        }
      }
    }
  }, []);

  React.useEffect(() => {
    if (preUserByIdDetails !== rest.userProvider.userDetailsById) {
      if (!rest.app.roles.roleTab) {
        if (
          rest.userProvider.userDetailsById
          && rest.userProvider.userDetailsById
        ) {
          const data = {
            role_id:
              rest.userProvider.userDetailsById
              && rest.userProvider.userDetailsById?.Users_Data[0]?.RoleID,
          };
          setCallRolePermission(true);
          rest.rolePermisstionRequest(data, "admin");
          setPreUserByIdDetails(rest.userProvider.userDetailsById);
        }
      }
    }
  }, [rest.userProvider, rest]);

  React.useEffect(() => {
    if (rest.roles.roleTab) {
      localStorage.setItem("rolePermission", JSON.stringify(rest.roles));
    }
  }, [rest.roles]);

  React.useEffect(() => {
    if (rest.userData !== false) {
      if (rest.userData && rest.userData.status) {
        localStorage.setItem("token", `bearer ${rest.userData.token}`);
        jwt.verify(
          rest.userData.token,
          "Support@Vaminfosyshserus",
          (err, decoded) => {
            if (decoded !== undefined) {
              localStorage.setItem("menu", JSON.stringify(decoded));
            }
          }
        );
      }
    }
  }, [rest]);

  React.useEffect(() => {
    let withLocal = true;
    if (!preWindowDetails && window.location.pathname.match("Offers/email")) {
      const splitValue = window.location.pathname.split("/");
      setPreWindowDetails(true);
      const data = {
        guid: splitValue[splitValue.length - 1],
      };
      rest.getPatientDetailsEmail(data);
      rest.history.push("/admin/email/offer");
      rest.history.push("/tsc/payment");
      rest.history.push("/payment/failure");
    } else if (window.location.pathname.match("password")) {
      withLocal = false;
      if (window.location.pathname.match("user")) {
        const splitValue = window.location.pathname.split("/");
        rest.setUserIdResetPaassword(splitValue[splitValue.length - 1]);
        rest.history.push("/admin/email/register");
      } else {
        const splitValue = window.location.pathname.split("/");
        const data = {
          emailToken: splitValue[splitValue.length - 1],
        };
        withLocal = false;
        rest.checkResetPasswordAuthCall(data);
        rest.history.push("/admin/email/register");
      }
    } else if (
      !localStorage.length
      && !window.location.pathname.match("register")
      && !window.location.pathname.match("login")
      && !window.location.pathname.match("/invite")
      && !window.location.pathname.match("errorCodeOpen")
      && !window.location.pathname.match("admin/login")
      && !window.location.pathname.match("newLogin")
      && !window.location.pathname.match("makePayment")
      && !window.location.pathname.match("paymentDetails")
      && !window.location.pathname.match("payment/verify/link")
      && !window.location.pathname.match("payment/process")
    ) {
      rest.history.push("/");
    }
    if (!rest.userRole.userList && withLocal) {
      const preData = localStorage.getItem("menu");
      if (preData) {
        rest.setUserRole(JSON.parse(preData));
        rest.setLayoutName(
          checkLayoutName(JSON.parse(preData).Role_Permission_Data.Profile_Code)
        );
        const idealPath = "";
        let LandingPage = "/";
        let MainCat = "";
        if (idealPath) {
          LandingPage = idealPath.landingPageName;
        } else {
          LandingPage = JSON.parse(preData).Role_Permission_Data.LandingPage;
          MainCat = JSON.parse(preData).Role_Permission_Data.Main_Category_LandingPage;
        }
        rest.setAppBarName(
          MainCat ? `${MainCat} / ${LandingPage}` : LandingPage
        );
        const layoutName = checkLayoutName(
          JSON.parse(preData).Role_Permission_Data.Profile_Code
        );
        rest.clientThemeCall(JSON.parse(preData).ClientID);
        rest.clientPortalConfig(JSON.parse(preData).ClientID);
        rest.getUserLeftMenuCall();
        if(!rest.userProvider.userDetailsById){
          rest.getUserDetailsById(JSON.parse(preData).UserID);
        }
        rest.setLayoutName(layoutName);
        const lastPath = localStorage.getItem("last");
        if (idealPath) {
          rest.history.push(idealPath.path);
        } else if (lastPath === "") {
          rest.history.push("/");
        } else if (lastPath === "/admin/user") {
          rest.history.push("/admin/Merchant");
        }
      }
    }
  }, [rest]);

  const routeState = () => {
    setState(!state);
  };

  if (window.performance.navigation.type === 1) {
    localStorage.setItem("last", window.location.pathname);
  }

  if (window.location.pathname === "/admin") {
    return <Redirect to="/admin/login" />;
  }

  if (
    window.location.pathname !== "/"
    && window.location.pathname !== "/sessionOut"
    && window.location.pathname !== "/invalidURL"
    && window.location.pathname !== "/Login1"
    && window.location.pathname !== "/login"
    && window.location.pathname !== "/admin/login"
    && window.location.pathname !== "/makePayment"
    && window.location.pathname !== "/paymentDetails"
    && window.location.pathname !== "/"
    && window.location.pathname !== "/admin/email/offer"
    && window.location.pathname !== "/admin/print/offer"
    && window.location.pathname !== "/tpo/payments"
    && window.location.pathname !== "/admin/email/register"
    && window.location.pathname !== "/resetPassword"
    && window.location.pathname !== "/newLogin"
    && window.location.pathname !== "/payment/verify/link"
    && window.location.pathname !== "/tsc/payment"
    && window.location.pathname !== "/payment/failure"
    && window.location.pathname !== "/payment/process"
    && window.location.pathname !== "/invite"
    && window.location.pathname !== "/errorCodeOpen"
  ) {
    if (!rest.app.app.mainAppLoading) {
      return (
        <div className={classes.wrapper}>
          {!rest.app.genrateOfferPatient.genrateOfferLoading && (
            <AppLoader
              loader={
                rest.app.genrateOfferPatient.loading
                || rest.app.provideRegistration.loading
                || rest.app.roles.loading
                || rest.app.table.loading
                || rest.app.userRole.loading
                || rest.app.users.loading
                || rest.loader
              }
            />
          )}
          {openDrawer && !windowWidth && (
            <Sidebar
              logoText="Vam Infosys"
              logo={logo}
              image={image}
              setState={() => setState(!state)}
              open={openDrawer}
              setopen={() => setOpen(false)}
              hoverDrawer={hoverDrawer}
              setOpenDrawerHover={(value) => setOpenDrawerHover(value)}
              // color={color}
              {...rest}
            />
          )}
          {windowWidth && (
            <Sidebar
              logoText="Vam Infosys"
              logo={logo}
              image={image}
              setState={() => setState(!state)}
              open={openDrawer}
              setopen={() => setOpen(false)}
              hoverDrawer={hoverDrawer}
              setOpenDrawerHover={(value) => setOpenDrawerHover(value)}
              // color={color}
              {...rest}
            />
          )}
          <div
            className={classNames({
              [classes.mainPanelHide]: !openDrawer && true,
              [classes.mainPanel]: openDrawer && true,
            })}
            ref={mainPanel}
          >
            <Navbar
              setopen={() => setOpen(!openDrawer)}
              setState={() => setState(!state)}
              openDrawer={openDrawer}
              hoverDrawer={hoverDrawer}
              setOpenDrawerHover={(value) => setOpenDrawerHover(value)}
              setCallRolePermission={() => setCallRolePermission(false)}
              // color={color}
              {...rest}
            />
            {/* On the /maps route we want the map to be on full screen - this is not possible if the content and conatiner classes are present because they have some paddings which would make the map smaller */}
            <div className={classes.content}>
              <div className={classes.container}>
                {switchRoutes(routeState, { ...rest })}
              </div>
            </div>
          </div>
        </div>
      );
    }

    return (
      <div>
        <AppLoader loader />
      </div>
    );
  }
  const value = { ...rest };
  return (
    <div>
      <AppLoader
        loader={
          rest.app.provideRegistration.loading
          || rest.app.roles.loading
          || rest.app.table.loading
          || rest.app.userRole.loading
          || rest.app.users.loading
        }
      />
      {switchRoutes(routeState, value)}
    </div>
  );
}

Admin.propTypes = {
  userDetails: PropTypes.object,
  userRole: PropTypes.object,
  setAppBarName: PropTypes.func,
  getUserList: PropTypes.func,
  changeUserFormMode: PropTypes.func,
  getUserListProvider: PropTypes.func,
  setLayoutName: PropTypes.func,
  setOroviderListEmpty: PropTypes.func,
  resetReducer: PropTypes.func,
  getPatientDetailsEmail: PropTypes.func,
  genrateOffer: PropTypes.object,
  app: PropTypes.object,
  logout: PropTypes.func,
  checkResetPasswordAuthCall: PropTypes.func,
  setUserIdResetPaassword: PropTypes.func,
  clientThemeCall: PropTypes.func,
  clientPortalConfig: PropTypes.func,
  getUserLeftMenuCall: PropTypes.func,
  getProviderList: PropTypes.func,
  tableData: PropTypes.object,
  getProviderDetailsById: PropTypes.func,
  changeProviderFormMode: PropTypes.func,
  rolePermisstionRequest: PropTypes.func,
  getUserDetailsById: PropTypes.func,
  setUserSearchEmpty: PropTypes.func,
  loader: PropTypes.bool,
  lastCalculationDate: PropTypes.func,
  roles: PropTypes.object,
};

const mapStateToProps = (app) => ({
  userRole: app.userRole,
  userProvider: app.users,
  tableData: app.provideRegistration,
  app,
  userDetails: app.app,
  loader: app.loader,
  genrateOffer: app.genrateOfferPatient,
  payout: app.payout,
  roles: app.roles,
});

export default connect(mapStateToProps, {
  setAppBarName,
  logout,
  getProviderList,
  getProviderDetailsById,
  changeProviderFormMode,
  getUserList,
  changeUserFormMode,
  changeRoleFormMode,
  setUserRole,
  getUserListProvider,
  setLayoutName,
  setOroviderListEmpty,
  resetReducer,
  getPatientDetailsEmail,
  checkResetPasswordAuthCall,
  emptyPatientSelect,
  setUserIdResetPaassword,
  clientThemeCall,
  clientPortalConfig,
  rolePermisstionRequest,
  getUserDetailsById,
  setUserSearchEmpty,
  getUserLeftMenuCall,
  lastCalculationDate,
})(Admin);
