import React from "react";
import {
  Modal,
  makeStyles,
  Backdrop,
  IconButton,
  Tooltip,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import FileCopyIcon from "@material-ui/icons/FileCopy";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  modalContent: {
    position: "relative",
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(3, 4, 3),
    outline: "none",
    border: "2px solid #000",
    borderRadius: "10px",
    width: "400px",
    height: "max-content",
  },
  closeButton: {
    position: "absolute",
    top: theme.spacing(0),
    right: theme.spacing(0),
  },
  copyButton: {
    position: "absolute",
    top: theme.spacing(0),
    right: theme.spacing(4),
  },
  backdrop: {
    backgroundColor: "rgba(0, 0, 0, 0)",
  },
}));

const TooltipModal = ({ open, onClose, content }) => {
  const classes = useStyles();

  const handleCopyToClipboard = () => {
    const modalContentText = Object.entries(content)
      .filter(([key, value]) => value)
      .map(([key, value]) => `${key} ${value}`)
      .join("\n");

    navigator.clipboard
      .writeText(modalContentText)
      .then(() => {
        console.log("Content copied to clipboard:", modalContentText);
      })
      .catch((error) => {
        console.error("Failed to copy content:", error);
      });
  };

  return (
    <Modal
      open={open}
      onClose={() => onClose}
      className={classes.modal}
      BackdropComponent={Backdrop}
      BackdropProps={{
        className: classes.backdrop,
      }}
    >
      <div className={classes.modalContent}>
        <Tooltip title="Copy to Clipboard">
          <IconButton
            className={classes.copyButton}
            onClick={handleCopyToClipboard}
          >
            <FileCopyIcon />
          </IconButton>
        </Tooltip>
        <IconButton className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
        {content["Txn ID:"] && (
          <div>
            <strong>Txn ID:</strong> {content["Txn ID:"]}
          </div>
        )}
        {content["Amount:"] && (
          <div>
            <strong>Amount:</strong> {content["Amount:"]}
          </div>
        )}
        {content["Fee $:"] && (
          <div>
            <strong>Fee $:</strong> {content["Fee $:"]}
          </div>
        )}
        {content["Fee Txn ID:"] && (
          <div>
            <strong>Fee Txn ID:</strong> {content["Fee Txn ID:"]}
          </div>
        )}
        {content["Fee Txn Status:"] && (
          <div>
            <strong>Fee Txn Status:</strong> {content["Fee Txn Status:"]}
          </div>
        )}
        {content["Refund $:"] && (
          <div>
            <strong>Refund $:</strong> {content["Refund $:"]}
          </div>
        )}
        {content["Refund Txn ID:"] && (
          <div>
            <strong>Refund Txn ID:</strong> {content["Refund Txn ID:"]}
          </div>
        )}
        {content["Refund Status:"] && (
          <div>
            <strong>Refund Status:</strong> {content["Refund Status:"]}
          </div>
        )}
        {content["Refund Reason Code:"] && (
          <div>
            <strong>Refund Reason Code:</strong>{" "}
            {content["Refund Reason Code:"]}
          </div>
        )}
      </div>
    </Modal>
  );
};

export default TooltipModal;
