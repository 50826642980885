import * as types from "../Actions/ActionTypes";
import initialState from "./initailState";

const onBoarding = (state = initialState.onBoarding, action) => {
  switch (action.type) {
  case types.ONBORDING_PROVIDER_CALL:
    return { ...state, loading: true };
  case types.ONBORDING_PROVIDER_SUCCESS:
    return { ...state, data: action.data, loading: false };
  case types.ONBORDING_PROVIDER_ERROR:
    return { ...state, error: true, loading: false };

  case types.CREATE_PRICING_TEMPLETE:
    return { ...state, loading: true };
  case types.CREATE_PRICING_TEMPLETE_SUCCESS:
    return { ...state, data: action.data, loading: false };
  case types.CREATE_PRICING_TEMPLETE_ERROR:
    return { ...state, error: true, loading: false };

  case types.UPDATE_PRICING_TEMPLETE:
    return { ...state, loading: true };
  case types.UPDATE_PRICING_TEMPLETE_SUCCESS:
    return { ...state, data: action.data, loading: false };
  case types.UPDATE_PRICING_TEMPLETE_ERROR:
    return { ...state, error: true, loading: false };
  default:
    return state;
  }
};

export default onBoarding;
