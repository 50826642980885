import React from "react";
import PropTypes from "prop-types";
import { Box, Grid, TextField } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import parse from "autosuggest-highlight/parse";
import match from "autosuggest-highlight/match";
import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/material-dashboard-react/components/businessForm";
import { getEmailData } from "Redux/Actions/genrateOfferPatient";
import { useDispatch } from "react-redux";

const useStyles = makeStyles(styles);

const EmailAutofill = ({
  email,
  onEmailChange,
  agentsList,
  setSelectedUser,
  allowManualInput,
  emailError,
  values,
}) => {
  const menu = JSON.parse(localStorage.getItem("menu"));
  const profileCode = menu?.Role_Permission_Data?.Profile_Code;
  const classes = useStyles();
  const dispatch = useDispatch();

  const handleInputChange = (event) => {
    const { value } = event.target;
    onEmailChange(value.toLowerCase());
    setSelectedUser(null);
  };

  return (
    <Box className={classes.root}>
      <Grid container>
        <Grid item xs={12}>
          <Autocomplete
            disabled={
              profileCode === "Mg" || (values.Status_Code === 1 && values.Status_Code !== 3) ? true : false
            }
            name="agentId"
            id="agentId"
            style={{ fontSize: "14px" }}
            value={email}
            autoHighlight
            fullWidth
            onInputChange={(e, newInputValue) => {
              onEmailChange(newInputValue.toLowerCase());
              setSelectedUser(null);
            }}
            onChange={(_, value) => {
              setSelectedUser(value);
              if (value) {
                dispatch(getEmailData(value));
              } else {
                onEmailChange("");
                setSelectedUser(null);
              }
            }}
            options={agentsList}
            getOptionLabel={(option) => option}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Email"
                value={email || ""}
                onChange={handleInputChange}
                required
                error={emailError}
                helperText={emailError ? "Invalid email" : ""}
                disabled={profileCode === "Mg"}
              />
            )}
            renderOption={(option, { inputValue }) => {
              const matches = match(option, inputValue);
              const parts = parse(option, matches);
              return (
                <div>
                  {parts.map((part, index) => (
                    <span
                      key={index}
                      style={{
                        backgroundColor: part.highlight ? "#f3ff0070" : "",
                        fontWeight: part.highlight ? 400 : "",
                        fontSize: "14px",
                      }}
                    >
                      {part.text}
                    </span>
                  ))}
                </div>
              );
            }}
            freeSolo={allowManualInput} // Allow manual input if specified
          />
        </Grid>
      </Grid>
    </Box>
  );
};

EmailAutofill.propTypes = {
  email: PropTypes.string.isRequired,
  onEmailChange: PropTypes.func.isRequired,
  agentsList: PropTypes.array.isRequired,
  selectedUser: PropTypes.any,
  setSelectedUser: PropTypes.func.isRequired,
  allowManualInput: PropTypes.bool,
  getEmailData: PropTypes.func,
  emailError: PropTypes.bool,
  setEmailError: PropTypes.func.isRequired,
  values: PropTypes.object.isRequired,
};

export default EmailAutofill;
