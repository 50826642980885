import React from "react";
import ReactDOM from "react-dom";

import App from "./App";
// the main file

const render = () => {
  ReactDOM.render(<App />, document.getElementById("root"));
};

render();

module.hot.accept("./", render);
