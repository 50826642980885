import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import moment from "moment";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Grid from "@material-ui/core/Grid";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import isEmpty from "lodash/isEmpty";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableFooter from "@material-ui/core/TableFooter";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import IconButton from "@material-ui/core/IconButton";
import FirstPageIcon from "@material-ui/icons/FirstPage";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import { Typography } from "@material-ui/core";
import Popper from "@material-ui/core/Popper";
import Grow from "@material-ui/core/Grow";
import MenuList from "@material-ui/core/MenuList";
// import { exportToPDF } from "modules/reports/utils/common";
import { withStyles } from "@material-ui/styles";
import Tooltip from "@material-ui/core/Tooltip";
import { format, subDays } from "date-fns";
import InfoIcon from "@material-ui/icons/Info";
import { useDispatch } from "react-redux";
import { exportToCSVProvider } from "../../Utils/commonUtils";
import noDataImage from "../../assets/img/noDataImage.png";
import SearchTxn from "../../components/DialogBox/SearchTxn";
import TooltipModal from "../../components/Modal/TooltipModal";
import tableStyle from "../../assets/jss/material-dashboard-react/components/reportTables";
import { actionPermission, permissionCheck } from "../../Utils/commonUtils";
import { ReactComponent as ExportIcon } from "../../assets/svgIcons/ExportIcon.svg";
import {
  getTransactionListData,
  emptyReduxState,
  getTransactionListDataExport,
  setFormData,
  clearFormData,
  setTransactionListPage,
  setTransactionListRowsPerPage,
} from "../../Redux/Actions/genrateOfferPatient";
import SearchFields from "../../components/SearchComponent/SearchFields";
import SearchChips from "../../components/SearchComponent/SearchChips";
import SyncIcon from "@material-ui/icons/Sync";

const IconsTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: "#000",
    fontSize: "12px",
  },
  tooltipPlacementTop: {
    margin: "4px 0",
  },
}))(Tooltip);

const useStyles = makeStyles(tableStyle);
function createData(
  senderTitle,
  managerName,
  recipientTitle,
  transactionId,
  amount,
  timestamp,
  status
) {
  return {
    senderTitle,
    managerName,
    recipientTitle,
    transactionId,
    amount,
    timestamp,
    status,
  };
}

let rows = [];

function TablePaginationActions(props) {
  const classes = useStyles();
  const theme = useTheme();

  const {
    count, page, rowsPerPage, onChangePage,
  } = props;

  const handleFirstPageButtonClick = (event) => {
    onChangePage(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onChangePage(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onChangePage(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <div className={classes.rootPagination}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        variant="body2"
        aria-label="first page"
      >
        {theme.direction === "rtl" ? (
          <Typography>Last</Typography>
        ) : (
          <Typography variant="body2">First</Typography>
        )}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <Typography>Previous</Typography>
        ) : (
          <Typography variant="body2">Previous</Typography>
        )}
      </IconButton>
      <IconButton
        onClick={handleFirstPageButtonClick}
        aria-label="first page"
        className={classes.bottonPage}
      >
        <Typography variant="body2">{page ? page + 1 : 1}</Typography>
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <Typography variant="body2">Next</Typography>
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? (
          <FirstPageIcon />
        ) : (
          <Typography variant="body2">Last</Typography>
        )}
      </IconButton>
    </div>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onChangePage: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

function EnhancedTableHead(props) {
  const {
    classes, order, orderBy, onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  const headCells = [
    {
      id: "senderTitle",
      numeric: false,
      disablePadding: true,
      label: "Sender",
      headClassName: classes.DBA_Name,
    },
    {
      id: "recipientTitle",
      numeric: false,
      disablePadding: false,
      label: "Receiver",
      headClassName: classes.Assign_To,
    },
    {
      id: "transactionId",
      numeric: true,
      disablePadding: false,
      label: "Txn ID",
      headClassName: classes.email,
    },
    {
      id: "amount",
      numeric: true,
      disablePadding: false,
      label: "Amount ($)",
      headClassName: classes.Phone,
    },
    {
      id: "timestamp",
      numeric: true,
      disablePadding: false,
      label: "Date & Time (EDT)",
      headClassName: classes.Phone,
    },
    {
      id: "status",
      numeric: false,
      disablePadding: false,
      label: "Status",
      headClassName: classes.status,
    },
    {
      id: "info",
      numeric: false,
      disablePadding: false,
      label: "",
      headClassName: classes.status,
    },
  ];

  return (
    <TableHead className={classes.tableHeader}>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            className={classes.cellTextHeaderOffer}
            align="center"
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
};

const TransactionList = (props) => {
  const classes = useStyles();
  const [page, setPage] = React.useState(
    props.transactionListData?.transactionListData?.page
  );
  const [order, setOrder] = React.useState("desc");
  const [orderBy, setOrderBy] = React.useState("timestamp");
  const [selected, setSelected] = React.useState([]);
  const [chipValue, setChipValue] = React.useState([]);
  const [deleteChp, setDeleteChip] = React.useState();
  const theme = useTheme();
  const windowWidth = useMediaQuery(theme.breakpoints.up("md"));
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [openImportFileModal, setImportFileModalOpen] = React.useState(false);
  const anchorRef = React.useRef(null);
  const [searchBar, setSearchBar] = React.useState(false);
  const [id, setId] = React.useState(null);
  const [handleBackPath, setHandleBack] = React.useState(false);
  const profileId = localStorage.getItem("profileId");
  const menu = JSON.parse(localStorage.getItem("menu"));
  const txnList = localStorage.getItem("TransactionReport");
  const searchData = localStorage.getItem("searchData");
  const newSearchData = JSON.parse(searchData);
  const [tooltipContent, setTooltipContent] = React.useState([]);

  const [modalOpen, setModalOpen] = React.useState(false);
  const [modalContent, setModalContent] = React.useState([]);
  const dispatch = useDispatch();

  React.useEffect(() => {
    const chips = [];
    if (handleBackPath === true && newSearchData?.searchSenderName) {
      chips.push(`Sender: ${newSearchData?.searchSenderName}`);
    }
    if (handleBackPath === true && newSearchData?.searchTxnID) {
      chips.push(`Txn ID: ${newSearchData?.searchTxnID}`);
    }
    if (
      handleBackPath === true
      && newSearchData?.searchStartAmount
      && !newSearchData?.searchEndAmount
    ) {
      if (newSearchData?.amountRange === true) {
        chips.push(
          `Amount From: $${newSearchData?.searchStartAmount} - To End`
        );
      } else {
        chips.push(`Amount: $${newSearchData?.searchStartAmount}`);
      }
    }
    if (
      handleBackPath === true
      && !newSearchData?.searchStartAmount
      && newSearchData?.searchEndAmount
    ) {
      if (newSearchData?.amountRange === true) {
        chips.push(
          `Amount From: Start - To $${newSearchData?.searchEndAmount}`
        );
      } else {
        chips.push(`Amount: $${newSearchData?.searchStartAmount}`);
      }
    }
    if (
      handleBackPath === true
      && newSearchData?.searchStartAmount
      && newSearchData?.searchEndAmount
    ) {
      chips.push(
        `Amount From: $${newSearchData?.searchStartAmount} - To $${newSearchData?.searchEndAmount}`
      );
    }
    if (
      handleBackPath === true
      && newSearchData?.searchStartDate
      && !newSearchData?.searchEndDate
    ) {
      const formattedStartDate = format(
        new Date(newSearchData?.searchStartDate),
        "MM/dd/yy"
      );
      if (newSearchData?.dateRange === true) {
        chips.push(`Date From: ${formattedStartDate} - To End`);
      } else {
        chips.push(`Date: ${formattedStartDate}`);
      }
    }
    if (
      handleBackPath === true
      && !newSearchData?.searchStartDate
      && newSearchData?.searchEndDate
    ) {
      const formattedEndDate = format(
        new Date(newSearchData?.searchEndDate),
        "MM/dd/yy"
      );
      if (newSearchData?.dateRange === true) {
        chips.push(`Date From: Start - To ${formattedEndDate}`);
      } else {
        chips.push(`Date: ${formattedEndDate}`);
      }
    }
    if (
      handleBackPath === true
      && newSearchData?.searchStartDate
      && newSearchData?.searchEndDate
    ) {
      const formattedStartDate = format(
        new Date(newSearchData?.searchStartDate),
        "MM/dd/yy"
      );
      const formattedEndDate = format(
        new Date(newSearchData?.searchEndDate),
        "MM/dd/yy"
      );
      chips.push(`Date From: ${formattedStartDate} - To ${formattedEndDate}`);
    }
    if (handleBackPath === true && newSearchData?.searchStatus) {
      chips.push(`Status: ${newSearchData?.searchStatus}`);
    }
    if (handleBackPath === true) {
      setChipValue(chips);
    }
  }, [props]);

  const handleOpenModal = (content) => {
    const dataObj = {
      "Txn ID:": content.transactionId,
      "Amount:": content.amount,
      "Fee $:": parseFloat(content.fee).toFixed(2),
      "Fee Txn ID:": content.feeTransactionId,
      "Fee Txn Status:": content.feeStatus,
      "Refund $:": content.refundAmount,
      "Refund Txn ID:": content.refundTransactionId,
      "Refund Status:": content.refundStatus,
      "Refund Reason Code:": content.refundReasonCode,
    };
    setModalContent(dataObj);
    setModalOpen(true);
  };
  React.useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const idFromUrl = queryParams.get("ID");
    if (idFromUrl) {
      setHandleBack(true);
    }
  }, []);

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const handleMouseEnter = (amount) => {
    setTooltipContent(amount);
  };

  const handleMouseLeave = () => {
    setTooltipContent([]);
  };


  const handleToggle = () => {
    setImportFileModalOpen((prevOpen) => !prevOpen);
  };

  const handleCloseModel = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setImportFileModalOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      setImportFileModalOpen(false);
    }
  }

  React.useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const idFromUrl = queryParams.get("id");

    setId(idFromUrl);
  }, []);

  React.useEffect(() => {
    const chip = [];
    let today, sevenDaysAgo, startDate, endDate;
    if(props?.transactionListData?.dateRange === false) {
      localStorage.removeItem("isDateRangeChecked");
    }
    if (!handleBackPath) {
      today = new Date();
      sevenDaysAgo = subDays(today, 7);
      startDate = format(sevenDaysAgo, "yyyy-MM-dd");
      endDate = format(today, "yyyy-MM-dd");
      const formattedStartDate = format(
        new Date(startDate),
        "MM/dd/yy"
      );
      const formattedEndDate = format(
        new Date(endDate),
        "MM/dd/yy"
      );
      chip.push(`Date From: ${formattedStartDate} - To ${formattedEndDate}`);
      if(!handleBackPath){localStorage.setItem("isDateRangeChecked", true);}
      const updatedFormData = {
        searchSenderName: "",
        searchTxnID: "",
        searchStartDate: startDate,
        searchEndDate: endDate,
        searchStartAmount: "",
        searchEndAmount: "",
        dateRange: true,
        amountRange: false,
        searchStatus: null,
      };
      setChipValue(chip);
      props.setFormData(updatedFormData);
    }
    if (handleBackPath) {
      props.setFormData(newSearchData);
      setHandleBack("false");
    }
    const payload = {
      Profile_id: profileId,
      pageNo: props.transactionListData?.transactionListPagination?.currentPage || page,
      recordPerPage: props.transactionListData?.transactionListPagination?.rowsPerPage || 5,
      sortColumn: orderBy,
      sortOrder: order,
      searchSenderName: newSearchData?.searchSenderName || "",
      searchTxnID: newSearchData?.searchTxnID || "",
      searchStartAmount: newSearchData?.searchStartAmount || "",
      searchEndAmount: newSearchData?.searchEndAmount || "",
      searchStartDate: handleBackPath ? newSearchData?.searchStartDate || "" : startDate,
      searchEndDate: handleBackPath ? newSearchData?.searchEndDate || "" : endDate,
      searchStatus: newSearchData?.searchStatus || "",
      dateRange: handleBackPath ? newSearchData?.dateRange || false : true,
      amountRange: newSearchData?.amountRange || false,
    };

    props.getTransactionListData(payload);
  }, [id, handleBackPath]);

  React.useEffect(() => {
    if (txnList) {
      if(props?.transactionListData?.dateRange === false) {
        localStorage.removeItem("isDateRangeChecked");
      }
      localStorage.removeItem("searchData");
      dispatch(setTransactionListPage(1));
      dispatch(setTransactionListRowsPerPage(5));
      setPage(1);
      setRowsPerPage(5);
      const today = new Date();
      const sevenDaysAgo = subDays(today, 7);
      const startDate = format(sevenDaysAgo, "yyyy-MM-dd");
      const endDate = format(today, "yyyy-MM-dd");
      const chips = [];
      const formattedStartDate = format(
        new Date(startDate),
        "MM/dd/yy"
      );
      const formattedEndDate = format(
        new Date(endDate),
        "MM/dd/yy"
      );
      localStorage.setItem("isDateRangeChecked", true);
      chips.push(`Date From: ${formattedStartDate} - To ${formattedEndDate}`);
      setChipValue(chips);
      const updatedFormData = {
        searchSenderName: "",
        searchTxnID: "",
        searchStartDate: startDate,
        searchEndDate: endDate,
        searchStartAmount: "",
        searchEndAmount: "",
        dateRange: true,
        amountRange: false,
        searchStatus: null,
      };
      props.setFormData(updatedFormData);
      props.clearFormData();
      const payload = {
        Profile_id: profileId,
        pageNo: 1,
        recordPerPage: 5,
        sortColumn: orderBy,
        sortOrder: order,
        searchSenderName: "",
        searchTxnID: "",
        searchStartAmount: "",
        searchEndAmount: "",
        searchStartDate: startDate,
        searchEndDate: endDate,
        searchStatus: "",
        dateRange: true,
        amountRange: false,
      };
      props.getTransactionListData(payload);
      localStorage.removeItem("TransactionReport");
    }
  }, [txnList]);

  React.useEffect(() => {
    if (props?.transactionListData?.transactionListData?.page !== page) {
      setPage(props?.transactionListData?.transactionListData?.page);
      dispatch(
        setTransactionListPage(
          props?.transactionListData?.transactionListData?.page
        )
      );
    }
    if (
      props?.transactionListData?.transactionListData?.limit !== rowsPerPage
    ) {
      setRowsPerPage(props?.transactionListData?.transactionListData?.limit);
      dispatch(
        setTransactionListRowsPerPage(
          props?.transactionListData?.transactionListData?.limit
        )
      );
    }
  }, [props]);

  React.useEffect(() => () => {
    const updatedFormData = {
      searchSenderName: "",
      searchTxnID: "",
      searchStartDate: "",
      searchEndDate: "",
      searchStartAmount: "",
      searchEndAmount: "",
      dateRange: false,
      amountRange: false,
      searchStatus: null,
    };
    if (localStorage.getItem("isAmountRangeChecked")) {
      localStorage.removeItem("isAmountRangeChecked");
      updatedFormData["amountRange"] = false;
    }
    if (localStorage.getItem("isDateRangeChecked")) {
      localStorage.removeItem("isDateRangeChecked");
      updatedFormData["dateRange"] = false;
    }
    props.setFormData(updatedFormData);
    props.clearFormData();
  }, []);

  const handleChangePage = (event, newPage) => {
    dispatch(setTransactionListPage(newPage));
    setPage(newPage);
    const profileCode = menu?.Role_Permission_Data?.Profile_Code;
    if (profileCode === "Ag") {
      const requestData = {
        Profile_Code: menu?.Role_Permission_Data?.Profile_Code,
        recordPerPage: rowsPerPage,
        sortColumn: orderBy,
        sortOrder: order,
        pageNo: newPage + 1,
        searchSenderName: props?.transactionListData?.searchSenderName || "",
        searchTxnID: props?.transactionListData?.searchTxnID || "",
        searchStartAmount: props?.transactionListData?.searchStartAmount || "",
        searchEndAmount: props?.transactionListData?.searchEndAmount || "",
        searchStartDate: props?.transactionListData?.searchStartDate || "",
        searchEndDate: props?.transactionListData?.searchEndDate || "",
        searchStatus: props?.transactionListData?.searchStatus || "",
        dateRange: props?.transactionListData?.dateRange || "",
        amountRange: props?.transactionListData?.amountRange || "",
      };
      props.getTransactionListData(requestData);
    } else {
      const requestData = {
        Profile_id: profileId,
        recordPerPage: rowsPerPage,
        sortColumn: orderBy,
        sortOrder: order,
        pageNo: newPage + 1,
        searchSenderName: props?.transactionListData?.searchSenderName || "",
        searchTxnID: props?.transactionListData?.searchTxnID || "",
        searchStartAmount: props?.transactionListData?.searchStartAmount || "",
        searchEndAmount: props?.transactionListData?.searchEndAmount || "",
        searchStartDate: props?.transactionListData?.searchStartDate || "",
        searchEndDate: props?.transactionListData?.searchEndDate || "",
        searchStatus: props?.transactionListData?.searchStatus || "",
        dateRange: props?.transactionListData?.dateRange || "",
        amountRange: props?.transactionListData?.amountRange || "",
      };
      props.getTransactionListData(requestData);
    }
  };

  window.addEventListener('beforeunload', (event) => {
    if (event.currentTarget.performance.navigation.type === 1) {
      localStorage.removeItem('searchData');
    }
  });

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleChangeRowsPerPage = (event) => {
    const newRowsPerPage = parseInt(event.target.value, 10);
    dispatch(setTransactionListRowsPerPage(newRowsPerPage));
    setRowsPerPage(parseInt(event.target.value, 10));
    if (newRowsPerPage >= props?.merchantListData?.merchantListData?.total) {
      dispatch(setTransactionListPage(0));
    }
    const profileCode = menu?.Role_Permission_Data?.Profile_Code;
    if (profileCode === "Mg") {
      const requestData = {
        Profile_id: profileId,
        recordPerPage: parseInt(event.target.value, 10),
        sortColumn: orderBy,
        sortOrder: order,
        pageNo: 1,
        searchSenderName: props?.transactionListData?.searchSenderName || "",
        searchTxnID: props?.transactionListData?.searchTxnID || "",
        searchStartAmount: props?.transactionListData?.searchStartAmount || "",
        searchEndAmount: props?.transactionListData?.searchEndAmount || "",
        searchStartDate: props?.transactionListData?.searchStartDate || "",
        searchEndDate: props?.transactionListData?.searchEndDate || "",
        searchStatus: props?.transactionListData?.searchStatus || "",
        dateRange: props?.transactionListData?.dateRange || "",
        amountRange: props?.transactionListData?.amountRange || "",
      };
      props.getTransactionListData({ ...requestData });
    } else {
      const requestData = {
        Profile_Code: menu?.Role_Permission_Data?.Profile_Code,
        recordPerPage: parseInt(event.target.value, 10),
        sortColumn: orderBy,
        sortOrder: order,
        pageNo: 1,
        searchSenderName: props?.transactionListData?.searchSenderName || "",
        searchTxnID: props?.transactionListData?.searchTxnID || "",
        searchStartAmount: props?.transactionListData?.searchStartAmount || "",
        searchEndAmount: props?.transactionListData?.searchEndAmount || "",
        searchStartDate: props?.transactionListData?.searchStartDate || "",
        searchEndDate: props?.transactionListData?.searchEndDate || "",
        searchStatus: props?.transactionListData?.searchStatus || "",
        dateRange: props?.transactionListData?.dateRange || "",
        amountRange: props?.transactionListData?.amountRange || "",
      };
      props.getTransactionListData(requestData);
    }
  };

  const parseAndFormatDate = (dateString) => {
    const parts = dateString.split("/");
    let year = parts[2];
    if (year.length === 2) {
      const currentYear = new Date().getFullYear().toString();
      const currentCentury = currentYear.slice(0, 2);
      year = `${currentCentury}${year}`;
    }
    const month = parts[1].padStart(2, "0");
    const day = parts[0].padStart(2, "0");
    return `${year}-${day}-${month}`;
  };
  const handleDeleteChip = (chipToDelete) => {
    setDeleteChip(chipToDelete);
    const updatedChips = chipValue.filter((chip) => chip !== chipToDelete);
    setChipValue(updatedChips);
    const profileCode = menu?.Role_Permission_Data?.Profile_Code;
    let payload;
    const updatedFormData = {
      searchSenderName: "",
      searchTxnID: "",
      searchStatus: "",
      searchStartAmount: "",
      searchEndAmount: "",
      searchStartDate: "",
      searchEndDate: "",
      dateRange: false,
      amountRange: false,
    };

    if (updatedChips.length > 0) {
      updatedChips.forEach((chip) => {
        const [key, value] = chip.split(":").map((item) => item.trim());
        switch (key) {
        case "Sender":
          updatedFormData["searchSenderName"] = value;
          break;
        case "Txn ID":
          updatedFormData["searchTxnID"] = value;
          break;
        case "Status":
          updatedFormData["searchStatus"] = value;
          break;
        case "Amount":
          updatedFormData["searchStartAmount"] = value.replace(/\$/g, "");
          updatedFormData["amountRange"] = false;
          break;
        case "Amount From":
          const samount = value === "Start"
            ? value
            : value.split(" - To ")[0].trim().replace(/\$/g, "");
          const eamount = value === "End"
            ? value
            : value.split(" - To ")[1].trim().replace(/\$/g, "");
          updatedFormData["amountRange"] = true;
          updatedFormData["searchStartAmount"] = samount === "Start" ? "" : samount;
          updatedFormData["searchEndAmount"] = eamount === "End" ? "" : eamount;
          break;
        case "Date":
          const formattedDate = parseAndFormatDate(value);
          updatedFormData["searchStartDate"] = formattedDate;
          updatedFormData["dateRange"] = false;
          break;
        case "Date From":
          const sdate = value === "Start" ? value : value.split(" - To ")[0].trim();
          const edate = value === "End" ? value : value.split(" - To ")[1].trim();
          const formattedStartDate = sdate === "Start" ? "" : parseAndFormatDate(sdate);
          const formattedEndDate = edate === "End" ? "" : parseAndFormatDate(edate);
          updatedFormData["dateRange"] = true;
          updatedFormData["searchStartDate"] = formattedStartDate;
          updatedFormData["searchEndDate"] = formattedEndDate;
          break;
        default:
          break;
        }
      });
    }
    if (
      updatedFormData["searchEndAmount"] === ""
      && updatedFormData["searchStartAmount"] === ""
    )
      localStorage.removeItem("isAmountRangeChecked");
    if (
      updatedFormData["searchEndDate"] === ""
      && updatedFormData["searchStartDate"] === ""
    )
      localStorage.removeItem("isDateRangeChecked");
    props.clearFormData();
    props.setFormData(updatedFormData);

    if (profileCode === "Mg") {
      payload = {
        Profile_id: profileId,
        pageNo: page,
        recordPerPage: updatedChips.length > 0 ? rowsPerPage : 5,
        sortColumn: orderBy,
        sortOrder: order,
        ...updatedFormData,
      };
    } else {
      payload = {
        Profile_Code: profileCode,
        pageNo: page,
        recordPerPage: updatedChips.length > 0 ? rowsPerPage : 5,
        sortColumn: orderBy,
        sortOrder: order,
        ...updatedFormData,
      };
    }
    
    props.getTransactionListData(payload);

    props.clearFormData();
  };


  const displayAndDeleteChips = (searchData) => {
    setDeleteChip();
    const chips = [];
    if (searchData?.searchSenderName) {
      chips.push(`Sender: ${searchData?.searchSenderName}`);
    }

    if (searchData?.searchTxnID) {
      chips.push(`Txn ID: ${searchData?.searchTxnID}`);
    }
    if (searchData?.searchStartAmount && !searchData?.searchEndAmount) {
      if (searchData?.amountRange === true) {
        chips.push(`Amount From: $${searchData?.searchStartAmount} - To End`);
      } else {
        chips.push(`Amount: $${searchData?.searchStartAmount}`);
      }
    }
    if (!searchData?.searchStartAmount && searchData?.searchEndAmount) {
      if (searchData?.amountRange === true) {
        chips.push(`Amount From: Start - To $${searchData?.searchEndAmount}`);
      } else {
        chips.push(`Amount: $${searchData?.searchStartAmount}`);
      }
    }
    if (searchData?.searchStartAmount && searchData?.searchEndAmount) {
      chips.push(
        `Amount From: $${searchData?.searchStartAmount} - To $${searchData?.searchEndAmount}`
      );
    }
    if (searchData?.searchStartDate && !searchData?.searchEndDate) {
      const formattedStartDate = format(
        new Date(searchData?.searchStartDate),
        "MM/dd/yy"
      );
      if (searchData?.dateRange === true) {
        chips.push(`Date From: ${formattedStartDate} - To End`);
      } else {
        chips.push(`Date: ${formattedStartDate}`);
      }
    }
    if (!searchData?.searchStartDate && searchData?.searchEndDate) {
      const formattedEndDate = format(
        new Date(searchData?.searchEndDate),
        "MM/dd/yy"
      );
      if (searchData?.dateRange === true) {
        chips.push(`Date From: Start - To ${formattedEndDate}`);
      } else {
        chips.push(`Date: ${formattedEndDate}`);
      }
    }
    if (searchData?.searchStartDate && searchData?.searchEndDate) {
      const formattedStartDate = format(
        new Date(searchData?.searchStartDate),
        "MM/dd/yy"
      );
      const formattedEndDate = format(
        new Date(searchData?.searchEndDate),
        "MM/dd/yy"
      );
      chips.push(`Date From: ${formattedStartDate} - To ${formattedEndDate}`);
    }
    if (searchData?.searchStatus) {
      chips.push(`Status: ${searchData?.searchStatus}`);
    }

    setChipValue(chips);
  };

  rows = [];

  const result = props.transactionListData.transactionListData?.data?.map(
    (items) => ({
      senderTitle: items?.senderTitle,
      managerName: items?.managerName,
      transactionId: items?.transactionId,
      recipientTitle: items?.recipientTitle,
      fee: items?.fee,
      feeTransactionId: items?.feeTransactionId,
      feeStatus: items?.feeStatus,
      feeDate: items?.feeDate,
      refundAmount: items?.refundAmount,
      refundCompleted: items?.refundCompleted,
      refundTransactionId: items?.refundTransactionId,
      refundReasonCode: items?.refundReasonCode,
      refundDate: items?.refundReasonCode,
      error: items?.error,
      receiverFirstName: items?.receiverFirstName,
      receiverLastName: items?.receiverLastName,
      Phone_Number: items?.Phone_Number,
      refundStatus: items.refundStatus,
      amount: items.amount,
      timestamp: items.timestamp,
      refundTimestamp: items.refundTimestamp,
      status: items.status,
    })
  );

  result
    && result.forEach((v, i) => {
      const senderTitle = v.senderTitle;
      const managerName = v.managerName;
      const recipientTitle = v.recipientTitle;
      const transactionId = v.transactionId;
      const amount = v.amount;
      const fee = v.fee;
      const feeTransactionId = v.feeTransactionId;
      const feeStatus = v.feeStatus;
      const refundAmount = v.refundAmount;
      const refundStatus = v.refundStatus;
      const refundTransactionId = v.refundTransactionId;
      const refundReasonCode = v.refundReasonCode;
      const timestamp = v.timestamp
        ? moment(v.timestamp).tz('America/New_York').format("MM/DD/YYYY hh:mm A")
        : "";
      const status = v.status;
      const amountObj = {
        amount: amount,
        fee: fee,
        feeTransactionId: feeTransactionId,
        feeStatus: feeStatus,
        refundAmount: refundAmount,
        refundStatus: refundStatus,
        refundTransactionId: refundTransactionId,
        refundReasonCode: refundReasonCode,
        transactionId: transactionId,
      };
      rows.push(
        createData(
          senderTitle,
          managerName,
          recipientTitle,
          transactionId,
          amountObj,
          timestamp,
          status
        )
      );
    });

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    const newOrder = isAsc ? "desc" : "asc";
    setOrder(newOrder);
    setOrderBy(property);
    const profileCode = menu?.Role_Permission_Data?.Profile_Code;
    if (profileCode === "Mg") {
      const payload = {
        Profile_id: profileId,
        pageNo: page,
        recordPerPage: rowsPerPage,
        sortColumn: property,
        sortOrder: newOrder,
        searchSenderName: props?.transactionListData?.searchSenderName || "",
        searchTxnID: props?.transactionListData?.searchTxnID || "",
        searchStartAmount: props?.transactionListData?.searchStartAmount || "",
        searchEndAmount: props?.transactionListData?.searchEndAmount || "",
        searchStartDate: props?.transactionListData?.searchStartDate || "",
        searchEndDate: props?.transactionListData?.searchEndDate || "",
        searchStatus: props?.transactionListData?.searchStatus || "",
        dateRange: props?.transactionListData?.dateRange || "",
        amountRange: props?.transactionListData?.amountRange || "",
      };
      props.getTransactionListData(payload);
    } else {
      const payload = {
        Profile_Code: menu?.Role_Permission_Data?.Profile_Code,
        pageNo: page,
        recordPerPage: rowsPerPage,
        sortColumn: property,
        sortOrder: newOrder,
        searchSenderName: props?.transactionListData?.searchSenderName || "",
        searchTxnID: props?.transactionListData?.searchTxnID || "",
        searchStartAmount: props?.transactionListData?.searchStartAmount || "",
        searchEndAmount: props?.transactionListData?.searchEndAmount || "",
        searchStartDate: props?.transactionListData?.searchStartDate || "",
        searchEndDate: props?.transactionListData?.searchEndDate || "",
        searchStatus: props?.transactionListData?.searchStatus || "",
        dateRange: props?.transactionListData?.dateRange || "",
        amountRange: props?.transactionListData?.amountRange || "",
      };
      props.getTransactionListData(payload);
    }
  };

  const handleInfoClick = (row) => {
    const localSearch = JSON.parse(localStorage.getItem("searchData"));
    const propsSearch = {
      searchEndAmount: props?.transactionListData?.searchEndAmount,
      searchEndDate: props?.transactionListData?.searchEndDate,
      amountRange: props?.transactionListData?.amountRange,
      dateRange: props?.transactionListData?.dateRange,
      searchSenderName: props?.transactionListData?.searchSenderName,
      searchStartAmount: props?.transactionListData?.searchStartAmount,
      searchStartDate: props?.transactionListData?.searchStartDate,
      searchStatus: props?.transactionListData?.searchStatus,
      searchTxnID: props?.transactionListData?.searchTxnID,
    };

    const isEqual = JSON.stringify(localSearch) === JSON.stringify(propsSearch);
    if (!isEqual) {
      localStorage.setItem("searchData", JSON.stringify(propsSearch));
    }

    if(props?.transactionListData?.dateRange === false) {
      localStorage.removeItem("isDateRangeChecked");
    }
    const updatedData = {
      searchTxnID: "",
    };
    updatedData["searchTxnID"] = row.transactionId;
    props.history.push(
      `/${props.userDetails.layout}/fee-refund-history?searchTxnID=${row.transactionId}`
    );
  };

  const exportedBy = props.mainData?.users?.userDetailsById
    && props.mainData?.users?.userDetailsById?.Users_Data[0]?.Email;
  const exportedByFname = props.mainData.users.userDetailsById
    && props.mainData.users.userDetailsById?.Users_Data[0].FName;
  const exportedByLname = props.mainData.users.userDetailsById
    && props.mainData.users.userDetailsById?.Users_Data[0].LName;

  const exportListData = () => {
    const filterData = {
      Profile_id: profileId,
      pageNo: page,
      recordPerPage: rowsPerPage,
      sortColumn: orderBy,
      sortOrder: order,
      searchSenderName: props?.transactionListData?.searchSenderName || "",
      searchTxnID: props?.transactionListData?.searchTxnID || "",
      searchStartAmount: props?.transactionListData?.searchStartAmount || "",
      searchEndAmount: props?.transactionListData?.searchEndAmount || "",
      searchStartDate: props?.transactionListData?.searchStartDate || "",
      searchEndDate: props?.transactionListData?.searchEndDate || "",
      searchStatus: props?.transactionListData?.searchStatus || "",
      dateRange: props?.transactionListData?.dateRange || "",
      amountRange: props?.transactionListData?.amountRange || "",
    };
    filterData.recordPerPage = 99999999;
    filterData.pageNo = 1;
    props.getTransactionListDataExport(filterData, (data) => {
      if (data) {
        const mappedLogs = data.data.map((row) => ({
          Sender: row.senderTitle,
          Receiver: row.recipientTitle,
          "Txn ID": row.transactionId,
          "Amount ($)": row.amount?parseFloat(row.amount):0,
          "Date & Time (EDT)": moment(row.timestamp).tz('America/New_York').format("MM/DD/YYYY hh:mm A"),
          Status: row.status,
          "Fee ($)": row.fee?parseFloat(row.fee):0,
          "Fee Txn ID": row.feeTransactionId,
          "Fee Status": row.feeStatus,
          "Fee Date": moment(row.feeDate).tz('America/New_York').format("MM/DD/YYYY hh:mm A"),
          "Refund ($)": row.refundAmount?parseFloat(row.refundAmount):0,
          "Refund Txn ID": row.refundTransactionId,
          "Refund Status": row.refundStatus,
          "Refund Date": moment(row.refundTimestamp).tz('America/New_York').format("MM/DD/YYYY hh:mm A"),
          "Reason Code": row.error,
        }));
        exportToCSVProvider(
          mappedLogs,
          "Transaction Report",
          chipValue,
          exportedBy,
          exportedByFname,
          exportedByLname
        );
      }
    });
  };

  // const exportToPDFList = () => {
  //   const filterData = {
  //     ...props.tableData.searchDetails,
  //     Profile_id: profileId,
  //     pageNo: page,
  //     recordPerPage: rowsPerPage,
  //     sortColumn: orderBy,
  //     sortOrder: order,
  //     searchSenderName: newSearchData?.searchSenderName
  //       ? newSearchData?.searchSenderName
  //       : "",
  //     searchTxnID: newSearchData?.searchTxnID ? newSearchData?.searchTxnID : "",
  //     searchStartAmount: newSearchData?.searchStartAmount
  //       ? newSearchData?.searchStartAmount
  //       : "",
  //     searchEndAmount: newSearchData?.searchEndAmount
  //       ? newSearchData?.searchEndAmount
  //       : "",
  //     searchStartDate: newSearchData?.searchStartDate
  //       ? newSearchData?.searchStartDate
  //       : "",
  //     searchEndDate: newSearchData?.searchEndDate
  //       ? newSearchData?.searchEndDate
  //       : "",
  //     searchStatus: newSearchData?.searchStatus
  //       ? newSearchData?.searchStatus
  //       : "",
  //     dateRange: newSearchData?.dateRange ? newSearchData?.dateRange : "",
  //     amountRange: newSearchData?.amountRange ? newSearchData?.amountRange : "",
  //   };
  //   filterData.recordPerPage = props.transactionListData.transactionListData.total;
  //   filterData.pageNo = 1;
  //   props.getTransactionListDataExport(filterData, (data) => {
  //     const totalCount = data.data.length;
  //     if (data) {
  //       const mappedLogs = data.data.map((row) => [
  //         row.senderTitle,
  //         row.recipientTitle,
  //         row.transactionId,
  //         row.parseFloat(row.amount).toFixed(2),
  //         moment(row.timestamp).tz('America/New_York').format("MM/DD/YYYY hh:mm A"),
  //         row.status,
  //         row.fee,
  //         row.feeTransactionId,
  //         row.feeStatus,
  //         row.feeDate,
  //         row.refundAmount,
  //         row.refundTransactionId,
  //         row.refundStatus,
  //         row.refundDate,
  //         row.reasonCode,
  //         row.referenceTransactionId,
  //       ]);

  //       const headersName = [
  //         "Sender",
  //         "Receiver",
  //         "Txn ID",
  //         "Amount ($)",
  //         "Date & Time (EDT)",
  //         "Status",
  //         "Fee ($)",
  //         "Fee Txn ID",
  //         "Fee Status",
  //         "Fee Date",
  //         "Refund ($)",
  //         "Refund Txn ID",
  //         "Refund Status",
  //         "Refund Date",
  //         "Reason Code",
  //         "Reference Txn ID",
  //       ];
  //       exportToPDF(
  //         headersName,
  //         mappedLogs,
  //         "Transaction Report",
  //         chipValue,
  //         exportedBy,
  //         exportedByFname,
  //         exportedByLname,
  //         totalCount
  //       );
  //     }
  //   });
  // };

  const handleSearchOpen = () => {
    setSearchBar(true);
  };

  const handleSearchClose = () => {
    setSearchBar(false);
  };

  const handleRefresh = () =>{
    const profileCode = menu?.Role_Permission_Data?.Profile_Code;
    if (profileCode === "Ag") {
      const requestData = {
        Profile_Code: menu?.Role_Permission_Data?.Profile_Code,
        recordPerPage: rowsPerPage,
        sortColumn: orderBy,
        sortOrder: order,
        pageNo: page,
        searchSenderName: props?.transactionListData?.searchSenderName || "",
        searchTxnID: props?.transactionListData?.searchTxnID || "",
        searchStartAmount: props?.transactionListData?.searchStartAmount || "",
        searchEndAmount: props?.transactionListData?.searchEndAmount || "",
        searchStartDate: props?.transactionListData?.searchStartDate || "",
        searchEndDate: props?.transactionListData?.searchEndDate || "",
        searchStatus: props?.transactionListData?.searchStatus || "",
        dateRange: props?.transactionListData?.dateRange || "",
        amountRange: props?.transactionListData?.amountRange || "",
      };
      props.getTransactionListData(requestData);
    } else {
      const requestData = {
        Profile_id: profileId,
        recordPerPage: rowsPerPage,
        sortColumn: orderBy,
        sortOrder: order,
        pageNo: page,
        searchSenderName: props?.transactionListData?.searchSenderName || "",
        searchTxnID: props?.transactionListData?.searchTxnID || "",
        searchStartAmount: props?.transactionListData?.searchStartAmount || "",
        searchEndAmount: props?.transactionListData?.searchEndAmount || "",
        searchStartDate: props?.transactionListData?.searchStartDate || "",
        searchEndDate: props?.transactionListData?.searchEndDate || "",
        searchStatus: props?.transactionListData?.searchStatus || "",
        dateRange: props?.transactionListData?.dateRange || "",
        amountRange: props?.transactionListData?.amountRange || "",
      };
      props.getTransactionListData(requestData);
    }
  }

  return (
    <>
      <TableContainer className={classes.TableContainer} component={Paper}>
        {windowWidth && (
          <Grid container className={classes.searchContainer}>
            <Grid item className={classes.margin}>
              <div className={classes.searchWrapper}>
                <SearchFields
                  searchBar={searchBar}
                  handleSearchClose={handleSearchClose}
                  handleSearchOpen={handleSearchOpen}
                  Fields={() => (
                    <SearchTxn
                      close={() => handleSearchClose()}
                      seacrhChipData={(data) => displayAndDeleteChips(data)}
                      clearChips={() => setChipValue([])}
                      deleteChp={deleteChp}
                    />
                  )}
                />
                <IconsTooltip title="Refresh" placement="top">
                  <div className={classes.appSvgIcons}>
                    <SyncIcon style={{marginRight:"10px"}} fontSize = "large" color= "primary" onClick={handleRefresh}/>
                  </div>
                </IconsTooltip>
                <IconsTooltip title="Export" placement="top">
                  <div className={classes.appSvgIcons}>
                    <ExportIcon
                      aria-controls="simple-menu"
                      aria-haspopup="true"
                      onClick={handleToggle}
                      ref={anchorRef}
                    />
                  </div>
                </IconsTooltip>
                {actionPermission(
                  permissionCheck.Category.setup,
                  permissionCheck.Page.merchant,
                  permissionCheck.option.export
                )
                  && (actionPermission(
                    permissionCheck.Category.setup,
                    permissionCheck.Page.merchant,
                    permissionCheck.option.exportToExcel
                  )
                    || actionPermission(
                      permissionCheck.Category.setup,
                      permissionCheck.Page.merchant,
                      permissionCheck.option.exportToPdf
                    )) && (
                  <IconsTooltip title="Export" placement="top">
                    <div className={classes.appSvgIcons}>
                      <ExportIcon
                        aria-controls="simple-menu"
                        aria-haspopup="true"
                        onClick={handleToggle}
                        ref={anchorRef}
                      />
                    </div>
                  </IconsTooltip>
                )}
                <Popper
                  open={openImportFileModal}
                  anchorEl={anchorRef.current}
                  role={undefined}
                  placement="bottom-start"
                  transition
                  disablePortal
                  style={{
                    zIndex: "10",
                  }}
                >
                  {({ TransitionProps, placement }) => (
                    <Grow
                      {...TransitionProps}
                      style={{
                        transformOrigin:
                          placement === "bottom-start"
                            ? "left top"
                            : "left bottom",
                      }}
                    >
                      <Paper>
                        <ClickAwayListener onClickAway={handleCloseModel}>
                          <MenuList
                            id="menu-list-grow"
                            onKeyDown={handleListKeyDown}
                          >
                            {actionPermission(
                              permissionCheck.Category.setup,
                              permissionCheck.Page.merchant,
                              permissionCheck.option.exportToExcel
                            ) && (
                              <MenuItem
                                onClick={exportListData}
                                style={{
                                  fontSize: "14px",
                                }}
                              >
                                Export to Excel
                              </MenuItem>
                            )}
                          </MenuList>
                        </ClickAwayListener>
                      </Paper>
                    </Grow>
                  )}
                </Popper>
              </div>
            </Grid>
          </Grid>
        )}
        {!windowWidth && (
          <Grid container className={classes.searchContainer}>
            <Grid item className={classes.margin} style={{ display: "flex" }}>
              <div className={classes.searchWrapper}>
                <SearchFields
                  searchBar={searchBar}
                  handleSearchClose={handleSearchClose}
                  handleSearchOpen={handleSearchOpen}
                  Fields={() => (
                    <SearchTxn
                      close={() => handleSearchClose()}
                      seacrhChipData={(data) => displayAndDeleteChips(data)}
                      clearChips={() => setChipValue([])}
                    />
                  )}
                />
                <IconsTooltip title="Export" placement="top">
                  <div className={classes.appSvgIcons}>
                    <ExportIcon
                      aria-controls="simple-menu"
                      aria-haspopup="true"
                      onClick={handleToggle}
                      ref={anchorRef}
                    />
                  </div>
                </IconsTooltip>
                <Popper
                  open={openImportFileModal}
                  anchorEl={anchorRef.current}
                  role={undefined}
                  placement="bottom-start"
                  transition
                  disablePortal
                  style={{
                    zIndex: "10",
                  }}
                >
                  {({ TransitionProps, placement }) => (
                    <Grow
                      {...TransitionProps}
                      style={{
                        transformOrigin:
                          placement === "bottom-start"
                            ? "left top"
                            : "left bottom",
                      }}
                    >
                      <Paper>
                        <ClickAwayListener onClickAway={handleCloseModel}>
                          <MenuList
                            id="menu-list-grow"
                            onKeyDown={handleListKeyDown}
                          >
                            {actionPermission(
                              permissionCheck.Category.setup,
                              permissionCheck.Page.merchant,
                              permissionCheck.option.exportToExcel
                            ) && (
                              <MenuItem
                                style={{
                                  fontSize: "14px",
                                }}
                              >
                                Export to Excel
                              </MenuItem>
                            )}
                            {actionPermission(
                              permissionCheck.Category.setup,
                              permissionCheck.Page.merchant,
                              permissionCheck.option.exportToPdf
                            ) && (
                              <MenuItem
                                style={{
                                  fontSize: "14px",
                                }}
                              >
                                Export to PDF
                              </MenuItem>
                            )}
                          </MenuList>
                        </ClickAwayListener>
                      </Paper>
                    </Grow>
                  )}
                </Popper>
              </div>
            </Grid>
          </Grid>
        )}
        <SearchChips
          searchValues={chipValue}
          handleDeleteChip={(chip, index) => handleDeleteChip(chip, index)}
        />
        <Table className={classes.table} aria-label="simple table">
          <EnhancedTableHead
            classes={classes}
            numSelected={selected.length}
            order={order}
            orderBy={orderBy}
            onSelectAllClick={handleSelectAllClick}
            onRequestSort={handleRequestSort}
            rowCount={rows.length}
          />
          <TableBody>
            {!isEmpty(rows) ? (
              rows.map((row, index) => (
                <TableRow className={classes.cellHeadSign} key={index}>
                  <TableCell
                    align="center"
                    className={classes.cellText}
                  >
                    <div>
                      <div>{row.senderTitle}</div>
                      <div
                        style={{
                          fontSize: "12px",
                          color: "#794411",
                        }}
                      >
                        {row.managerName}
                      </div>
                    </div>
                  </TableCell>
                  <TableCell
                    className={classes.cellText}
                    align="center"
                  >
                    {row.recipientTitle}
                  </TableCell>
                  <TableCell
                    className={classes.cellText}
                    align="center"
                  >
                    {row.transactionId}
                  </TableCell>
                  <TableCell
                    className={classes.cellText}
                    align="center"
                  >
                    <IconsTooltip
                      title={(
                        <div
                          style={{
                            width: "200px",
                            fontSize: "13px",
                          }}
                        >
                          {tooltipContent.transactionId && (
                            <div>
                              <strong>Txn ID:</strong>{" "}
                              {tooltipContent.transactionId}
                            </div>
                          )}
                          {tooltipContent.amount && (
                            <div>
                              <strong>Amount:</strong> {tooltipContent.amount}
                            </div>
                          )}
                          {tooltipContent.fee && (
                            <div>
                              <strong>Fee $:</strong> {parseFloat(tooltipContent.fee).toFixed(2)}
                            </div>
                          )}
                          {tooltipContent.feeTransactionId && (
                            <div>
                              <strong>Fee Txn ID:</strong>{" "}
                              {tooltipContent.feeTransactionId}
                            </div>
                          )}
                          {tooltipContent.feeStatus && (
                            <div>
                              <strong>Fee Txn Status:</strong>{" "}
                              {tooltipContent.feeStatus}
                            </div>
                          )}
                          {tooltipContent.refundAmount && (
                            <div>
                              <strong>Refund $:</strong>{" "}
                              {tooltipContent.refundAmount}
                            </div>
                          )}
                          {tooltipContent.refundTransactionId && (
                            <div>
                              <strong>Refund Txn ID:</strong>{" "}
                              {tooltipContent.refundTransactionId}
                            </div>
                          )}
                          {tooltipContent.refundStatus && (
                            <div>
                              <strong>Refund Status:</strong>{" "}
                              {tooltipContent.refundStatus}
                            </div>
                          )}
                          {tooltipContent.refundReasonCode && (
                            <div>
                              <strong>Refund Reason Code:</strong>{" "}
                              {tooltipContent.refundReasonCode}
                            </div>
                          )}
                        </div>
                      )}
                      placement="top"
                    >
                      <a
                        href="#"
                        style={{
                          color: "blue",
                          textDecoration: "underline",
                          cursor: "pointer",
                        }}
                        onClick={(e) =>{
                          e.preventDefault();
                          handleOpenModal(tooltipContent);
                        }}
                        onMouseEnter={() => handleMouseEnter(row.amount)}
                        onMouseLeave={handleMouseLeave}
                      >
                        {parseFloat(row.amount.amount).toFixed(2)}
                      </a>
                    </IconsTooltip>
                    <Popper
                      open={false}
                      anchorEl={anchorRef.current}
                      role={undefined}
                      placement="bottom-start"
                      transition
                      disablePortal
                      style={{
                        zIndex: "10",
                      }}
                    >
                      {({ TransitionProps, placement }) => (
                        <Grow
                          {...TransitionProps}
                          style={{
                            transformOrigin:
                              placement === "bottom-start"
                                ? "left top"
                                : "left bottom",
                          }}
                        >
                          <IconsTooltip title="tooltip" placement="top">
                            {" "}
                            <div
                              style={{
                                width: "200px",
                                fontSize: "13px",
                              }}
                            >
                              {tooltipContent.transactionId && (
                                <div>
                                  <strong>Txn ID:</strong>{" "}
                                  {tooltipContent.transactionId}
                                </div>
                              )}
                              {tooltipContent.amount && (
                                <div>
                                  <strong>Amount:</strong>{" "}
                                  {tooltipContent.amount}
                                </div>
                              )}
                              {tooltipContent.fee && (
                                <div>
                                  <strong>Fee $:</strong> {tooltipContent.fee}
                                </div>
                              )}
                              {tooltipContent.feeTransactionId && (
                                <div>
                                  <strong>Fee Txn ID:</strong>{" "}
                                  {tooltipContent.feeTransactionId}
                                </div>
                              )}
                              {tooltipContent.feeStatus && (
                                <div>
                                  <strong>Fee Txn Status:</strong>{" "}
                                  {tooltipContent.feeStatus}
                                </div>
                              )}
                              {tooltipContent.refundAmount && (
                                <div>
                                  <strong>Refund $:</strong>{" "}
                                  {tooltipContent.refundAmount}
                                </div>
                              )}
                              {tooltipContent.refundTransactionId && (
                                <div>
                                  <strong>Refund Txn ID:</strong>{" "}
                                  {tooltipContent.refundTransactionId}
                                </div>
                              )}
                              {tooltipContent.refundStatus && (
                                <div>
                                  <strong>Refund Status:</strong>{" "}
                                  {tooltipContent.refundStatus}
                                </div>
                              )}
                              {tooltipContent.refundReasonCode && (
                                <div>
                                  <strong>Refund Reason Code:</strong>{" "}
                                  {tooltipContent.refundReasonCode}
                                </div>
                              )}
                            </div>
                          </IconsTooltip>
                        </Grow>
                      )}
                    </Popper>
                  </TableCell>
                  <TooltipModal
                    open={modalOpen}
                    onClose={handleCloseModal}
                    content={modalContent}
                  />
                  <TableCell
                    className={classes.cellText}
                    align="center"
                  >
                    {row.timestamp}
                  </TableCell>
                  <TableCell
                    className={classes.cellText}
                    align="center"
                  >
                    {row.status}
                  </TableCell>
                  <TableCell
                    className={classes.cellText}
                    align="center"
                  >
                    <Tooltip
                      title={<div>View Details</div>}
                      placement="right"
                      arrow
                    >
                      <InfoIcon
                        fontSize="small"
                        color="action"
                        onClick={() => handleInfoClick(row)}
                      />
                    </Tooltip>
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell className="no-data" colSpan={12}>
                  <img
                    src={noDataImage}
                    alt=""
                    className={classes.noDataImage}
                  />
                </TableCell>
              </TableRow>
            )}
          </TableBody>
          {rows.length ? (
            <TableFooter>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[
                    5,
                    10,
                    25,
                    {
                      label: "All",
                      value:
                        props?.transactionListData?.transactionListData?.total,
                    },
                  ]}
                  colSpan={12}
                  count={props?.transactionListData?.transactionListData?.total}
                  rowsPerPage={rowsPerPage ? rowsPerPage : 5}
                  page={page - 1}
                  SelectProps={{
                    inputProps: { "aria-label": "rows per page" },
                    MenuProps: { classes: { paper: classes.selectDropdown } },
                  }}
                  classes={{ menuItem: classes.menuItem }}
                  onChangePage={handleChangePage}
                  onChangeRowsPerPage={handleChangeRowsPerPage}
                  ActionsComponent={TablePaginationActions}
                />
              </TableRow>
            </TableFooter>
          ) : (
            ""
          )}
        </Table>
      </TableContainer>
    </>
  );
};

TransactionList.propTypes = {
  tableData: PropTypes.object,
  history: PropTypes.any,
  userDetails: PropTypes.object,
  mainData: PropTypes.object,
  getTransactionListData: PropTypes.func,
  getTransactionListDataExport: PropTypes.func,
  emptyReduxState: PropTypes.func,
};

const mapStateToProps = (app) => ({
  tableData: app.provideRegistration,
  userDetails: app.app,
  mainData: app,
  roles: app.roles,
  transactionListData: app.genrateOfferPatient,
});
export default connect(mapStateToProps, {
  setFormData,
  clearFormData,
  getTransactionListData,
  getTransactionListDataExport,
  emptyReduxState,
})(TransactionList);
