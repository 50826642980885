import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import MenuItem from "@material-ui/core/MenuItem";
import styles from "assets/jss/material-dashboard-react/components/businessForm";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { format } from "date-fns";
import { createTheme } from "@material-ui/core";
import { MuiThemeProvider } from "@material-ui/core/styles";
import { getApiLogsData, setFormDataApiLogs, clearFormDataApiLogs } from "../../Redux/Actions/genrateOfferPatient";

export const customTheme = createTheme({
  palette: {
    primary: {
      main: "#003c6e",
      light: "#003c6e",
      dark: "#003c6e",
    },
    secondary: {
      main: "#003c6e",
    },
  },
  overrides: {
    MuiPickersBasePicker: {
      container: {
        width: "300px",
        height: "370px",
      },
      pickerView: {
        width: "280px",
        height: "280px",
      },
    },
    MuiPickersCalendarHeader: {
      switchHeader: {
        fontSize: "0.9rem",
      },
      dayLabel: {
        fontSize: "0.7rem",
      },
    },
    MuiPickersDay: {
      day: {
        fontSize: "0.8rem",
      },
    },
    MuiTypography: {
      h4: {
        fontSize: "1rem",
      },
    },
  },
});

const useStyles = makeStyles(styles);
const searchButtonStyles = makeStyles(theme => ({
  searchButton: {
    backgroundColor: theme.palette.primary.main,
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
      boxShadow: "0 14px 26px -12px rgb(153 153 153 / 42%), 0 4px 23px 0px rgb(0 0 0 / 12%), 0 8px 10px -5px rgb(153 153 153 / 20%)",
    },
  },
}));

function ApiLogsSearch(props) {
  const classes = useStyles();
  const searchButtonClasses = searchButtonStyles();
  const menu = JSON.parse(localStorage.getItem("menu"));
  const clearFormData = () => {
    const initialFormData = {
      searchApiType: "",
      searchApiTypeValue: "",
      searchModule: "",
      searchModuleValue: "",
      searchRequestDate: "",
      searchResponseDate: "",
      searchStatus: null,
      searchStatusValue: "",
    };
    props.setFormDataApiLogs(initialFormData);
  };

  const handleClose = () => {
    const profileCode = menu?.Role_Permission_Data?.Profile_Code;
    const payload = {
      Profile_Code: profileCode,
      pageNo: 1,
      recordPerPage: 5,
    };
    props.getApiLogsData(payload);
    props.clearFormDataApiLogs();
    clearFormData();
    props.clearChips();
  };

  const handleApiTypeChange = value => {
    let Type_Code = null;

    switch (value) {
    case "RTN API":
      Type_Code = 1;
      break;
    case "Send Fund API":
      Type_Code = 2;
      break;
    default:
      Type_Code = null;
      break;
    }
    props.setFormDataApiLogs({
      ...props.formData,
      searchApiType: Type_Code,
      searchApiTypeValue: value,
    });
  };

  const handleModuleChange = value => {
    let Module_Code = null;

    switch (value) {
    case "Invite Page":
      Module_Code = 1;
      break;
    case "Merchant Submit Page":
      Module_Code = 2;
      break;
    case "Make Payment Page":
      Module_Code = 3;
      break;
    case "Merchant SignUp Page":
      Module_Code = 4;
      break;
    default:
      Module_Code = null;
      break;
    }
    props.setFormDataApiLogs({
      ...props.formData,
      searchModule: Module_Code,
      searchModuleValue: value,
    });
  };

  const handleFromDateChange = date => {
    let formattedDate = null;
    if (date instanceof Date) {
      formattedDate = format(date, "yyyy-MM-dd");
    } else if (typeof date === "string") {
      const parts = date.split("/");
      if (parts.length === 3) {
        formattedDate = `${parts[2]}-${parts[1]}-${parts[0]}`;
      }
    }
    props.setFormDataApiLogs({
      ...props.formData,
      searchRequestDate: formattedDate,
    });
  };

  const onChange = e => {
    const { name, value } = e.target;

    if (name === "searchApiType") {
      handleApiTypeChange(value);
    } else if (name === "searchModule") {
      handleModuleChange(value);
    }else {
      props.setFormDataApiLogs({
        ...props.formData,
        [name]: value,
      });
    }
  };

  const onFormSubmit = event => {
    event.preventDefault();
    const profileCode = menu?.Role_Permission_Data?.Profile_Code;
    const payload = {
      Profile_Code: profileCode,
      pageNo: 1,
      recordPerPage: 5,
      sortColumn: props.orderBy,
      sortOrder: props.order,
      searchApiType: props.formData.searchApiType,
      searchModule: props.formData.searchModule,
      fromDate: props.formData.searchRequestDate,
      toDate: props.formData.searchResponseDate,
      searchStatus: props.formData.searchStatus,
    };

    props.getApiLogsData(payload);
    props.seacrhChipData(payload);
    props.close();
  };

  return (
    <MuiThemeProvider theme={customTheme}>
      <form onSubmit={e=>onFormSubmit(e)}>
        <Grid container className={classes.margin} style={{ marginBottom: "5%" }}>
          <Grid item xs>
            <TextField
              className={classes.rootDialog}
              InputLabelProps={{
                classes: {
                  root: classes.activeInputLableColor,
                  focused: "focused",
                },
              }}
              InputProps={{
                classes: {
                  root: classes.activeInputColor,
                  input: classes.resize,
                  focused: "focused",
                },
              }}
              placeholder="Select API Type"
              label="API Type"
              value={props.formData.searchApiTypeValue || ""}
              onChange={onChange}
              name="searchApiType"
              id="searchApiType"
              select
              style={{ fontSize: "82%" }}
            >
              <MenuItem
                className={classes.dialogBoxDropDown}
                value="RTN API"
              >
                RTN API
              </MenuItem>
              <MenuItem
                className={classes.dialogBoxDropDown}
                value="Send Fund API"
              >
                Send Fund API
              </MenuItem>
            </TextField>
          </Grid>
        </Grid>
        <Grid container className={classes.margin} style={{ marginBottom: "5%" }}>
          <Grid item xs>
            <TextField
              className={classes.rootDialog}
              InputLabelProps={{
                classes: {
                  root: classes.activeInputLableColor,
                  focused: "focused",
                },
              }}
              InputProps={{
                classes: {
                  root: classes.activeInputColor,
                  input: classes.resize,
                  focused: "focused",
                },
              }}
              placeholder="Select Module"
              label="Module"
              value={props.formData.searchModuleValue || ""}
              onChange={onChange}
              name="searchModule"
              id="searchModule"
              select
              style={{ fontSize: "82%" }}
            >
              <MenuItem
                className={classes.dialogBoxDropDown}
                value="Invite Page"
              >
                Invite Page
              </MenuItem>
              <MenuItem
                className={classes.dialogBoxDropDown}
                value="Merchant Submit Page"
              >
                Merchant Submit Page
              </MenuItem>
              <MenuItem
                className={classes.dialogBoxDropDown}
                value="Make Payment Page"
              >
                Make Payment Page
              </MenuItem>
              <MenuItem
                className={classes.dialogBoxDropDown}
                value="Merchant SignUp Page"
              >
                Merchant SignUp Page
              </MenuItem>
            </TextField>
          </Grid>
        </Grid>
        <Grid container className={classes.margin} style={{ marginBottom: "5%" }} >
          <Grid item xs>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <DatePicker
                autoOk
                variant="inline"
                label="Request Date"
                value={props.formData.searchRequestDate ? new Date(props.formData.searchRequestDate) : null}
                onChange={handleFromDateChange}
                format="yyyy/MM/dd"
                maxDate={new Date()}
                className={`${classes.rootDialog}`}
              />
            </MuiPickersUtilsProvider>
          </Grid>
        </Grid>
        <Grid container className={classes.margin} style={{ marginBottom: "5%" }}>
          <Grid item xs>
            <TextField
              className={classes.rootDialog}
              InputLabelProps={{
                classes: {
                  root: classes.activeInputLableColor,
                  focused: "focused",
                },
              }}
              InputProps={{
                classes: {
                  root: classes.activeInputColor,
                  input: classes.resize,
                  focused: "focused",
                },
              }}
              placeholder="Select Status"
              label="Status"
              value={props.formData.searchStatus || ""}
              onChange={onChange}
              name="searchStatus"
              id="Current_Status"
              select
              style={{ fontSize: "82%" }}
            >
              <MenuItem
                className={classes.dialogBoxDropDown}
                value="Success"
              >
                Success
              </MenuItem>
              <MenuItem
                className={classes.dialogBoxDropDown}
                value="Failure"
              >
                Failure
              </MenuItem>
            </TextField>
          </Grid>
        </Grid>

        <Grid
          container
          className={classes.margin}
          style={{ justifyContent: "end" }}
        >
          <Button
            variant="contained"
            className={classes.clearButton}
            disabled={
              !props.formData.searchApiType
            && !props.formData.searchModule
            && !props.formData.searchStatus
            && !props.formData.searchRequestDate
            && !props.formData.searchResponseDate
            }
            onClick={() => handleClose()}
          >
            clear
          </Button>
          <Button
            variant="contained"
            color="primary"
            className={`${classes.buttonStyle} ${searchButtonClasses.searchButton}`}
            disabled={
              !props.formData.searchApiType
            && !props.formData.searchModule
            && !props.formData.searchStatus
            && !props.formData.searchRequestDate
            && !props.formData.searchResponseDate
            }
            type="submit"
          >
            Search
          </Button>
        </Grid>
      </form>
    </MuiThemeProvider>
  );
}

ApiLogsSearch.propTypes = {
  close: PropTypes.func,
  seacrhChipData: PropTypes.func,
  getApiLogsData: PropTypes.func,
};

const mapStateToProps = app => ({
  formData: app.genrateOfferPatient,
  tableData: app.provideRegistration,
  userDetails: app.app,
  apiLogsData: app.genrateOfferPatient,
});

export default connect(mapStateToProps, {
  setFormDataApiLogs,
  clearFormDataApiLogs,
  getApiLogsData,
})(ApiLogsSearch);
