import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import MenuItem from "@material-ui/core/MenuItem";
import styles from "assets/jss/material-dashboard-react/components/businessForm";
import { useDispatch } from "react-redux";
import AgentSearchChipManager from "./AgentSearchChipManager";
import {
  getMerchantListData,
  setFormDataManager,
  clearFormDataManager,
  getManagerListData,
  getManagerListDataByID,
} from "../../Redux/Actions/genrateOfferPatient";

const useStyles = makeStyles(styles);
const searchButtonStyles = makeStyles(theme => ({
  searchButton: {
    backgroundColor: theme.palette.primary.main,
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
      boxShadow: `0 14px 26px -12px rgb(153 153 153 / 42%), 0 4px 23px 0px rgb(0 0 0 / 12%), 0 8px 10px -5px rgb(153 153 153 / 20%)`,
    },
  },
}));

function SearchDialogManager(props) {
  const classes = useStyles();
  const searchButtonClasses = searchButtonStyles();
  const dispatch = useDispatch();
  const profileId = localStorage.getItem("profileId");
  const [id, setId] = React.useState(null);
  const [business1, setBusiness1] = React.useState("");
  const [manager1, setManager1] = React.useState("");
  const [managerId, setManagerId] = React.useState("");
  const [uid1, setUid1] = React.useState("");
  const [email1, setEmail1] = React.useState("");
  const [status1, setStatus1] = React.useState("");

  const clearFormData = () => {
    const initialFormData = {
      searchBusinessName: "",
      searchManagerUID: "",
      searchEmail: "",
      searchStatus: null,
      searchStatusValue: "",
      searchManagerName: "",
      searchManagerId: "",
    };
    props.setFormDataManager(initialFormData);
  };

  React.useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const idFromUrl = queryParams.get("id");

    setId(idFromUrl);
  }, []);

  const handleClose = () => {
    if (id) {
      const payloadWhenId = {
        Bussines_id: id,
        recordPerPage: 5,
        pageNo: 1,
      };
      props.getManagerListDataByID(payloadWhenId);
    } else if (id == null) {
      const payload = {
        Profile_Id: profileId,
        pageNo: 1,
        recordPerPage: 5,
      };
      props.getManagerListData(payload);
    }
    props.clearFormDataManager();
    clearFormData();
    props.clearChips();
  };

  const onChange = e => {
    debugger
    const { name, value } = e.target;
    dispatch(
      setFormDataManager({
        ...props.formData,
        [name]: value,
      })
    );
  };

  const handleSetSearchManagerName = value => {
    setManager1(value);
  };

  const handleSetSearchManagerId = value => {
    setManagerId(value)
  };

  const onFormSubmit = event => {
    event.preventDefault();
    const submitFormData = {
      searchBusinessName: business1.trim(),
      searchManagerName: manager1.trim(),
      searchManagerUID: uid1.trim(),
      searchEmail: email1.trim(),
      searchStatus: status1,
      searchStatusValue: "",
      searchManagerId: managerId,
    };
    props.setFormDataManager(submitFormData);
    if (id) {
      const payloadWhenId = {
        Bussines_id: id,
        recordPerPage: 5,
        searchBusinessName: business1.trim(),
        searchManagerName: "",
        searchManagerUID: uid1.trim(),
        searchEmail: email1.trim(),
        searchStatus: status1,
        searchManagerId: managerId,
        pageNo: 1,
      };
      const payloadWithManagerName = {
        ...payloadWhenId,
        searchManagerName: manager1.trim(),
      };
      props.seacrhChipData(payloadWithManagerName);
      props.close();
    } else if (
      id == null
    ) {
      const payload = {
        Profile_Id: profileId,
        pageNo: 1,
        recordPerPage: 5,
        searchBusinessName: business1.trim(),
        searchManagerName: "",
        searchManagerUID: uid1.trim(),
        searchEmail: email1.trim(),
        searchStatus: status1,
        searchManagerId: managerId,
      };
      const payloadWithManagerName = {
        ...payload,
        searchManagerName: manager1.trim(),
      };
      props.seacrhChipData(payloadWithManagerName);
      props.close();
    }
  };

  React.useEffect(() => {
    if (props.formData.searchBusinessName) {
      setBusiness1(props.formData.searchBusinessName);
    }
    if (props.formData.searchManagerName) {
      setManager1(props.formData.searchManagerName);
    }
    if (props.formData.searchManagerUID) {
      setUid1(props.formData.searchManagerUID);
    }
    if (props.formData.searchEmail) {
      setEmail1(props.formData.searchEmail);
    }
    if (props.formData.searchStatus) {
      setStatus1(props.formData.searchStatus);
    }
  }, [props.formData]);

  const setParentSearchManagerName = value => {
    setManager1(value);
  };

  const setParentSearchManagerId = value => {
    setManagerId(value);
  };

  const handleInputChange = e => {
    setBusiness1(e.target.value);
  };

  const handleInputChange1 = e => {
    setUid1(e.target.value);
  };

  const handleInputChange2 = e => {
    setEmail1(e.target.value);
  };
  return (
    <>
      <form onSubmit={e => onFormSubmit(e)}>
        <Grid
          container
          className={classes.margin}
          style={{ marginBottom: "5%" }}
        >
          <Grid item xs>
            <TextField
              className={classes.rootDialog}
              InputLabelProps={{
                classes: {
                  root: classes.activeInputLableColor,
                  focused: "focused",
                },
              }}
              InputProps={{
                classes: {
                  root: classes.activeInputColor,
                  focused: "focused",
                  input: classes.resize,
                },
              }}
              label="Business Name"
              value={business1}
              onChange={handleInputChange}
              name="searchBusinessName"
              id="Business Name"
              style={{ fontSize: "82%" }}
            />
          </Grid>
        </Grid>
        <Grid
          container
          className={classes.margin}
          style={{ marginBottom: "5%" }}
        >
          <AgentSearchChipManager
            onChange={(value, type) => {
              setFormDataManager({
                ...props?.formData,
                [type]: value,
              });
              props.seacrhChipData({ ...props?.formData, [type]: value });
              setParentSearchManagerName(value);
              setParentSearchManagerId(value);
            }}
            setParentSearchManagerName={handleSetSearchManagerName}
            setParentSearchManagerId={handleSetSearchManagerId}
            values={manager1}
            propsf={props.formData.searchManagerName}
            clearSearchManagerName={() => {
              dispatch(
                setFormDataManager({
                  ...props?.formData,
                  searchManagerName: "",
                  searchManagerId: "",
                })
              );
            }}
          />
        </Grid>
        <Grid
          container
          className={classes.margin}
          style={{ marginBottom: "5%" }}
        >
          <Grid item xs>
            <TextField
              className={classes.rootDialog}
              InputLabelProps={{
                classes: {
                  root: classes.activeInputLableColor,
                  focused: "focused",
                },
              }}
              InputProps={{
                classes: {
                  root: classes.activeInputColor,
                  focused: "focused",
                  input: classes.resize,
                },
              }}
              label="Manager UID"
              value={uid1}
              onChange={handleInputChange1}
              name="searchManagerUID"
              id="Manager UID"
              style={{ fontSize: "82%" }}
            />
          </Grid>
        </Grid>
        {/* <Grid
          container
          className={classes.margin}
          style={{ marginBottom: "5%" }}
        >
          <Grid item xs>
            <TextField
              className={classes.rootDialog}
              InputLabelProps={{
                classes: {
                  root: classes.activeInputLableColor,
                  focused: "focused",
                },
              }}
              InputProps={{
                classes: {
                  root: classes.activeInputColor,
                  focused: "focused",
                  input: classes.resize,
                },
              }}
              label="Email address"
              value={email1}
              onChange={handleInputChange2}
              name="searchEmail"
              id="Email"
              style={{ fontSize: "82%" }}
            />
          </Grid>
        </Grid> */}
        <Grid
          container
          className={classes.margin}
          style={{ marginBottom: "5%" }}
        >
          <Grid item xs>
            <TextField
              className={classes.rootDialog}
              InputLabelProps={{
                classes: {
                  root: classes.activeInputLableColor,
                  focused: "focused",
                },
              }}
              InputProps={{
                classes: {
                  root: classes.activeInputColor,
                  input: classes.resize,
                  focused: "focused",
                },
              }}
              placeholder="Select Status"
              label="Status"
              value={status1}
              onChange={onChange}
              name="searchStatus"
              id="Current_Status"
              select
              style={{ fontSize: "82%" }}
            >
              <MenuItem className={classes.dialogBoxDropDown} value="Invited">
                Invited
              </MenuItem>
              <MenuItem className={classes.dialogBoxDropDown} value="Active">
                Active
              </MenuItem>
            </TextField>
          </Grid>
        </Grid>

        <Grid
          container
          className={classes.margin}
          style={{ justifyContent: "end" }}
        >
          <Button
            variant="contained"
            className={classes.clearButton}
            disabled={
              business1 === "" &&
              manager1 === "" &&
              uid1 === "" &&
              status1 === "" &&
              email1 === ""
            }
            onClick={() => handleClose()}
          >
            clear
          </Button>
          <Button
            variant="contained"
            color="primary"
            className={`${classes.buttonStyle} ${searchButtonClasses.searchButton}`}
            disabled={
              business1 === "" &&
              manager1 === "" &&
              uid1 === "" &&
              status1 === "" &&
              email1 === ""
            }
            type="submit"
          >
            Search
          </Button>
        </Grid>
      </form>
    </>
  );
}

SearchDialogManager.propTypes = {
  close: PropTypes.func,
  tableData: PropTypes.object,
  seacrhChipData: PropTypes.func,
  getMerchantListData: PropTypes.func,
  getManagerListData: PropTypes.func,
  getManagerListDataByID: PropTypes.func,
};

const mapStateToProps = app => ({
  formData: app.genrateOfferPatient,
  formDataManager: app.genrateOfferPatient,
  tableData: app.provideRegistration,
  userDetails: app.app,
});

export default connect(mapStateToProps, {
  setFormDataManager,
  clearFormDataManager,
  getMerchantListData,
  getManagerListData,
  getManagerListDataByID,
})(SearchDialogManager);
