/* eslint-disable import/no-named-as-default */
/* eslint-disable import/no-named-as-default-member */
// @material-ui/icons
import Dashboard from "@material-ui/icons/Dashboard";
import SettingsIcon from "@material-ui/icons/Settings";
import AssessmentIcon from "@material-ui/icons/Assessment";
import AssignmentIcon from "@material-ui/icons/Assignment";
import { ReactComponent as RiskManagementIcon } from "../../assets/svgIcons/RiskManagementIcon.svg";

export const iconArray = [
  {
    name: "Dashboard",
    icon: Dashboard
  },
  {
    name: "Reports",
    icon: AssignmentIcon
  },
  {
    name: "Setup",
    icon: SettingsIcon
  },
  {
    name: "Residual",
    icon: AssessmentIcon
  },
  {
    name: "Payment",
    icon: AssessmentIcon
  },
  {
    name: "Risk Management",
    icon: RiskManagementIcon
  }
];
