import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { getAgentByHierarchy } from "../../Redux/Actions/ProvideRegistration";
import styles from "assets/jss/material-dashboard-react/components/businessForm";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(styles);

function AgentSearchChipManager(props) {
  const classes = useStyles();
  const { clearSearchManagerName } = props;
  const [searchManagerName, setSearchManagerName] = React.useState("");

  const uniqueManagerSet = new Set();

  const uniqueManagers = (props.managerData?.managerListNames?.data || [])
    .map((manager) => ({
      name: `${manager?.First_Name || ''} ${manager?.Last_Name || ''}`,
      email: manager?.Email || '',
      id: manager?._id || ''
    }))
    .filter((manager) => {
      if (uniqueManagerSet.has(manager.email)) {
        return false;
      } else {
        uniqueManagerSet.add(manager.email);
        return true;
      }
    });


  const {setParentSearchManagerId, setParentSearchManagerName, propsf } = props;

  const handleSearchManagerNameChange = (value) => {
    setSearchManagerName(value ? `${value.name} [${value.email}]` : "");
    setParentSearchManagerName(value ? `${value.name} [${value.email}]` : "");
    setParentSearchManagerId(value ? value.id : "");
  };

  return (
    (propsf || searchManagerName ?
    <Autocomplete
      value={{ name: propsf || searchManagerName }}
      name="searchManagerName"
      id="searchManagerName"
      className={classes.rootDialog}
      onChange={(e, value) => {
        handleSearchManagerNameChange(value);
      }}
      options={uniqueManagers}
      clearOnBlur
      clearOnEscape
      getOptionLabel={(option) => option ? `${option.name || ''} [${option.email || ''}]` : ""}
      onClear={clearSearchManagerName}
      renderOption={(option) => (
        <div>
          {option.name} <span style={{ fontSize: '13px', color: 'rgb(121, 68, 17)' }}>[{option.email}]</span>
        </div>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          style={{ fontSize: "10%" }}
          name="searchManagerName"
          id="searchManagerName"
          label="Manager Name/Email"
        />
      )}
    /> :
    <Autocomplete
      name="searchManagerName"
      id="searchManagerName"
      className={classes.rootDialog}
      onChange={(e, value) => {
        handleSearchManagerNameChange(value);
      }}
      options={uniqueManagers}
      clearOnBlur
      clearOnEscape
      getOptionLabel={(option) => option ? `${option.name || ''} ${option.email || ''}` : ""}
      onClear={clearSearchManagerName}
      renderOption={(option) => (
        <div>
          {option.name} <span style={{ fontSize: '13px', color: 'rgb(121, 68, 17)' }}>[{option.email}]</span>
        </div>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          style={{ fontSize: "10%" }}
          name="searchManagerName"
          id="searchManagerName"
          label="Manager Name/Email"
        />
      )}
    />)
  );
}
AgentSearchChipManager.propTypes = {
  tableData: PropTypes.object,
  values: PropTypes.string,
  getAgentByHierarchy: PropTypes.func,
  onChange: PropTypes.func,
  label: PropTypes.string, 
  clearSearchManagerName: PropTypes.func,
};

const mapStateToProps = (app) => ({
  tableData: app.provideRegistration,
  managerData: app.genrateOfferPatient
});

export default connect(mapStateToProps, {
  getAgentByHierarchy
})(AgentSearchChipManager);