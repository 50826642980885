import { mixed, object, string, date, number } from "yup";

const phoneNumberRegEx =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
const emailRegEx =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const alphaRegEx = /^[A-Za-z]+$/;
const ssnRegEx = /^\d{9}$/;
const twoDecimalRegEx = /^[0-9]*(\.?[0-9]{1,2}$)?$/;


export default {
  addPatient: object({
    firstName: string("Please enter a valid first name.")
      .required("First name is required.")
      .test(
        "firstName",
        "Only alphabets are allowed.",
        (value) => !value || alphaRegEx.test(value)
      )
      .trim(),
    lastName: string("Please enter a valid last name.")
      .required("Last name is required.")
      .test(
        "lastName",
        "Only alphabets are allowed.",
        (value) => !value || alphaRegEx.test(value)
      )
      .trim(),
    email: string("Please enter a valid email address.")
      .required("Email is required.")
      .email("Please enter a valid email address.")
      .test(
        "email",
        "Please enter a valid email address.",
        (value) => !value || emailRegEx.test(value)
      )
      .trim(),
    ssn: string("Please enter a valid SSN")
      .required("SSN is required.")
      .test(
        "ssn",
        "Please enter a valid SSN.",
        (value) => !value || ssnRegEx.test(value)
      )
      .trim(),
    confirmSsn: string("Please enter a valid SSN")
      .required("Confirm SSN is required.")
      .test("confirmSsn-match", "SSN must match", function (value) {
        return this.parent.ssn === value;
      })
      .test(
        "confirmSsn",
        "Please enter a valid SSN.",
        (value) => !value || ssnRegEx.test(value)
      )
      .trim(),
    dob: date().required("DOB is required.").nullable(),
    address1: string("Please enter a valid address 1")
      .required("Address 1 is required.")
      .trim(),
    address2: string("Please enter a valid address 2").nullable().trim(),
    country: string("Please select a valid country")
      .required("Country is required.")
      .trim(),
    state: string("Please enter a valid state")
      .required("State is required.")
      .trim(),
    city: string("Please enter a valid city")
      .required("City is required.")
      .trim(),
    Zip: mixed()
      .test(
        "zip",
        "Please enter a valid zip",
        (value) =>
          !value ||
          (!value.toString().includes("-") &&
            !value.toString().includes("+") &&
            value.toString().length === 5)
      )
      .required("Zip is required."),
    mobile: mixed()
      .test(
        "mobile",
        "Please enter a valid mobile number.",
        (value) =>
          !value || (phoneNumberRegEx.test(value) && value.length <= 10)
      )
      .required("Mobile number is required."),
    responsibilty: number()
      .typeError("Only numeric values are allowed")
      .required("Responsibility $ is required.")
      .test(
        "responsibilty",
        "Maximum Two( 2 ) values are allowed after Decimal.",
        (value) => !value || twoDecimalRegEx.test(value)
      ),
    provider: string("Please select a valid provider.")
      .required("Provider is required.")
      .trim(),

    insuranceProvider: string("Please enter a valid Insurance Provider.")
      .required("Insurance Carrier is required.")
      .trim(),

    otherservicename: string("Please enter a valid service name.").trim(),
    service: string("Please enter a valid service.")
      .required("Service Description is required.")
      .trim()
  }),
  loginPatient: object({
    id: string("Please enter a valid Email.")
      .required("Email is required.")
      .test(
        "id",
        "Please enter a valid Email.",
        (value) =>
          !value ||
          emailRegEx.test(value) ||
          /^(\+\d{1,3}[- ]?)?\d{10}$/.test(parseInt(value))
      )
      .trim(),
    password: string("Please enter a valid password.")
      .required("Password is required")
      .trim()
    // .test(
    //   "password",
    //   "Password length must me be minimum 8 characters.",
    //   (value) => {
    //     return !value || value.length >= 8;
    //   }
    // )
    // .test(
    //   "password",
    //   "Password length can not be more than 20 characters.",
    //   (value) => {
    //     return !value || value.length <= 20;
    //   }
    // )
    // .test("password", "At least 1 lowercase letter required.", (value) => {
    //   return !value || value.toUpperCase() !== value;
    // })
    // .test("password", "At least 1 uppercase letter required.", (value) => {
    //   return !value || value.toLowerCase() !== value;
    // })
    // .test("password", "At least 1 digit is required.", (value) => {
    //   return !value || /\d/.test(value);
    // })
    // .test(
    //   "password",
    //   "At least 1 special character is required.",
    //   (value) => {
    //     return !value || !/^[A-Za-z0-9 ]+$/.test(value);
    //   }
    // ),
  }),
  loginAdmin: object({
    clientId: string("Please enter a valid client id.")
      .required("Client Id is required.")
      .trim(),
    id: string("Please enter a valid Email.")
      .required("Email is required.")
      .test(
        "id",
        "Please enter a valid Email.",
        (value) =>
          !value ||
          emailRegEx.test(value) ||
          /^(\+\d{1,3}[- ]?)?\d{10}$/.test(parseInt(value))
      )
      .trim(),
    password: string("Please enter a valid password.")
      .required("Password is required")
      .trim()
    // .test(
    //   "password",
    //   "Password length must me be minimum 8 characters.",
    //   (value) => {
    //     return !value || value.length >= 8;
    //   }
    // )
    // .test(
    //   "password",
    //   "Password length can not be more than 20 characters.",
    //   (value) => {
    //     return !value || value.length <= 20;
    //   }
    // )
    // .test("password", "At least 1 lowercase letter required.", (value) => {
    //   return !value || value.toUpperCase() !== value;
    // })
    // .test("password", "At least 1 uppercase letter required.", (value) => {
    //   return !value || value.toLowerCase() !== value;
    // })
    // .test("password", "At least 1 digit is required.", (value) => {
    //   return !value || /\d/.test(value);
    // })
    // .test(
    //   "password",
    //   "At least 1 special character is required.",
    //   (value) => {
    //     return !value || !/^[A-Za-z0-9 ]+$/.test(value);
    //   }
    // ),
  }),
  resetPassword: object({
    password: string("Please enter a valid password.")
      .required("Password is required")
      .trim()
      .test(
        "password",
        "Password length must me be minimum 8 characters.",
        (value) => {
          return !value || value.length >= 8;
        }
      )
      .test(
        "password",
        "Password length can not be more than 20 characters.",
        (value) => {
          return !value || value.length <= 20;
        }
      )
      .test("password", "At least 1 lowercase letter required.", (value) => {
        return !value || value.toUpperCase() !== value;
      })
      .test("password", "At least 1 uppercase letter required.", (value) => {
        return !value || value.toLowerCase() !== value;
      })
      .test("password", "At least 1 digit is required.", (value) => {
        return !value || /\d/.test(value);
      })
      .test(
        "password",
        "At least 1 special character is required.",
        (value) => {
          return !value || !/^[A-Za-z0-9 ]+$/.test(value);
        }
      ),
    confirmPassword: string("Please enter a valid password.")
      .required("Password is required")
      .test(
        "confirmPassword",
        "Password and Confirm Password does not match.",
        function (value) {
          return this.parent.password === value;
        }
      )
      .trim()
  }),

  addEditGroups: object({
    groupName: string("Please enter a valid group name.")
      .required("Group name is required.")
      .trim(),
    groupType: string("Please select a group type.").nullable().trim(),

    groupStatus: string("Please select a group status.")
      .required("Legal name is required.")
      .trim()
  }),

  merchantSignUp: object({
    First_Name: string("Please enter a valid first name.")
      .required("First name is required.")
      .trim(),
    Last_Name: string("Please enter a valid last name.")
      .required("Last name is required.")
      .trim(),
    Email: string("Please enter a valid email address.")
      .required("Email is required.")
      .email("Please enter a valid email address.")
      .test(
        "Email",
        "Please enter a valid email address.",
        (value) => !value || emailRegEx.test(value)
      )
      .trim(),
    legalName: string("Please enter a valid email address.").trim(),
    dbaName: string("Please enter a valid email address.").trim()
  }),

  makePaymentPage: object({
    First_Name: string("Please enter a valid first name.")
      .required("First name is required.")
      .trim(),
    Last_Name: string("Please enter a valid last name.")
      .required("Last name is required.")
      .trim(),
    Email: string("Please enter a valid email address.")
      .required("Email is required.")
      .email("Please enter a valid email address.")
      .test(
        "Email",
        "Please enter a valid email address.",
        (value) => !value || emailRegEx.test(value)
      )
      .trim(),
      // accountNumber:validationSchema,
    // confirmAccountNumber: validationSchema
    // address_one: string("Please enter a valid address.").required("Address is required.").trim(),
    // address_tow: string("Please enter a valid address.").required("Address is required.").trim()
  }),

  createUser: object({
    First_Name: string("Please enter a valid first name.")
      .required("First name is required.")
      .trim(),
    Middle_Name: string("Please enter a valid first name.").nullable().trim(),
    Last_Name: string("Please enter a valid last name.")
      .required("Last name is required.")
      .trim(),
    Email: string("Please enter a valid email address.")
      .required("Email is required.")
      .email("Please enter a valid email address.")
      .test(
        "email",
        "Please enter a valid email address.",
        (value) => !value || emailRegEx.test(value)
      )
      .trim(),
    Profile_Code: string("Please select a valid Profile Code")
      .required("Profile Code is required.")
      .trim(),
    Address1: string("Please enter a valid address 1")
      .required("Address 1 is required.")
      .trim(),
    Address2: string("Please enter a valid address 2").nullable().trim(),
    Country_Code: string("Please select a valid country")
      .required("Country is required.")
      .trim(),
    State_Code: string("Please enter a valid state")
      .required("State is required.")
      .trim(),
    City: string("Please enter a valid city")
      .required("City is required.")
      .trim(),
    Zip: mixed()
      .test(
        "zip",
        "Please enter a valid zip",
        (value) =>
          !value ||
          (!value.toString().includes("-") &&
            !value.toString().includes("+") &&
            value.toString().length === 5)
      )
      .required("Zip is required."),
    Mobile: mixed()
      .test(
        "mobile",
        "Please enter a valid mobile number.",
        (value) =>
          !value || (phoneNumberRegEx.test(value) && value.length === 12)
      )
      .required("Mobile number is required."),
    Phone_No: mixed()
      .nullable()
      .test(
        "mobile",
        "Please enter a valid mobile number.",
        (value) =>
          !value || (phoneNumberRegEx.test(value) && value.length === 12)
      ),
    Time_Zone_Code: string().required("Time Zone is required."),
    reportsTo: string().required("Reports To is required."),
    Password: string("Please enter a valid password.")
      .required("Password is required")
      .trim()
      .test(
        "Password",
        "Password length must me be minimum 8 characters.",
        (value) => {
          return !value || value.length >= 8;
        }
      )
      .test(
        "Password",
        "Password length can not be more than 20 characters.",
        (value) => {
          return !value || value.length <= 20;
        }
      )
      .test("Password", "At least 1 lowercase letter required.", (value) => {
        return !value || value.toUpperCase() !== value;
      })
      .test("Password", "At least 1 uppercase letter required.", (value) => {
        return !value || value.toLowerCase() !== value;
      })
      .test("Password", "At least 1 digit is required.", (value) => {
        return !value || /\d/.test(value);
      })
      .test(
        "Password",
        "At least 1 special character is required.",
        (value) => {
          return !value || !/^[A-Za-z0-9 ]+$/.test(value);
        }
      ),
    confirmPassword: string("Please enter a valid password.")
      .required("Confirm Password is required")
      .test(
        "confirmPassword",
        "Password and Confirm Password does not match.",
        function (value) {
          return this.parent.Password === value;
        }
      )
      .trim(),
    profileVisibility: string()
      .required(
        "Profile Visibility is a required field. Please select a option."
      )
      .trim(),


    repoCode: string().test(
      "repoCode",
      "Please enter a valid user code.",
      (value) => {
        return !value || /^[a-zA-Z0-9/-]+$/.test(value);
      }
    ).required("Rep Code is required.").min(3).max(12).trim(),
    Role_ID: string().required("Please select a Role.").trim(),
    Notes: string("Please write a note for user.").nullable().trim()
  }),
  updateUser: object({
    First_Name: string("Please enter a valid first name.")
      .required("First name is required.")
      .trim(),
    Middle_Name: string("Please enter a valid first name.").nullable().trim(),
    Last_Name: string("Please enter a valid last name.")
      .required("Last name is required.")
      .trim(),
    Email: string("Please enter a valid email address.")
      .required("Email is required.")
      .email("Please enter a valid email address.")
      .test(
        "email",
        "Please enter a valid email address.",
        (value) => !value || emailRegEx.test(value)
      )
      .trim(),
    Profile_Code: string("Please select a valid Profile Code")
      .required("Profile Code is required.")
      .trim(),
    Address1: string("Please enter a valid address 1")
      .required("Address 1 is required.")
      .trim(),
    Address2: string("Please enter a valid address 2").nullable().trim(),
    Country_Code: string("Please select a valid country")
      .required("Country is required.")
      .trim(),
    State_Code: string("Please enter a valid state")
      .required("State is required.")
      .trim(),
    City: string("Please enter a valid city")
      .required("City is required.")
      .trim(),
    Zip: mixed()
      .test(
        "zip",
        "Please enter a valid zip",
        (value) =>
          !value ||
          (!value.toString().includes("-") &&
            !value.toString().includes("+") &&
            value.toString().length === 5)
      )
      .required("Zip is required."),
    Mobile: mixed()
      .test(
        "mobile",
        "Please enter a valid mobile number.",
        (value) =>
          !value || (phoneNumberRegEx.test(value) && value.length === 12)
      )
      .required("Mobile number is required."),
    Phone_No: mixed()
      .nullable()
      .test(
        "mobile",
        "Please enter a valid mobile number.",
        (value) =>
          !value || (phoneNumberRegEx.test(value) && value.length === 12)
      ),
    Time_Zone_Code: string().required("Time Zone is required."),
    reportsTo: string().required("Reports To is required."),
    Password: string("Please enter a valid password.")
      .trim()
      .test(
        "Password",
        "Password length must me be minimum 8 characters.",
        (value) => {
          return !value || value.length >= 8;
        }
      )
      .test(
        "Password",
        "Password length can not be more than 20 characters.",
        (value) => {
          return !value || value.length <= 20;
        }
      )
      .test("Password", "At least 1 lowercase letter required.", (value) => {
        return !value || value.toUpperCase() !== value;
      })
      .test("Password", "At least 1 uppercase letter required.", (value) => {
        return !value || value.toLowerCase() !== value;
      })
      .test("Password", "At least 1 digit is required.", (value) => {
        return !value || /\d/.test(value);
      })
      .test(
        "Password",
        "At least 1 special character is required.",
        (value) => {
          return !value || !/^[A-Za-z0-9 ]+$/.test(value);
        }
      ),
    confirmPassword: string("Please enter a valid password.")
      .test(
        "confirmPassword",
        "Password and Confirm Password does not match.",
        function (value) {
          return this.parent.Password === value;
        }
      )
      .trim(),
    profileVisibility: string()
      .required(
        "Profile Visibility is a required field. Please select a option."
      )
      .trim(),
    Role_ID: string().required("Please select a Role.").trim()
  }),

  kycSubQues: object({
    idNumber: string("Please enter a valid Id number.")
      .required("Id number is required.")
      .trim(),
    issueDate: date("plassdasd").required("Issued Date is required."),
    dateExpires: date("asdasdasd").required("Date of Expire is required."),
    country: string("Please select a valid country")
      .required("Country is required.")
      .trim(),
    state: string("Please enter a valid state")
      .required("State is required.")
      .trim(),
    city: string("Please enter a valid city")
      .required("City is required.")
      .trim()
  }),
  pricingTemplateAddEdit: object({
    name: string("Please enter a valid template name.")
      .required("Pricing template name is required.")
      .trim(),
    processorId: string("Please select a valid processor")
      .required("Processor is required.")
      .trim(),
    applicationId: string("Please select a valid application")
      .required("Application is required.")
      .trim(),
    startDate: date("Please select a valid date").required(
      "Start Date is required."
    ),
    termLengthDays: number("Term days must be a number.")
      .required("Terms days is required.")
      .test("Password", "Invalid term days value.", (value) => {
        return /^[1-9]\d*$/.test(value);
      }),
    status: string("Status value is invalid").trim(),
    Notes: string("Please enter valid notes").nullable().trim()
  }),
  addNotes: object({
    type: string("Please select type.").required("Type is required.").trim(),
    description: string("please write description.")
      .required("Description is required.")
      .trim()
      .max(2000, "Reached maximum allowed characters")
  })
};
